import React, { Fragment } from 'react';
import MetricsChart from './MetricsChart'
import DateMetricsTable from './DateMetricsTable'
import CategoryMetricsTable from './CategoryMetricsTable'
import UsersMetricsTable from './UsersMetricsTable'
import ProdCentresMetricsTable from './ProdCentresMetricsTable'
import { Tab, Tabs } from 'react-bootstrap';
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import Utilities from 'hub-utilities'

import { mapDispatchToMetricsProps, mapStateToMetricsProps } from 'store/actions/HubMetricsAction';

import * as Logger from 'loglevel';
import { DataTableConstant } from 'hub-dashboard-framework'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import 'styles/main-content/MetricsReports.scss'

import HubDateRangePicker from './HubDateRangePicker';

import { HUB_METRICS_REPORT_TYPE } from 'util/HubConstant';

import CountUp from 'react-countup';

class MetricsReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // dateRange: {
            //     startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago of the current timezone in UTC,
            //     endDate: new Date(), // today the current timezone in UTC,
            // },
            dateRange: this.getCurrentWeek(),
            metricsNumericalDataAry: []
        }
    }

    getCurrentWeek() { // returns a {startDate: Date, endDate: Date} object; this should be made as a utility soon
        const curr = new Date; // get current date
        const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        const last = first + 6; // last day is the first day + 6

        const firstDay = new Date(curr.setDate(first));
        const lastDay = new Date(curr.setDate(last));

        const dateRange = { startDate: firstDay, endDate: lastDay };

        return dateRange;
    }

    async componentWillMount() {
        await this.props.getHubMetrics(this.state.dateRange.startDate, this.state.dateRange.endDate);
        if (!!this.props.metrics && !!this.props.metrics.totalMetrics)
            this.setState({metricsNumericalDataAry: this.initializeMetricsNumericalData(this.props.metrics.totalMetrics)});
    }

    componentDidMount() {
        this.props.setActivePageName({ singular: "Usage Metrics", plural: "Usage Metrics", icon: faChartBar });
    }

    initializeMetricsNumericalData(totalMetrics) {
        return [
            {
                title: "Total Downloads", 
                value: totalMetrics.downloads, 
                type: "number",
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION]
            },
            {
                title: "Total Previews", 
                value: totalMetrics.previews, 
                type: "number",
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION]
            },
            {
                title: "Total Created", 
                value: totalMetrics.added, 
                type: "number",
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CREATION]
            },
            {
                title: "Precent With Media", 
                value: Math.round(Utilities.calcProportionalData(totalMetrics.addedWithMedia, totalMetrics.added) * 100), 
                type: "percentage",
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CREATION]
            },
            {
                title: "Total Media", 
                value: Utilities.formatRunTime(totalMetrics.duration, false), 
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION, HUB_METRICS_REPORT_TYPE.CONTENT_CREATION]
            },
            {
                title: "Total Items Fed", 
                value: totalMetrics.feeds, 
                type: "number",
                metricsReportTypes: [HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION]
            },

        ]
    }

    renderMetricsNumbers(metricsReportType) {
        return (
            <div className="MetricsNumbers">
            {
                this.state.metricsNumericalDataAry.map(numData => {
                    if (numData.metricsReportTypes.some(type => type === metricsReportType)) {
                        return (
                            <Fragment>
                                <div className="title">{numData.title}</div>
                                <div className="value">
                                    {
                                        numData.type === "number" ? 
                                            <CountUp end={numData.value} duration={0.5} /> :
                                        numData.type === "percentage" ? 
                                            <Fragment>
                                                <CountUp end={numData.value} duration={0.5} />%
                                            </Fragment> :
                                        numData.value
                                    }
                                </div>
                            </Fragment>
                        )
                    } else return <Fragment />
                })
            }
            </div>
        )
    }

    sortDateMetrics(dateMetrics) {
        if (!dateMetrics) {
            return [];
        }
        return dateMetrics.sort((a, b) => new Date(a.date) - new Date(b.date));
    }



    renderLoadingScreen() {
        return (
            <Fragment>

            </Fragment>
        )
    }

    renderMetricsContent(metricsReportType) {
        return (
            <Fragment>
                <MetricsChart metrics={this.sortDateMetrics(this.props.metrics.dateMetrics)} metricsReportType={metricsReportType} />
                <div className="DetailedMetricsWrapper">
                    <div className="MetricsTablesTabWrapper">
                        <span className="by">By:</span> <Tabs
                            className="MetricsTablesTab"
                            id="controlled-tab-example"
                            defaultActiveKey="date"
                            mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                            unmountOnExit
                        >
                            <Tab eventKey="date" title="Date">
                                <DateMetricsTable metrics={this.sortDateMetrics(this.props.metrics.dateMetrics)} dataAction={this.props.action} metricsReportType={metricsReportType} />
                            </Tab>
                            <Tab eventKey="category" title="Category">
                                <CategoryMetricsTable metrics={this.props.metrics.categoryMetrics} dataAction={this.props.action} metricsReportType={metricsReportType} />
                            </Tab>
                            <Tab eventKey="user" title="User">
                                <UsersMetricsTable metrics={this.props.metrics.userMetrics} dataAction={this.props.action} metricsReportType={metricsReportType} />
                            </Tab>
                            <Tab eventKey="prodCentre" title="Production Centre">
                                <ProdCentresMetricsTable metrics={this.props.metrics.prodCenterMetrics} dataAction={this.props.action} metricsReportType={metricsReportType} />
                            </Tab>
                            
                        </Tabs>
                    </div>
                    {
                        this.renderMetricsNumbers(metricsReportType)
                    }
                </div>
            </Fragment>
        )
    }

    queryDateRange = (startDate, endDate) => {
        this.setState({
            dateRange: {
                startDate: startDate,
                endDate: endDate
            }
        }, () => {
            this.props.getHubMetrics(this.state.dateRange.startDate, this.state.dateRange.endDate);
        })
    }

    renderMetricsTab(tabKey, tabName, metricsType) {
        return (
            <Tab eventKey={tabKey} title={tabName}>
                <div className="MetricsReportsWrapper">
                    <HubDateRangePicker 
                        startDate={this.state.dateRange.startDate} 
                        endDate={this.state.dateRange.endDate}
                        queryDateRange={this.queryDateRange} />
                    {
                        (this.props.action !== DataTableConstant.HUB_ACTION_STATE.LOADING && !!this.props.metrics) ?
                            this.renderMetricsContent(metricsType) : this.renderLoadingScreen()
                    }
                </div>
            </Tab>
        )
    }

    render() {
        return (
            <div className="MetricsReports">
                <Tabs
                    className="MetricsContentsTab"
                    id="controlled-tab-example"
                    defaultActiveKey="content-creation"
                    mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                    unmountOnExit
                >
                    {this.renderMetricsTab("content-creation", "Content Creation", HUB_METRICS_REPORT_TYPE.CONTENT_CREATION)}
                    {this.renderMetricsTab("content-consumption", "Content Consumption", HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION)}
                </Tabs>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToMetricsProps, mapDispatchToMetricsProps)(MetricsReports));