import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType';

// state and reducer here:

const initialState = {
    isLoading: false,
    geoObj: null,
    error: null,
    success: null
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_GEO_BEGIN:
        case ActionType.ADD_GEO_BEGIN:
        case ActionType.UPDATE_GEO_BEGIN:
        case ActionType.DELETE_GEO_BEGIN:

            Logger.debug(`Logo reducer: action = ${action.type}`);
            return ({
                ...state,
                isLoading: true,
            });

        case ActionType.FETCH_GEO_SUCCESS:
            {
                Logger.debug(`Logo reducer: action = ${action.type}`);
                Logger.debug('here');
                if (!!action.payload.geoAreaList) {
                    action.payload.geoAreaList.forEach(geoArea => {
                        var geoListExtractedStr = geoArea.geoDesc.geoData;
                        Logger.debug(geoListExtractedStr)
                        geoListExtractedStr = geoListExtractedStr.substring(geoListExtractedStr.lastIndexOf('(') + 1);
                        geoListExtractedStr = geoListExtractedStr.substring(0, geoListExtractedStr.indexOf(')'));

                        Logger.debug(geoListExtractedStr)

                        var arrOfLatLon = geoListExtractedStr.split(", ");
                        var geoPoints = arrOfLatLon.slice(0, arrOfLatLon.length - 1).map((latlon, index) => {
                            return { lat: latlon.substring(0, latlon.indexOf(' ')), lon: latlon.substring(latlon.indexOf(' ') + 1, latlon.length) }
                        })

                        Logger.debug(geoPoints);
                        geoArea.geoDesc.geoData = geoPoints;
                    })
                }
                return (
                    {
                        ...state,
                        isLoading: false,
                        geoObj: action.payload,
                    });
            }
        case ActionType.ADD_GEO_SUCCESS:
            {
                return (
                    {
                        ...state,
                        isLoading: false,
                        success: "Successfully Added Geographics!"
                    }
                );
            }
        case ActionType.UPDATE_GEO_SUCCESS:
            {
                return (
                    {
                        ...state,
                        isLoading: false,
                        success: "Successfully Updated Geographics!"
                    }
                );
            }
        case ActionType.DELETE_GEO_SUCCESS:
            {
                return (
                    {
                        ...state,
                        isLoading: false,
                        success: "Successfully Deleted Geographics!"
                    }
                );
            }

        case ActionType.FETCH_GEO_FAILURE:
        case ActionType.ADD_GEO_FAILURE:
        case ActionType.UPDATE_GEO_FAILURE:
        case ActionType.DELETE_GEO_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug(action.payload);
            return (
                {
                    ...state,
                    isLoading: false,
                    error: action.payload,
                });

        case ActionType.EDIT_GEO_INPUT:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    error: null,
                    success: null
                });
        default:
            break;
    }
    return state;
}
