import * as Logger from 'loglevel';
import authService from '../../components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'
import { convertObjToFolderTree } from '../../components/post-authorization/libraries/DataHandler';
import PermissionManager from '../../util/PermissionManager';

export class FtReducer {
    //Add action here:
    
    onFtDeletePermission = (state, ftContent) => {
        const userId = authService.getCurrentHubUser().id;
        const userType = authService.getUserType();

        const isUserInSecurityGroups = state.folderSecurityGroups.some(group =>
            group.users.includes(userId)
        );

        if (ftContent.principalId === userId || isUserInSecurityGroups) {
            const folderId = ftContent.folderId;
            Logger.debug(`FtSink: deleting user permissions for Folder with Id: ${folderId}`);

            const updatedTree = state.hubFolderTree.map(folder => {
                if (folder.id === folderId) {

                    const newPermissions = folder.permissions.filter(perm =>
                        perm.principalId !== ftContent.principalId
                    );

                    // If no permissions are left
                    if (newPermissions.length === 0) {

                        return {
                            ...folder,
                            permissions: newPermissions,
                            folderPermissions: userType === UtilConstant.HUB_USER_TYPE.HUB_ADMIN ? 1 : -1
                        };
                    }

                    // If permissions remain, update the folder with the new permissions
                    return {
                        ...folder,
                        permissions: newPermissions,
                        folderPermissions: PermissionManager.chooseEffectivePermission(newPermissions, userId)
                    };
                }
                return folder;
            });

            let newState = {
                ...state,
                hubFolderTree: updatedTree,
                bTriggerUpdate: false
            };

            return newState;
        }

        return state;
    };



    onFtAddPermission = (state, ftContent) => {
        const userId = authService.getCurrentHubUser().id;

        const isUserInSecurityGroups = state.folderSecurityGroups.some(group =>
            group.users.includes(userId)
        );

        if (ftContent.securityDef.principalId === userId || isUserInSecurityGroups) {
            const folderId = ftContent.securityDef.securityId;

            const updatedTree = state.hubFolderTree.map(folder => {
                if (folder.id === folderId) {
                    const newPermissions = [...folder.permissions, ftContent.securityDef];
                    return {
                        ...folder,
                        permissions: newPermissions,
                        folderPermissions: PermissionManager.chooseEffectivePermission(newPermissions, userId)
                    };
                }
                return folder;
            });

            Logger.debug(`FtSink: updating user permissions for Folder with Id: ${folderId}`);

            return {
                ...state,
                hubFolderTree: updatedTree,
                bTriggerUpdate: false
            };
        }
        return state;
    };

    onFtUpdatePermission = (state, ftContent) => {
        const userId = authService.getCurrentHubUser().id;
        const isUserInSecurityGroups = state.folderSecurityGroups.some(group =>
            group.users.includes(userId)
        );

        if (ftContent.securityDef.principalId === userId || isUserInSecurityGroups) {
            const folderId = ftContent.securityDef.securityId;

            const updatedTree = state.hubFolderTree.map(folder => {
                if (folder.id === folderId) {
                    const newPermissions = folder.permissions.map(perm =>
                        perm.principalId === ftContent.securityDef.principalId ? ftContent.securityDef : perm
                    );

                    return {
                        ...folder,
                        permissions: newPermissions,
                        folderPermissions: PermissionManager.chooseEffectivePermission(newPermissions, userId)
                    };
                }
                return folder;
            });

            Logger.debug(`FtSink: updating user permissions for Folder with Id: ${folderId}`);

            return {
                ...state,
                hubFolderTree: updatedTree,
                bTriggerUpdate: false
            };
        }
        return state;
    };

    onFtAddFolder = (state, ftContent) => {
        Logger.debug(`FtSink: adding new Folder with Id: ${ftContent.folderId}`);
        const newFolder = convertObjToFolderTree(ftContent.hubObject)
        const updatedTree = [...state.hubFolderTree, newFolder]
        let newState = {
            ...state,
            hubFolderTree: updatedTree,
            bTriggerUpdate: false
        }
        return newState;
    }
    onFtUpdateFolder = (state, ftContent) => {
        Logger.debug(`FtSink: updating Folder with Id: ${ftContent.folderId}`);
        const newFolder = convertObjToFolderTree(ftContent.hubObject)
        const updatedTree = state.hubFolderTree.map((folder) =>
            folder.id === ftContent.folderId
                ? {
                    ...folder,
                    parentId : newFolder.parentId,
                    id : newFolder.id,
                    name : newFolder.name,
                    securityOption : newFolder.securityOption
                }
                : folder
        );
        let newState = {
            ...state,
            hubFolderTree: updatedTree,
            bTriggerUpdate: false
        }
        return newState;
    }
    onFtDeleteFolder = (state, ftContent) => {
        Logger.debug(`FtSink: deleting Folder with Id: ${ftContent.folderId}`);
        const updatedTree = state.hubFolderTree.filter((folder) => folder.id !== ftContent.folderId);
        let newState = {
            ...state,
            hubFolderTree: updatedTree,
            bTriggerUpdate: false
        }
        return newState;
    }
}

const ftReducer = new FtReducer();
export default ftReducer;
