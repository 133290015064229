import React, { Fragment } from 'react';
import { DataTable, DataTableConstant, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import * as HubUserBoardActions from 'store/actions/HubUserBoardActions'
import * as HubConfigActions from 'store/actions/HubConfigActions'
import authService from 'components/api-authorization/AuthorizeService';

import * as HubConstant from 'util/HubConstant';
import { UtilConstant } from 'hub-utilities'
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import 'styles/main-content/Users.scss';

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.headerConfig = {
            id: -1,
            LoginName: { val: "", canSort: true },
            FirstName: { val: "", canSort: true },
            LastName: { val: "", canSort: true },
            Email: { val: "", canSort: true },
            Description: { val: "", canSort: true },
            ProductionCentre: { val: "", canSort: true },
            State: { val: "", canSort: true },
            rowActions: [
                { action: TableRowAction.CHANGE_PASSWORD, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                { action: TableRowAction.EDIT_INFO_BY_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                { action: TableRowAction.DISABLE, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN }
            ]
        };
    }

    componentWillMount() {
        this.props.setActivePageName({ singular: "User", plural: "Users", icon: faUsers });
        if (this.props.users == null) {
            this.props.requestData();
            this.props.requestNodes(); //fetch available nodes to populate nodes dropdown in user form
        }
        //objectList is the redux state
        this.setState({ fetchedData: this.fetchData(this.props.users) });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.users) })
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.sort((a, b) => { return (a.id < b.id) ? 1 : -1 }).map((o) => {
                return (
                    {
                        //Lower case keys won't get posted to table
                        id: o.id,
                        userGuid: o.userGuid,
                        phoneNumber: o.phoneNumber,
                        adminLevel: o.adminLevel,
                        LoginName: { val: o.userName, canSort: true },
                        FirstName: { val: o.firstName, canSort: true },
                        LastName: { val: o.lastName, canSort: true },
                        Email: { val: o.email, canSort: true },
                        Description: { val: o.description, canSort: true },
                        ProductionCentre: { val: authService.getHubNodeName(o.hubNodeId), id: o.hubNodeId, canSort: true },
                        State: { val: o.state > 0 ? 'Active' : 'InActive', canSort: true },
                        rowActions: [
                            { action: TableRowAction.CHANGE_PASSWORD, form: HubConstant.HUB_UPDATE_USER_PASSWORD_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                            {
                                action: TableRowAction.EDIT_INFO_BY_FORM,
                                form: HubConstant.HUB_UPDATE_USER,
                                data: {
                                    nodes: this.props.nodes,
                                    userType: authService.getUserType(),
                                    nodeName: authService.getNodeIdByUserGuid(authService.getUserGuid())
                                },
                                minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN,
                                tooltipText: "Edit User"
                            },
                            {
                                action: TableRowAction.DISABLE,
                                onDisable: this.props.disableUser,
                                onEnable: this.props.enableUser,
                                minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN
                            }
                        ]
                    }
                );
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            {
                                action: TableToolType.ADD,
                                form: HubConstant.HUB_CREATE_NEW_USER_FORM,
                                data: {
                                    nodes: this.props.nodes,
                                    userType: authService.getUserType(),
                                    nodeName: authService.getNodeNameByUserGuid(authService.getUserGuid())
                                }
                            },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION }]}
                        dataAction={this.props.action}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Users">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapNodeAndUserStateToProps = (state) => {
    return {
        nodes: state.hubConfig.nodeConfig.nodes,
        users: state.hubConfig.userConfig.users,
        action: state.hubConfig.userConfig.action,
    }
}

export default withRouter(connect(
    mapNodeAndUserStateToProps,
    dispatch => bindActionCreators({ ...HubUserBoardActions.mapDispatchToProps, ...HubConfigActions.mapDispatchToProps }, dispatch)
)(Users));
