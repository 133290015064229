import React from "react";
import { Popover } from 'react-bootstrap';
import Vocabulary from './Vocabulary';
import VocabularyTable from './VocabularyTable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import 'styles/widgets/VocabPopover.scss';

class VocabularyPopup extends React.Component {
    render() {
        return (
            <Popover 
                id="manageVocabPopover" 
                role={this.props.role} 
                placement={this.props.placement}
                style={this.props.style}> {/* Need to pass props (styles) 
            or else the popup position would get messed up */}
                <Popover.Title id="manageVocabPopoverTitle" as="h3">
                    Manage Values
                </Popover.Title>
                <Popover.Content id="manageVocabPopoverContent">
                    <VocabularyTable
                        vocabType={this.props.dataType}
                        vocabData={this.props.formData}
                        editVocab={this.props.onEditVocab}
                        deleteVocab={this.props.onDeleteVocab}
                        addVocab={this.props.onAddVocab}
                    />
                </Popover.Content>
                <div className="closeButton" onClick={(e) => this.props.toggleManageVocabulariesForm(e)}><FontAwesomeIcon icon={faTimes} /></div>
            </Popover>
        )
    }
}

export default VocabularyPopup