import authService from '../components/api-authorization/AuthorizeService';
import { assignInheritedPermissions, convertObjToFolderTree } from '../components/post-authorization/libraries/DataHandler';
import folderApi from './FolderApi';
import hubApi from './HubApi';
import PermissionManager from './PermissionManager';
import * as HubConstant from './HubConstant';

export default class FolderFactory {
    static async processFolderWithPermissions(tree, folder) {
        const userId = authService.getCurrentHubUser().id;
        const processedFolder = FolderFactory.createFolder(folder);

        // Fetch and assign permissions based on security option
        await FolderFactory.assignFolderPermissions(tree, processedFolder, userId);

        return processedFolder;
    }

    static async assignFolderPermissions(tree, folder, userId) {
        let permissions = [];
        let folderPermissions = -1; 

        switch (folder.securityOption) {
            case HubConstant.FOLDER_SECURITY_OPTIONS.CUSTOM:
                const perms = await folderApi.getUserFolderPermission(folder.id, userId);
                permissions = PermissionManager.storePermissions(perms.securityDefs);
                if (folder.parentId == null && permissions.length == 0) {
                    folderPermissions = 1;
                    break;

                }
                folderPermissions = permissions.length > 0  ? PermissionManager.chooseEffectivePermission(permissions, userId) : -1;
                break;

            case HubConstant.FOLDER_SECURITY_OPTIONS.PUBLIC:
                folderPermissions = 31; // Bitmask: 11111 (all permissions)
                break;

            case HubConstant.FOLDER_SECURITY_OPTIONS.PRIVATE:
                folderPermissions = (folder.createdBy === userId) ? 31 : 1;
                break;

            case HubConstant.FOLDER_SECURITY_OPTIONS.INHERIT:
                assignInheritedPermissions(tree, folder);
                folderPermissions = folder.folderPermissions;
                break;

            default:
                folderPermissions = 1; // Bitmask: 00001 (read-only)
                break;
        }

        folder.permissions = permissions;
        folder.folderPermissions = folderPermissions;
    }

    static createFolder(data) {
        return convertObjToFolderTree(data);
    }

    static async getSubFolders(tree, folderId) {
        const subfolders = await hubApi.getSubFolders(folderId);

        if (!subfolders || subfolders.length === 0) {
            return [];
        }

        const processedSubfolders = await Promise.all(subfolders.map((subfolder) => FolderFactory.processFolderWithPermissions(tree, subfolder)));
        return processedSubfolders;
    }
}
