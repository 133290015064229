import React, { Fragment, useEffect, useState } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { mapMetadataStateToProps, mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import * as HubConstant from 'util/HubConstant'
import Utilities, { UtilConstant } from 'hub-utilities';
import authService from 'components/api-authorization/AuthorizeService';

export function Metadata(props) {
    const [fetchedData, setFetchedData] = useState(null);

    const headerConfig = {
        id: -1,
        description: "",
        FieldName: { val: "", canSort: true },
        DataType: { val: "", canSort: true },
        'Controlled?': { val: "", canSort: false },
        Values: { val: "", canSort: false },
        rowActions: [
            { action: TableRowAction.EDIT_METADATA, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
        ]
    };

    useEffect(() => {
        async function fetchData() {
            // Flush success/error msg:
            props.editMetadata();

            // Need to fetch nodes, for the ones without group
            await props.getMetadata();
            // objectList is the redux state
            setFetchedData(await fetchDataFromProps(props.metaData));
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function updateMetaData() {

            setFetchedData(await fetchDataFromProps(props.metaData));
        }
        updateMetaData();
    }, [props.metaData]);

    const fetchDataFromProps = (objects) => {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.map(o => {
                Logger.debug("o: ", JSON.parse(JSON.stringify(o)));
                var convertedDataType = Object.keys(UtilConstant.HUB_DATA_TYPE).find(key => UtilConstant.HUB_DATA_TYPE[key] === o.dataType);
                if (!!convertedDataType) {
                    convertedDataType = convertedDataType.charAt(0) + convertedDataType.slice(1).toLowerCase()
                    return (
                        {
                            id: o.fieldId,
                            type: HubConstant.HUB_METADATA,
                            description: o.description,
                            canDelete: o.fieldId >= 10000,
                            FieldName: { val: o.fieldName, canSort: true },
                            DataType: { val: convertedDataType, actualVal: o.dataType, canSort: true },
                            'Controlled?': { val: o.isControlledVocabulary ? "✓" : "✗", canSort: false, color: o.isControlledVocabulary ? 'green' : 'red' },
                            Values: {
                                val: !!o.vocabulary ?
                                    o.vocabulary.map(vocab => formatVocab(vocab.keyword)).join("; ") :
                                    "Loading...",
                                formVal: o.vocabulary,
                                canSort: false
                            },
                            rowActions: [
                                { action: TableRowAction.EDIT_METADATA, form: HubConstant.HUB_UPDATE_METADATA_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
                            ]
                        }
                    );
                } else {
                    return [];
                }
            });
        } else {
            return [];
        }
    };

    const onDeleteRow = (id) => {
        Logger.debug(id);
        props.deleteMetadata(id);
    };

    const formatVocab = (keyword) => {
        if (Utilities.verifyDataHasCorrectDataType(keyword, UtilConstant.HUB_DATA_TYPE.DATETIME)) {
            return Utilities.formatObjectDate(keyword, UtilConstant.HUB_TIME_FORMAT.CANADIAN, true)
        } else {
            return keyword;
        }
    };

    function displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={headerConfig}
                        data={fetchedData}
                        tools={[
                            { action: TableToolType.ADD, form: HubConstant.HUB_CREATE_METADATA_FORM },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.MESSAGE }]}
                        dataAction={props.action}
                        activeTableName={{ singular: "Data Field", plural: "Data Fields" }}
                        secondaryObjectType={HubConstant.HUB_METADATA}
                        onDeleteRow={onDeleteRow}
                        message={
                            props.success ? { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: props.success } :
                                props.error ? { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: props.error } :
                                    { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" }
                        }
                        authorizeService={authService}
                        {...props}></DataTable>
                </Col>
            </Row>
        )
    };

    return (
        <div className="Metadata">
            <Row className="structureRow">
                <Col xs="12">
                    {(!!fetchedData && fetchedData != null) ? displayDatatable() : <Fragment key="0"></Fragment>}
                </Col>
            </Row>
        </div>
    );
}

export default withRouter(connect(
    mapMetadataStateToProps,
    dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch),
    null,
    { pure: false }
)(Metadata));
