import * as HubConstant from 'util/HubConstant'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Utilities, { UtilConstant } from 'hub-utilities'
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { ExportToCsv } from 'export-to-csv';

import { HUB_METRICS_REPORT_TYPE } from 'util/HubConstant';

const renderHubNodeCount = (hubNode) => {
    var nodeCount = 0;
    hubNode.forEach((hubNodeElm) => {
        if (hubNodeElm.type === 2 || hubNodeElm.type === 3) {
            nodeCount += hubNodeElm.count;
        }
    })
    return nodeCount;
}

export const renderHubInfo = (hubInfoAry, actualData) => {
    console.log("Actaul data: ", actualData)
    if (!!actualData && Object.keys(actualData).length > 0 && actualData.hubInfo.hubId !== "Unknown") {
        return hubInfoAry.map((hubInfoData, index) => {
            switch (hubInfoData.type) {
                case HubConstant.HUB_INFO_TYPE.GENERAL:
                    return <p key={`hubInfoGeneral_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        {actualData[hubInfoData.key]}
                    </p>
                case HubConstant.HUB_INFO_TYPE.HUB_INFO:
                    return <p key={`hubInfoInfo_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        {hubInfoData.key === "createTime" ?
                            Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], UtilConstant.HUB_TIME_FORMAT.ONLY_DATE, true, false, false) :
                            hubInfoData.key === "expireTime" ?
                                <Fragment>
                                    <span style={
                                        Utilities.dateDiffInDays(new Date(actualData["hubInfo"][hubInfoData.key]), new Date()) < 14 ? { color: "red" } :
                                            Utilities.dateDiffInDays(new Date(actualData["hubInfo"][hubInfoData.key]), new Date()) < 30 ? { color: "orange" } : {}
                                    }>
                                        {Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], "MMM dd yyyy", true, true, false)} (UTC)&nbsp;
                                    </span>
                                    <OverlayTrigger
                                        key="tooltip_expireTime_top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], "h:mmTT MMM dd, yyyy zzz", true, false, false)}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon style={{fontSize:'18px', transform: 'translate(0, 10%)'}} icon={faInfoCircle} />
                                    </OverlayTrigger>
                                </Fragment> :
                                actualData["hubInfo"][hubInfoData.key]}
                    </p>
                case HubConstant.HUB_INFO_TYPE.ENVIRONMENT:
                    return <p key={`hubInfoEnv_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>{actualData["environment"][hubInfoData.key]}</p>
                case HubConstant.HUB_INFO_TYPE.RUNTIME:
                    return (
                        <p key={`hubInfoRuntime_${index}`} className="AboutInfo">
                            <span><b>{hubInfoData.title}:</b>&nbsp;</span>
                            {(!!hubInfoData.isStorageSize) ? Utilities.formatStorageSize(actualData["runtime"][hubInfoData.key]) : actualData["runtime"][hubInfoData.key]}
                        </p>
                    )
                case HubConstant.HUB_INFO_TYPE.NODES:
                    return (
                        <Fragment key={`hubInfoRuntimeNodes_${index}`}>
                            <p key={`hubInfoNodes_${index}`} className="AboutInfo">
                                <span><b>{hubInfoData.title}:</b>&nbsp;</span>
                                {renderHubNodeCount(actualData["hubInfo"]["nodes"])}
                            </p>
                        </Fragment>

                    )
                case HubConstant.HUB_INFO_TYPE.CUSTOMIZED: // {customized: {key: x, val: y}}
                    return <p key={`hubInfoGeneral_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        <div style={{display: "inline-table"}}>
                            {!!hubInfoData.val ? hubInfoData.val.map(val => <p style={{marginBottom: 0}}>{val}</p>) : <></>}
                        </div>
                    </p>
                default:
                    return <Fragment key={`hubInfoNone_${index}`}></Fragment>
            }
        })
    }
}

export const renderUserInfo = (userInfoAry, actualData) => {
    if (!!actualData && Object.keys(actualData).length > 0) {
        return userInfoAry.map((userInfoData, index) => {
            switch (userInfoData.type) {
                case HubConstant.HUB_INFO_TYPE.GENERAL:
                    return <p key={`userInfoGeneral_${index}`} className="AboutInfo">
                        <span><b>{userInfoData.title}:</b>&nbsp;</span>
                        {actualData[userInfoData.key]}
                    </p>

                case HubConstant.HUB_INFO_TYPE.HUB_NODE:
                    return <p key={`userInfoNode_${index}`} className="AboutInfo">
                        <span><b>{userInfoData.title}:</b>&nbsp;</span>
                        {actualData.hubNode ? actualData.hubNode[userInfoData.key] : ''}
                    </p>

                case HubConstant.HUB_INFO_TYPE.CUSTOMIZED:
                    return <p key={`userInfoCustom_${index}`} className="AboutInfo">
                        <span><b>{userInfoData.title}:</b>&nbsp;</span>
                        <div style={{ display: "inline-table" }}>
                            {!!userInfoData.val ? userInfoData.val.map((val, i) =>
                                <p key={`custom_${i}`} style={{ marginBottom: 0 }}>{val}</p>
                            ) : <></>}
                        </div>
                    </p>

                default:
                    return <Fragment key={`userInfoNone_${index}`}></Fragment>
            }
        })
    }
}

export const onClickDownloadCSVButton = (fileName, metricsData) => {
    const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: fileName
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
    const csvExporter = new ExportToCsv(options);

    //var newMetricsAry = Utilities.moveAryObjPropertyUpALevel(metricsData, "metrics");
    csvExporter.generateCsv(metricsData);
}

export const initializeMetricDetailsHeader = (metricsReportType) => {
    return {
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION && {Added: { val: "", canSort: true }},
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {Downloads: { val: "", canSort: true }},
        WithMedia: { val: "", canSort: true },
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {FeedRequests: { val: "", canSort: true }},
        AvgDuration: { val: "", canSort: true },
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {Previews: { val: "", canSort: true }},
        rowActions: []
    };
}

export const mapMetricsDetailsToTableData = (metrics, metricsReportType) => {
    return {
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION && {Added: { val: metrics.added, canSort: true }},
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {Downloads: { val: metrics.downloads, canSort: true }},
        WithMedia: { 
            val: Utilities.calcProportionalDataStr(
                metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION ? metrics.addedWithMedia : metrics.downloadsWithMedia, 
                metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION ? metrics.added : metrics.downloads), 
            canSort: true },
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && { FeedRequests: { val: metrics.feeds, canSort: true } },
        AvgDuration: { val: Utilities.formatRunTime(Utilities.calcProportionalData(metrics.duration, metrics.added), false), canSort: true },
        ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && { Previews: { val: metrics.previews, canSort: true } },
        rowActions: []
    };
}

export const mapCSVData = (metricsReportType, metricsAry) => {
    var newMetricsAry = JSON.parse(JSON.stringify(metricsAry));
    return newMetricsAry.map(metrics => {
        return {
            ...metrics.date && {Date: Utilities.formatObjectDate(metrics.date, "yyyy-MM-dd", true, false, false)},
            ...metrics.category && { Category: metrics.category },
            ...metrics.user && { User: metrics.user.userName },
            ...metrics.prodCenter && { ["Production Center"]: metrics.prodCenter.name },
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION && {["Added"]: metrics.metrics.added},
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {["Downloads"]: metrics.metrics.downloads},
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CREATION && {["Added With Media"]: metrics.metrics.addedWithMedia},
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && {["Downloads With Media"]: metrics.metrics.downloadsWithMedia},
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && { ["Feed Requests"]: metrics.metrics.feeds },
            ["Average Duration"]: Utilities.formatRunTime(Utilities.calcProportionalData(metrics.metrics.duration, metrics.metrics.added)),
            ...metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION && { ["Previews"]: metrics.metrics.previews }
        }
    });
}