import React, { Fragment ,useState, useEffect, useRef  } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import hubApi from 'util/HubApi';
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

//TODO: FINTAN TEST THIS PROPERLY
function OutQForm(props) {

    const { payload: outQData } = props;
    const form = useRef(null);

    const [state, setState] = useState({
        outQ: outQData.outQ,
        verbatim: outQData.verbatim
    })

    const mediaId = outQData.elementId
    const newsItemId = outQData.newsItemId

    const onChange = (event) => {
        setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const submitHandler = async (event) => {
        Logger.debug('props: ', props)
        Logger.debug('newsItemId: ', newsItemId)
        event.preventDefault()
        var updateMediaObjPropertiesRequest = {
            outQ: state.outQ,
            verbatim: state.verbatim,
        }

        var response = await hubApi.updateMediaObjProperties(mediaId, updateMediaObjPropertiesRequest);
        if (response.ok) {
            Logger.debug(`Updated Media Object Properties.`);
            //await hubApi.fetchMediaObj(this.mediaId);
            props.getNewsItemSubObject(newsItemId) // grab newsitem subobjects of the NEWSITEM id
            props.onFormSubmitted();
        }
        else {
            Logger.error(`Update Media Object Properties failed, response=.`, response);
            setState(prevState => ({
                ...prevState,
                formError: `Error ${response.status}: ${response.statusText}` 
            }));
        }
    }


    return (
        <Form
            ref={form}
            onSubmit={submitHandler}
            noValidate
        >
            <Row>
                <Col xs={12}>
                    <TextField
                        value={state.outQ}
                        name='outQ'
                        onChange={onChange}
                        type='text'
                        id='outQ'
                        label='Out-Queue'
                    >
                    </TextField>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <TextField
                        value={state.verbatim}
                        name='verbatim'
                        onChange={onChange}
                        type='email'
                        id='verbatim'
                        label='Verbatim'
                    >
                    </TextField>
                </Col>
            </Row>
            {state.formError ? (
                <div className='errorMsg' style={{ textAlign: "center" }}>{state.formError}</div>
            ) : (
                    <Fragment />
                )}
            <Button
                color='light'
                type='submit'
                size='sm'
                className='float-right mr-0'
            >
                Save
            </Button>
        </Form>
    );
    
}

export default connect(
    null,
    dispatch => bindActionCreators(mapDispatchToHubObjectBoardProps, dispatch)
)(OutQForm);