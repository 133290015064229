import * as Logger from 'loglevel';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import Utilities, {UtilConstant} from 'hub-utilities'

const apiFetch = (method, body) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: `/api/metrics`,
        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,
        begin: fetchMetricsBegin,
        success: fetchMetricsSuccess,
        error: fetchMetricsFailure,
    }
});

const fetchMetricsBegin = () => ({
    type: ActionType.FETCH_METRICS_BEGIN
});

const fetchMetricsSuccess = payload => ({
    type: ActionType.FETCH_METRICS_SUCCESS,
    payload: payload
});

const fetchMetricsFailure = error => ({
    type: ActionType.FETCH_METRICS_FAILURE,
    payload: error
});

export const mapStateToMetricsProps = state => {
    return state.hubMetrics;
}

export const mapDispatchToMetricsProps = {
    getHubMetrics: (startDate, endDate) => async (dispatch, getState) => {
        //Logger.debug("isTable", isTable)
        //await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined));
        const _startDate = new Date(startDate); // or else there's memory allocation bug where setting endDate sets startDate (reference type)
        _startDate.setHours(0,0,0,0);
        const _endDate = new Date(endDate); 
        _endDate.setHours(23,59,59,999);
        await dispatch(apiFetch("POST", {
            startDate: _startDate,
            endDate: _endDate
        }));
    },
}