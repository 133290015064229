import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { Row, Col } from 'react-bootstrap';

import * as HubConstant from 'util/HubConstant';
import Utilities, { UtilConstant } from 'hub-utilities';
import { EditorConstant } from 'hub-dashboard-framework';
import authService from 'components/api-authorization/AuthorizeService';

import 'styles/main-content/Vocabulary.scss'

class Vocabulary extends React.Component {
    constructor(props) {
        super(props);
        this.headerConfig = {
            Value: { val: "", canSort: true },
            rowActions: [
                { action: TableRowAction.EDIT_ROW_EDITOR, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
            ]
        };
    }

    async componentWillMount() {
        Logger.debug(this.props.formData);
        this.setState({ fetchedData: this.fetchData(this.props.formData) });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //Don't requestNodes here, or else datatable would fetch twice in the beginning, and 
        //mistreat it as new data added
        return new Promise((resolve, reject) => {
            Logger.debug(nextPropsFromRedux)
            if (this.props.changeNotifier !== nextPropsFromRedux.changeNotifier) {
                Logger.debug(nextPropsFromRedux.formData)
                this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.formData) }, () => resolve(true));
            } else {
                resolve(true);
            }
        })
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            Logger.debug(objects);
            return objects.map((o, index) => {
                Logger.debug("o", JSON.parse(JSON.stringify(o)))
                return (
                    {
                        rowEditorData: [
                            Utilities.verifyDataHasCorrectDataType(o.keyword, UtilConstant.HUB_DATA_TYPE.DATETIME) ? { placeholder: o.keyword, type: EditorConstant.DataEditorType.DATE_TIME_FIELD } :
                                Utilities.verifyDataHasCorrectDataType(o.keyword, UtilConstant.HUB_DATA_TYPE.DECIMAL) ? { placeholder: o.keyword, type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: "number" } :
                                    { placeholder: o.keyword, type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: "text" }
                        ],
                        id: o.index,
                        type: HubConstant.HUB_VOCABULARY,
                        //Lower case keys won't get posted to table
                        Value: { val: o.keyword, canSort: true },
                        rowActions: [
                            { action: TableRowAction.EDIT_ROW_EDITOR, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
                        ]
                    }
                );
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        Logger.debug("FETCHED DATA: ", this.state.fetchedData);
        Logger.debug("DATAACTION: ", this.props.action);
        Logger.debug(this.props.requiredDataType);
        Logger.debug(this.props.changeNotifier);
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable
                        activeTableName={{ singular: "Value", plural: "Values" }}
                        isPageless={false}
                        canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        rowEditorConfig={{ hasInitialVal: true, isMultiColVal: false }}
                        tools={[
                            {
                                action: TableToolType.ADD_ROW_EDITOR, editorData: [                            
                                    this.props.requiredDataType === UtilConstant.HUB_DATA_TYPE.DATETIME ? { placeholder: "New Value", type: EditorConstant.DataEditorType.DATE_TIME_FIELD } :
                                    this.props.requiredDataType === UtilConstant.HUB_DATA_TYPE.DECIMAL ? { placeholder: "New Value", type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: "number" } :
                                        { placeholder: "New Value", type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: "text" }]
                            },
                            { action: TableToolType.FILTER_SEARCH },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.MESSAGE }]}
                        dataAction={this.props.action}
                        changeNotifier={this.props.changeNotifier}
                        // allowInputAddRow
                        // allowInputEditRow
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Vocabulary">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData && this.state.fetchedData != null) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Vocabulary;