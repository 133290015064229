import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType';
import scnReducer from '../../scn/ScnSink';
import { DataTableConstant } from 'hub-dashboard-framework'

const initialState = {
    error: null,
    activeItemId: 0,
    users: null,
    action: DataTableConstant.HUB_ACTION_STATE.NONE
};

export const reducer = (state, action) => {
    Logger.debug(`HubUser reducer: action = ${action.type}`);
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_HUB_USERS_BEGIN:
            return ({
                ...state,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING
            });
        case ActionType.FETCH_HUB_USERS_SUCCESS:
            return (
                {
                    ...state,
                    users: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE
                });
        case ActionType.FETCH_HUB_USERS_FAILURE:
            return (
                {
                    ...state,
                    activeFeedId: 0,
                    users: [],
                    error: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        case ActionType.SCN_UPDATE_USER:
            return scnReducer.onScnUpdateUser(state, action.payload);
        default:
            break;
    }
    return state;
}

