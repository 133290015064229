
import React from 'react';
import 'styles/libraries/AnimatingArrows.scss';

class AnimatingArrows extends React.Component {

    render() {
        return (
            <div id="arrowAnim" className={!this.props.isHorizontallyExpanded ? `rightArrow` : `leftArrow`}>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""}`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay1`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay2`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay3`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay4`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay5`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay6`}>
                    <div className="arrow"></div>
                </div>
                <div className={`${!!this.props.shouldAnimate ? this.props.isHorizontallyExpanded ? "leftArrowSliding" : "rightArrowSliding" : ""} delay7`}>
                    <div className="arrow"></div>
                </div>
            </div>
        )
    }
}

export default AnimatingArrows;