import React from 'react'
import { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import * as Logger from 'loglevel';
import authService from './AuthorizeService'

export default class AuthorizeRoute extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            ready: false,
            authenticated: false
        };
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticatedAsync();
        Logger.debug(`Auth State loaded: new state = ${authenticated}`);
        if (this._isMounted) {
            this.setState({ ready: true, authenticated });
        }
    }

    async authenticationChanged() {
        if (this._isMounted) {
            this.setState({ ready: false, authenticated: false });
        }
        Logger.debug(`Auth State changed: waiting new state`);
        await this.populateAuthenticationState();
    }

    componentDidMount() {
        this._isMounted = true;
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        Logger.debug(`AuthorizeRoute mounted: Subscribed to AuthService, id = ${this._subscription}`);
        this.populateAuthenticationState();
    }

    componentWillUnmount() {
        this._isMounted = false;
        authService.unsubscribe(this._subscription);
        Logger.debug(`AuthorizeRoute unmounted: unsubscribed to AuthService, id = ${this._subscription}`);
    }

    render() {
        const { ready, authenticated } = this.state;
        // /authentication/login?returnUrl={current url}
        const loginPage = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`
        if (!ready) {
            return <div>Logging in...</div>;
        } else {
            const { component: Component, ...rest } = this.props;
            Logger.debug('AuthorizeRoute props=', this.props);
            Logger.debug('AuthorizeRoute Component=', Component);
            Logger.debug('AuthorizeRoute rest=', rest);
            return <Route {...rest}
                render={(props) => {
                    if (authenticated) {
                        Logger.debug(`AuthorizeRoute: Authenticated, rendering target component`);
                        return <Component {...props} />
                    } else {
                        Logger.debug(`AuthorizeRoute: Redirecting to login page ${loginPage}`);
                        return <Redirect to={loginPage} />
                    }
                }} />
        }
    }


}
