import * as Logger from 'loglevel';
import * as signalR from "@microsoft/signalr";
import * as FtOpCope from './FtOpCode';
import * as ActionType from '../ActionType';

export const createHubFtClientMiddleware = (url) => {

    return (store) => {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect()
            .build();

        connection.on("onFTReceived", (message) => {
            Logger.debug(`FT Received`, message)
            let ft = JSON.parse(message);
            Logger.debug(`FT =`, ft)
            Logger.debug(`store =`, store)
            let actionType = null;
            switch (ft.opCode) {
                case FtOpCope.FT_UPDATE_PERMISSION:
                    actionType = ActionType.FT_UPDATE_PERMISSION;
                    break;
                case FtOpCope.FT_DELETE_PERMISSION:
                    actionType = ActionType.FT_DELETE_PERMISSION;
                    break;
                case FtOpCope.FT_ADD_PERMISSION:
                    actionType = ActionType.FT_ADD_PERMISSION;
                    break;
                case FtOpCope.FT_ADD_FOLDER:
                    actionType = ActionType.FT_ADD_FOLDER;
                    break;
                case FtOpCope.FT_DELETE_FOLDER:
                    actionType = ActionType.FT_DELETE_FOLDER;
                    break;
                case FtOpCope.FT_UPDATE_FOLDER:
                    actionType = ActionType.FT_UPDATE_FOLDER;
                    break;
                default:
                    break;
            }

            Logger.debug(`actionType =`, actionType)
            try {
                if (!!actionType) {
                    store.dispatch({
                        type: actionType,
                        payload: ft.content
                    });
                    Logger.debug(`FT action ${actionType} dispatched`);
                }
                else {
                    Logger.error(`FT ignored: no action type!`);
                }
            }
            catch (error) {
                Logger.error(`Ft error: ${error}`);
            }

            Logger.debug(`Ft Processed`)
        });
        connection.start().then(() => {
            Logger.debug("FolderHubClient connected");
        }).catch((error) => {
            console.error("Error starting FolderHubClient:", error);
        });

        return next => action => {
            var result = next(action)
            return result;
        };
    }
}

