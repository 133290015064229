import React from "react"
import { Button, Modal } from 'react-bootstrap'
import { CardText } from 'reactstrap'

export function DeleteFolderModal(props) {


    const onDeleteConfirm = async (event) => {
        //add delete functionality
        event.preventDefault()

        props.onDeleteFolder();

        
    }

    return (
        <Modal show={props.showModal} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CardText>{`Are you sure you want to delete the folder: ${props.folderName}? Note this is action is permanent!`}</CardText>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={onDeleteConfirm}>
                    Yes
                </Button>
                <Button variant='secondary' onClick={props.onHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}