import React, {useState, useEffect, useRef} from "react";
import { Button, Form, Row, Col } from 'react-bootstrap'
import { TextField } from '@material-ui/core';
import * as Logger from 'loglevel';
import authService from '../../api-authorization/AuthorizeService';
import Utilities from 'hub-utilities'
import Cookies from 'js-cookie';
import * as HubConstant from 'util/HubConstant';

import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { MultiValsSelector } from 'hub-dashboard-framework';

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import zIndex from "@material-ui/core/styles/zIndex";


function StoryForm(props) {

    
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [keywords, setKeywords] = useState("");
    const [fileName, setFileName] = useState(null);
    const [formError, setFormError] = useState(null);
    const [formStatus, setFormStatus] = useState();
    const [hasError, setHasError] = useState();
    const [isStatusEnabled, setIsStatusEnabled] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [isCreatingStory, setIsCreatingStory] = useState(false);
    const [isBrowseWindowOpen, setIsBrowseWindowOpen] = useState(false);
    const [isUrgent, setIsUrgent] = useState(false);
    const [editorActiveIndex, setEditorActiveIndex] = useState(-1);

    const form = useRef(null);
    const storyTitle = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(()  => {

        async function getCachedData(){
            var cachedTitle = await Cookies.get('CreateStoryTitle');

            var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
            var cachedText = "";
            if (!!numOfBodyCookies) {
                for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                    cachedText += await Cookies.get(`CreateStoryBody${i}`);
                }
            }

            setTitle(!!cachedTitle ? cachedTitle : "")
            setText(!!cachedText ? cachedText : "")
        }
        getCachedData();
    },[]);


    useEffect(() => {
        if (storyTitle.current) {
            storyTitle.current.focus();
        }
    }, [])

    useEffect(() => {
        const creatingStory = async () => {
            if (isCreatingStory) {
                await createStoryInFolder();
                setIsCreatingStory(false);
            }
        }

        creatingStory();
        
    }, [isCreatingStory])

    const setEditorActiveIndexRow = (rowIndex) => {
        if (rowIndex === editorActiveIndex) {
            //Set editing row to none
            setEditorActiveIndex(-1);

        } else {
            setEditorActiveIndex(rowIndex);

        }
    }

    //Each cookie has 4096 byte limit (around 3900 characters)
    const cacheCreateStoryInfo = (stateName, val) => {
        if (stateName === 'title') {
            Cookies.set(`CreateStoryTitle`, val);
        }
        else if (stateName === 'text') {
            var infoArrs = Utilities.chunkInfoOnExceedByteLimit(val, 4096);
            for (var i = 0; i < infoArrs.length; i++) {
                Cookies.set(`CreateStoryBody${i}`, infoArrs[i]);
            }
            Cookies.set(`NumOfBodyCookies`, infoArrs.length);
        }
    }

    const clearCookies= async () => {
        var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
        if (!!numOfBodyCookies) {
            for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                Cookies.set(`CreateStoryBody${i}`, "");
            }
        }

        Cookies.set(`CreateStoryTitle`, "");
        Cookies.set('NumOfBodyCookies', "");
    }

    const validate = () => (form.current.reportValidity());


    const createStoryInFolder = async () => {
        //To prevent story from being double-created: 
        const response = await props.createNewsItemWithMetadataInFolder({
            name: title,
            text: text,
            newsObjectDesc: {
                keyword: keywords,
                priorityLevel: isUrgent ? 5 : 7
            },
            elements: attachments.map(a => (a.elementId)),
        }, HubConstant.HUB_OBJECT_STORY, props.folderData.id);

        if (response.ok) {
            let respObj = await response.json()
            Logger.debug(respObj)
            let story = respObj.hubObject;
            if (!!props.onStoryCreated) {
                props.onStoryCreated(story);
            }
            if (!!props.onFormSubmitted) {
                props.onFormSubmitted();
            }
            clearCookies();
        }
        else {
            let respText = await response.text();
            setFormError(`Creating story failed: ${respText}`)
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        if (validate() && !isCreatingStory) {
            setIsCreatingStory(true)
        }
    };

    const toggleStatus = (e) => {
        e.preventDefault();
        setIsStatusEnabled(!isStatusEnabled)
        
    }

    const changeHandler = event => {
        cacheCreateStoryInfo(event.target.name, event.target.value);
        switch(event.target.name){
            case "title":
                setTitle(event.target.value);
                break;
            case "text":
                setText(event.target.value);
                break;
            case "keywords":
                setKeywords(event.target.value);
                break;
            default:
                break;
        }      
    };

    const fileUploadHandler = async event => {
        props.setIsBrowseWindowOpen(false);
        if (event.target.value.length == 0) {
            setFormError(null);
            setFileName(null);
            setFormStatus(null);
            return;
        }

        Logger.debug(`Uploading file ${event.target.value} started`);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);
        setFileName(selectedFilename);
        setFormStatus(null);
        setUploading(true);
        
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/CloudQ/1/file`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });
        Logger.debug('fileUploadHandler response = ', response);
        setUploading(false);

        let respObject = await response.json();
        let newSts;
        if (response.ok) {

            let elementId = respObject.hubObject.id;
            Logger.debug(`new media element ${elementId} created.`);
            let existingAttachments = attachments;

            setFormError(null);
            setAttachments(existingAttachments.concat({ elementId: elementId, fileName: selectedFilename }));
            setFileName(selectedFilename);
        }
        else {
            setFormError(`Uploading ${selectedFilename} failed: ${respObject.error}`);
            setHasError(false);
            setFileName(null);
        }
        
    };

    const setCategoriesToSelect = (newArr) => {
        var categories = props.getCategory();
        if (!!categories) {
            props.onLocalResetNewsItemMetadata(categories.fieldId, newArr.map(val => {
                return { index: val.id, value: val.name }
            }));
        }
    }

    const renderCategory = () => {
        var availableCategories = props.getCategory();
        var chosenCategoriesState = !!props.storyMetadata ? props.storyMetadata.find(mdata => mdata.fieldId === 1) : null;
        //Logger.debug(availableCategories.vocabulary.map(vocab => vocab.keyword));
        //Logger.debug(chosenCategoriesState);
        return (
            !!availableCategories && !!chosenCategoriesState ?
                <>
                    {/* Change to set to remove duplicates */}
                    <div className="multiValsSelectorWrapper">
                        <MultiValsSelector
                            id={0}
                            title={availableCategories.fieldName}
                            toggleIcon={faListAlt}
                            availableVals={availableCategories.vocabulary.map(vocab => { return { id: vocab.index, name: vocab.keyword } })}
                            selectedVals={chosenCategoriesState.values.map(value => { return { id: value.index, name: value.value } })}
                            setSelectedVals={setCategoriesToSelect}
                        />
                    </div>

                    {/* {chosenCategoriesState.values.map(val => {
                        Logger.debug(val);
                        return (
                            <div name="" className="categoryBadge">
                                {val.value}<FontAwesomeIcon className="deleteIcon" icon={faTrash} onClick={() => this.props.onDeleteSelectedVal(chosenCategoriesState.fieldId, val.index)} />
                            </div>
                        );
                    })}
                    <RowEditor
                        title="Add Category"
                        icon={faPlusCircle}
                        mediaEditInfo={[
                            availableCategories.isControlledVocabulary ? { type: DataEditorType.DROP_DOWN, dataType: availableCategories.dataType, dropdownVal: availableCategories.vocabulary, key: "keyword" } :
                            availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.TEXT || availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.KEYWORD ?
                                    { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "text" } :
                                    availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.DECIMAL ?
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "number" } :
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.DATE_TIME_FIELD }
                        ]}
                        editorId={availableCategories.fieldId}
                        objectType={this.props.objectType}
                        editingRowIndex={this.state.editorActiveIndex}
                        setEditingRow={this.setEditorActiveIndexRow}
                        onSubmitRowEditor={this.props.onSubmitRowEditor}
                    /> */}
                </>
                : <></>
        );
    }

    const handleAddMediaClick = () => {
        props.setIsBrowseWindowOpen(true);
        fileInputRef.current.click(); 
    };

    const collapsibleLoaderOverride = css`
        position: relative;
        margin-top: 5px !important;
        margin-bottom: 0px !important;`;

    const uploadMediaButtonOverride = css`
    position: relative;
    margin-top: 0 !important;`;

    let uploadedAttachments = null;
    if (attachments.length > 0) {
        let fileList = attachments.map(a => (<div key={a.elementId}>{a.fileName}</div>));
        uploadedAttachments = <div><div>Uploaded files:</div>{fileList}</div>;
    }
    return (
        <div>
            <Form ref={form} className="needs-validation StoryDetailsForm" onSubmit={submitHandler} >
                <Row className="ImportantInfoRow">
                    <Col xs="10">
                        <TextField
                            ref={storyTitle}
                            value={title}
                            name="title"
                            onChange={changeHandler}
                            type="text"
                            id="storyTitle"
                            className="form-control"
                            label="Story Title"
                            required
                        />
                    </Col>
                    <Col xs="2" className="LargeCheckbox">
                        <div className="formTitle">Urgent?</div>
                        <label
                            className='checkboxContainer'
                        >
                            <input
                                id={`UrgencyCheckbox`}
                                name={`UrgencyCheckbox`}
                                className="filterGroupChild"
                                label=""
                                type="checkbox"
                                onChange={() => {
                                    var newUrgency = !isUrgent;
                                    setIsUrgent(newUrgency);
                                    // setState({ isUrgent: newUrgency });
                                }}
                                // onChange={(e) => this.handleCheckboxChange(e, availableFilterObjs, objsToFilter, null)}
                                checked={isUrgent}
                            />
                            <span className='checkmark large' />
                        </label>
                    </Col>
                </Row>
                <div className="invalid-feedback">
                    Please provide a title for the story.
                </div>
                <div className="valid-feedback">Looks good!</div>
                <textarea
                    value={text}
                    name="text"
                    onChange={changeHandler}
                    id="storyText"
                    className="form-control"
                    placeholder="Story Text"
                    rows="6"
                />
                {renderCategory()}
                <TextField
                    value={keywords}
                    name="keywords"
                    onChange={changeHandler}
                    type="text"
                    id="storyKeyword"
                    className="form-control storyKeyword"
                    label="Tags"
                    InputLabelProps={{
                        style: {
                            zIndex:9999
                        },
                    }}
                />
                <div className="input-group">
                    <div className="custom-file">
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: "none" }}
                            onChange={fileUploadHandler}
                            onClick={() => props.setIsBrowseWindowOpen(true)}
                            className="custom-file-input"
                            id="inputGroupFile01"
                            aria-describedby="inputGroupFileAddon01"
                        />
                        <Button
                            className={`uploadMediaButton ${uploading ? "uploading" : ""}`}
                            onClick={handleAddMediaClick}
                            style={{ pointerEvents: "auto" }}
                        >
                            {uploading ? (
                                <ClipLoader
                                    size={20}
                                    color={"#f69b1d"}
                                    css={uploadMediaButtonOverride}
                                />
                            ) : (
                                "Add Media"
                            )}
                        </Button>
                    </div>
                </div>
                {attachments && attachments.length > 0 ? <div className="uploadedMediaFileDisplay">
                    {uploadedAttachments}
                </div> : <div></div>}
                {
                    !!formError ?
                        <div className="errorMsg">
                            {formError}
                        </div>
                        :
                        <></>
                }
                <Button className={uploading ? "disabled" : ""} type="submit" size="sm">
                    {uploading ?
                        <ClipLoader
                            size={20}
                            color={"#f69b1d"}
                            css={collapsibleLoaderOverride}
                        />
                        :
                        "Create"}
                </Button>
            </Form >
        </div >
    );
}

export default StoryForm;