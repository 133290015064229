import React, { useState, useEffect, Fragment } from 'react';
import { DataTable, TableToolType } from 'hub-dashboard-framework';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import authService from 'components/api-authorization/AuthorizeService';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import 'styles/main-content/Users.scss';
import { onClickDownloadCSVButton, mapMetricsDetailsToTableData, initializeMetricDetailsHeader, mapCSVData } from 'util/DataRenderHelper';

const UsersMetricsTable = (props) => {
    const [fetchedData, setFetchedData] = useState([]);

    const headerConfig = {
        User: { val: "", canSort: true },
        ...initializeMetricDetailsHeader(props.metricsReportType)
    };

    useEffect(() => {
        setFetchedData(fetchData(props.metrics));
    }, [props.metrics, props.action]);

    const fetchData = (objects) => {
        if (objects !== undefined && objects !== null && objects.length > 0) {
            return objects.sort((a, b) => a.id < b.id ? 1 : -1).map((o) => ({
                User: { val: o.user.userName, canSort: true },
                ...mapMetricsDetailsToTableData(o.metrics, props.metricsReportType)
            }));
        } else {
            return [];
        }
    };

    const displayDatatable = () => (
        <Row className="tableRow">
            <Col xs="12">
                <DataTable
                    headerConfig={headerConfig}
                    data={fetchedData}
                    tools={[
                        { action: TableToolType.PAGINATION },
                        { action: TableToolType.FILTER_SEARCH },
                        {
                            action: TableToolType.CUSTOMIZED_BUTTON,
                            tooltipName: 'Download CSV',
                            buttonIcon: faFileDownload,
                            onClick: () => onClickDownloadCSVButton("newshub_metrics_users", mapCSVData(props.metricsReportType, props.metrics))
                        },
                    ]}
                    canDeleteRow={false}
                    numOfRowsPerPage={5}
                    authorizeService={authService}
                    {...props}
                />
            </Col>
        </Row>
    );

    return (
        <div className="UsersMetricsTable">
            <Row className="structureRow">
                <Col xs="12">
                    {fetchedData.length > 0 ? displayDatatable() : <Fragment key="0"></Fragment>}
                </Col>
            </Row>
        </div>
    );
};

export default withRouter(UsersMetricsTable);
