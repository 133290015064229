import React, { useState, useEffect } from 'react';
import * as Logger from 'loglevel';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DataTableConstant } from 'hub-dashboard-framework';

export function HubNodeSelector(props) {
    const [activeNodeId, setActiveNodeId] = useState(props.nodeId);

    useEffect(() => {
        Logger.debug(`NodeSelector componentDidMount`);
        const initialNode = props.nodes.find(f => f.name === props.initialNode);

        if (props.initialNode && initialNode) {
            setActiveNodeId(initialNode.id);
        }
    }, []);

    useEffect(() => {
        Logger.debug(`NodeSelector componentDidUpdate`);
    });

    const onHubNodeSelected = (node) => {
        Logger.debug(`NodeSelector: onHubNodeSelected(${node})`);
        if (node.id < 0) {
            node.id = props.nodes[0].id;
        }
        props.onHubNodeSelected(node);
        Logger.debug('onHubNodeSelected: ' + node);
        setActiveNodeId(node.id);
    };

    const getActiveNode = () => {
        const index = (
            typeof activeNodeId === 'number'
                ? activeNodeId >= 0
                : !!activeNodeId
        )
            ? props.nodes.findIndex(f => f.id === activeNodeId)
            : 0;

        if (index < 0) {
            return null;
        }
        return props.nodes[index];
    };

    const renderDropdown = (canModify, node, dropItems) => {
        Logger.debug(node);
        Logger.debug(dropItems);
        Logger.debug(props.nodes);
        return (
            <Dropdown className="formDropdown nodeDropdown">
                <Dropdown.Toggle
                    id="dropdown-basic"
                    disabled={canModify !== undefined && !canModify}
                >
                    {node.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>{dropItems}</Dropdown.Menu>
            </Dropdown>
        );
    };

    if (props.action === DataTableConstant.HUB_ACTION_STATE.LOADING) {
        return <div>Loading Nodes...</div>;
    }
    if (props.error) {
        return <div>Loading Nodes failed, error={props.error}</div>;
    }
    if (props.nodes == null) {
        Logger.debug(`Rendering "No Nodes"`);
        return <div> No Hub Node loaded </div>;
    }

    const node = getActiveNode();
    const dropItems = props.nodes.map(n => {
        return (
            <Dropdown.Item
                key={n.id}
                active={n.id === node.id}
                onClick={() => onHubNodeSelected(n)}
            >
                {n.name}
            </Dropdown.Item>
        );
    });

    return (
        <>
            <div className="formTitle">Production Centre</div>
            {props.canModify !== undefined && !props.canModify ? (
                <OverlayTrigger
                    key="cantModify"
                    placement="bottom"
                    overlay={
                        <Tooltip className="dropdownTooltip rowActionTooltip">
                            You Have No<br />
                            Permission to Modify<br />
                            Production Centre
                        </Tooltip>
                    }
                >
                    {renderDropdown(props.canModify, node, dropItems)}
                </OverlayTrigger>
            ) : (
                renderDropdown(props.canModify, node, dropItems)
            )}
        </>
    );
}

export default HubNodeSelector;