import React , { useState, useEffect, useRef } from "react";
import { TextField } from '@material-ui/core';
import { Dropdown, Form, OverlayTrigger, Tooltip, Overlay, Button, Row, Col } from 'react-bootstrap';
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapMetadataStateToProps, mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import VocabularyPopup from './VocabularyPopup';

import Utilities, { UtilConstant} from 'hub-utilities';
import * as _ from 'lodash';

import 'styles/widgets/Metadata.scss'


export function MetadataForm(props) {
    const { payload: metadata } = props;
    const form = useRef(null);
    const vocabFormRef = useRef(null);

    const newMetadata = !!metadata ? JSON.parse(JSON.stringify(metadata)) : null;
    Logger.debug(newMetadata);

    const [controlledAfterAPI, setControlledAfterAPI] = useState(metadata ? metadata["Controlled?"].val === "✓" : true)
    const [fieldName, setFieldName] = useState(metadata ? metadata.FieldName.val : '')
    const [dataType, setDataType] = useState(metadata ? metadata.DataType.actualVal : UtilConstant.HUB_DATA_TYPE.DECIMAL)
    const [description, setDescription] = useState(metadata ? metadata.description : '')
    const [controlled, setControlled] = useState(metadata ? metadata["Controlled?"].val === "✓" : true)
    const [vocabularies, setVocabularies] = useState(metadata ? newMetadata.Values.formVal : [])
    const [filterSearchText, setFilterSearchText] = useState('')
    const [formError, setFormError] = useState(undefined)
    const [isStatusEnabled, setIsStatusEnabled] = useState(false)
    const [changeNotifier, setChangeNotifier] = useState(false)
    const [isFocusingMembersUI, setIsFocusingMembersUI] = useState(false)
    const [isCreatingMetadata, setIsCreatingMetadata] = useState(false)
    const [showVocabForm, setShowVocabForm] = useState(false)
    const [vocabFormTarget, setVocabFormTarget] = useState(null)
    const [isRightDataType, setIsRightDataType] = useState(true)
    const [virtualIndex, setVirtualIndex] = useState(metadata ? -2 : undefined)


    const metadataId = !!metadata ? metadata.id : -1;
    const isSysDefinedVar = !!metadata && metadata.id <= 10000;
    Logger.debug(isSysDefinedVar);
    Logger.debug(metadataId);



    const [initialFieldName, setInitialFieldName] = useState(!!metadata ? metadata.FieldName.val : "");
    const [initialDataType, setInitialDataType] = useState(!!metadata ? metadata.DataType.actualVal : -1);
    const [initialDescription, setInitialDescription] = useState(!!metadata ? metadata.description : "");

    useEffect(() => {
        Logger.debug("right Data Type? ", isRightDataType);
        const newMetadata = props.metaData.find(mdata => mdata.fieldId === metadataId);
        if (!!newMetadata && metadataId !== -1) {

            Logger.debug(newMetadata.vocabulary);
            Logger.debug(vocabularies);
            const isArrayEqual = _.isEmpty(_.differenceWith(newMetadata.vocabulary, vocabularies, _.isEqual))
                && vocabularies.length === newMetadata.vocabulary.length;
            Logger.debug(isArrayEqual)
            if (!isArrayEqual) {
                const newNotifier = !changeNotifier;
                
                setVocabularies(newMetadata.vocabulary);
                setChangeNotifier(newNotifier);
            }

            if (newMetadata.isControlledVocabulary !== controlledAfterAPI) {

                setControlledAfterAPI(metadata.isControlledVocabulary);
            }
        } else {
            // metadata is probably deleted... force-quit the editor
        }

    }, [props]);

    useEffect(() => {
        if (isStatusEnabled) {
            props.onFormClosed();
        }
    }, [isStatusEnabled, props]);

    //Filter text:
    const setFilterSearchBarText = (text) => {

        const newNotifier = !changeNotifier
        setFilterSearchText(text);
        setChangeNotifier(newNotifier);
    }

    const setAreDataOfRightType = (isRight) => {
        Logger.debug(isRight);
        setIsRightDataType(isRight);
    }

    const onChange = event => {
        props.editMetadata();

        switch(event.target.name){
            case "fieldName":
                setFieldName(event.target.value);
                break;
            case "description":
                setDescription(event.target.value);
                break;
            default:
                break;
        }
    }

    const onSelectDataType = value => {
        //New change notifier for dataType detection for popup:
        let newChangeNotifier = !changeNotifier;
        setDataType(typeof value == "string" ? parseInt(value) : value);
        setChangeNotifier(newChangeNotifier)

    }

    const validateForm = () => (form.current.checkValidity());

    const submitHandler = (event) => {
        event.preventDefault();
        if (isRightDataType) {
            Logger.debug('Saving metadata, event=', event);
            if (!event.target.className.includes(" was-validated")) {
                event.target.className += " was-validated";
            }
            if (validateForm()) {
                saveMetadata();
            }
        }
    };

    //set state is required => async resolve promise
    const onConfirmAddInputRow = (addRowField) => {
        var oldVocabularies = vocabularies;
        let newChangeNotifier = !changeNotifier
        oldVocabularies.push(addRowField);
        Logger.debug(oldVocabularies);

        setVocabularies(oldVocabularies,
            ()=>setChangeNotifier(newChangeNotifier,
                ()=>setAreDataOfRightType(Utilities.verifyAllVocabsHaveCorrectDataType(vocabularies, parseInt(dataType)))))
    }

    const onDeleteRow = (deleteObj) => {
        var oldVocabularies = vocabularies.filter(obj => obj !== deleteObj);
        let newChangeNotifier = !changeNotifier;
        Logger.debug(oldVocabularies);

        setVocabularies(oldVocabularies,
            ()=>setChangeNotifier(newChangeNotifier,
                ()=>setAreDataOfRightType(Utilities.verifyAllVocabsHaveCorrectDataType(vocabularies, parseInt(dataType)))))
    }


    function propogateMetaData(isCreate, isControlledEdgeCase) {
        //var localdataType = !!isControlledEdgeCase ? initialDataType : dataType
        return {
            fieldName: !!isControlledEdgeCase ? initialFieldName : fieldName,
            description: !!isControlledEdgeCase ? initialFieldName : description,
            dataType: typeof dataType !== 'number' ? parseInt(dataType) : dataType,
            isControlledVocabulary: controlled,
            ...controlled && { vocabulary: !!isControlledEdgeCase ? [] : vocabularies.map(vocab => { return { value: vocab.keyword } }) }
        }
    }

    
    const createMetadata = async () => {
        await props.addMetadata(propogateMetaData(true));

        if (!!props.success) {
            setIsStatusEnabled(true);

        } else if (!!props.error) {
            setIsStatusEnabled(true);
        }
        props.onFormClosed()
    }

    
    const updateMetadata = async (isControlledEdgeCase) => {
        Logger.debug("Updating");

        Logger.debug(Utilities.metadataTypeEnumToStr(dataType));
        await props.updateMetadata(metadataId, propogateMetaData(false, isControlledEdgeCase));

        if (!!props.success) {
            setIsStatusEnabled(true)
        } else if (!!props.error) {
            setIsStatusEnabled(true);
        }
        props.onFormClosed()
    }

    const saveMetadata = async () => {
        const { payload: metadata } = props;
        if (!!metadata) {
            await updateMetadata();
        }
        else {
            await createMetadata();
        }
    }

    function renderStatus() {
        if (!!props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">{props.error}</p></Col></Row>
        } else if (!!props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{props.success}</p></Col></Row>
        }
    }

    function renderFieldName() {
        return (
            <TextField
                group
                value={fieldName}
                name="fieldName"
                onChange={onChange}
                type="text"
                id="userformPassword1"
                label={isSysDefinedVar ? "Field Name" : "FIELD NAME"}
                required={!isSysDefinedVar}
                disabled={isSysDefinedVar}
            >
                <div className="invalid-feedback">This field cannot be empty!</div>
            </TextField>
        )
    }

    const verifyDataType = (value) => {
        Logger.debug(value);
        setIsRightDataType(Utilities.verifyAllVocabsHaveCorrectDataType(vocabularies.map(vocab => vocab.keyword), isNaN(value) ? Utilities.metadataTypeStrToEnum(value) : value));
    }

    function renderDataType() { 
        return (
            <div>
                <div className="formTitle">Data Type</div>
                <Dropdown className="formDropdown" onSelect={onSelectDataType}>
                    <Dropdown.Toggle id="dropdown-basic" disabled={isSysDefinedVar /*|| showVocabForm*/}>
                        {Utilities.metadataTypeEnumToStr(dataType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            Object.keys(UtilConstant.HUB_DATA_TYPE)
                                .filter(key => isNaN(Number(key))) // Filter out numeric keys
                                .map(key =>
                                    <Dropdown.Item
                                        key={key} // Set a unique key for each item
                                        style={{ color: 'white' }}
                                        onClick={e => { Logger.debug(e.currentTarget.innerHTML); verifyDataType(e.currentTarget.innerHTML) }}
                                        eventKey={Utilities.metadataTypeStrToEnum(key)}>
                                        {Utilities.metadataTypeEnumStrToLowerCase(key)}
                                    </Dropdown.Item>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    function renderIsControlled() {
        const isDisabled = isSysDefinedVar || vocabularies.length > 0;

        const inputStyle = isDisabled
            ? {
                cursor: 'not-allowed',
            }
            : {};

        const buttonStyle = isDisabled
            ? {
                backgroundColor: 'lightgrey',
            }
            : {};

        return (
            <div>
                <div className="formTitle">Is Controlled</div>
                <label className='radioContainer'>
                    <input
                        id="formTitle"
                        key="formTitle"
                        type="radio"
                        label=""
                        checked={controlled}
                        disabled={isDisabled}
                        className={isDisabled ? 'disabled' : ''}
                        onClick={(e) => {
                            var newControlled = !controlled;
                            setControlled(newControlled);
                            if (!newControlled) toggleManageVocabulariesForm(e, true);
                        }}
                        style={inputStyle}
                    />
                    <span className='checkmark' style={buttonStyle} />
                </label>
            </div>
        );
    }



    function renderDescription() {
        return (
            <TextField
                group
                value={description}
                name="description"
                onChange={onChange}
                type="textarea"
                id="userformPassword4"
                label="DESCRIPTION"
                required
            />
        )
    }

    // changeNotifier={changeNotifier}
    // isForm={true}
    // formData={DataHandler.filterAvailableVocabs(vocabularies, filterSearchText, null)}
    // onConfirmAddInputRow={onConfirmAddInputRow}
    // onDeleteRow={onDeleteRow}
    // changeNotifier={changeNotifier}
    // isInputDisabled={!state["Controlled?"]}
    // isDateTime={dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
    // requiredDataType={dataType}
    // setAreDataOfRightType={setAreDataOfRightType

    const renderManageVocabsButton = () => {
        return (
            <Button
                color="light"
                type="button"
                size="sm"
                onClick={(e) => controlled ? toggleManageVocabulariesForm(e) : null}
                className={`${!isRightDataType ? "wrongDataType" : ""} ${!controlled ? "disabled disabledWithTooltip" : ""}`}>Manage Values</Button>
        )
    }

    const toggleManageVocabulariesForm = (e, shouldClose) => {
        props.editMetadata(); //reset error msg
        var newShow = !!shouldClose ? false : !showVocabForm;

        setVocabFormTarget(e.target);
        setShowVocabForm(newShow);
        verifyDataType(dataType);

    }

    //For Add/Edit/Delete vocab:
    const onAddVocab = async (val) => {
        // check duplicates:
        if (vocabularies.map((vocab) => vocab.keyword).includes(val)) {
            // report error:
            props.reportError("Error: Duplicated vocabulary shall not be added");
            Logger.debug("should return false");
            return false;
        } else {
            Logger.debug(val);
            Logger.debug(vocabularies);
            Logger.debug(virtualIndex);

            if (metadataId === -1) {
                // newly-added:
                var newVocab = vocabularies;
                var newVirtualIndex = virtualIndex - 1;
                var newChangeNotifier = !changeNotifier;

                newVocab.push({ index: virtualIndex, keyword: val });
                setVocabularies(newVocab);
                setVirtualIndex(newVirtualIndex);
                setChangeNotifier(newChangeNotifier);
            } else {
                // For edge case: if the user updates IsControlled from false to true then add vocabs immediately:
                if (!controlledAfterAPI && controlled) {
                    await updateMetadata(true);
                }
                props.addMetadataVocab(metadataId, { value: val });
            }
            return true;
        }
    };


    const onEditVocab = async (vocabId, val) => {
        if (metadataId === -1) {
            // newly-edited:
            var aryIndex = vocabularies.findIndex(vocab => vocab.index === vocabId);
            var newVocab = vocabularies;
            var newChangeNotifier = !changeNotifier;

            newVocab[aryIndex] = { index: vocabId, keyword: val };
            setVocabularies(newVocab);
            setChangeNotifier(newChangeNotifier);
        }
        else {
            var foundVocab = vocabularies.find(vocab => vocab.index === vocabId);
            if (!!foundVocab) {
                //For edge case: if user update IsControlled from false to true then add vocabs immediately:
                if (!controlledAfterAPI && controlled) { await updateMetadata(true); }
                props.editMetadataVocab(metadataId, vocabId, { currentValue: foundVocab.keyword, keyword: { value: val } });
            } else {
                //foundVocab is prob being modified at the same time by other ppl, return error here:

            }
        }
    }

    const onDeleteVocab = async (vocabId) => {
        if (metadataId === -1) {
            // newly-deleted:
            var filteredVocabs = vocabularies.filter(vocab => vocab.index !== vocabId);
            var newChangeNotifier = !changeNotifier;
            setVocabularies(filteredVocabs);
            setChangeNotifier(newChangeNotifier)
        }
        else {
            var foundVocab = vocabularies.find(vocab => vocab.index === vocabId);
            await props.deleteMetadataVocab(metadataId, vocabId, foundVocab.keyword);
            var newChangeNotifier = !changeNotifier;
            setChangeNotifier(newChangeNotifier);
        }
    }

    let isUpdateMetadata = !!metadata;
    return (
        <div className="HubUserForm HubNodeGroupForm" ref={vocabFormRef}>
            <Overlay
                show={showVocabForm}
                target={vocabFormTarget}
                placement="right"
                container={vocabFormRef.current}
                containerPadding={20}
            >
                <VocabularyPopup
                    changeNotifier={changeNotifier}
                    formData={vocabularies}
                    isDateTime={dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
                    requiredDataType={dataType}
                    setAreDataOfRightType={setAreDataOfRightType}
                    dataType={dataType}
                    toggleManageVocabulariesForm={toggleManageVocabulariesForm}
                    onAddVocab={onAddVocab}
                    onEditVocab={onEditVocab}
                    onDeleteVocab={onDeleteVocab}
                    message={props.vocabMsg}
                    style={props.style}
                    {...props} />
            </Overlay>
            <Form ref={form} className="needs-validation burli-metadata-form mt-3" onSubmit={submitHandler} noValidate >
                <Row>
                    <Col size="5" className="HubNameRow">
                        {isSysDefinedVar ?
                        <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Built-in Data Field:<br />Field Name is Fixed</Tooltip>}>
                            {renderFieldName()}
                        </OverlayTrigger>
                        : renderFieldName()}
                    </Col>
                    <Col size="4" className="HubNameRow">
                        { /* {showVocabForm ?
                                <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Cannot Edit While<br />Managing Vocabularies</Tooltip>}>
                                    {renderDataType()}
                                </OverlayTrigger> : */ }
                        {
                            isSysDefinedVar ?
                                <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Built-in Data Field:<br />Data Type is Fixed</Tooltip>}>
                                    {renderDataType()}
                                </OverlayTrigger>
                                : renderDataType()}
                    </Col>
                    <Col size="3" className="HubNameRow">
                        {/* {showVocabForm ?
                                <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Cannot Edit While<br />Managing Vocabularies</Tooltip>}>
                                    {renderIsControlled()}
                                </OverlayTrigger> :  */}
                        {isSysDefinedVar ?
                            <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Built-in Data Field:<br />Is Controlled is Fixed</Tooltip>}>
                                {renderIsControlled()}
                            </OverlayTrigger> :
                            vocabularies.length > 0 ?
                                <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Please Remove All<br /> Vocabularies Before<br />Modifying Controlled Settings</Tooltip>}>
                                    {renderIsControlled()}
                                </OverlayTrigger>
                                : renderIsControlled()}
                    </Col>
                </Row>
                <Row>
                    <Col size="12" className="descriptionCol">
                        {renderDescription()}
                    </Col>
                    {/* <Col size="6">
                            <Row className="TitleRow">
                                <Col size="4">
                                    <h5 className={isFocusingMembersUI ? "focused" : ""}>VOCABULARIES</h5>
                                </Col>
                                <Col size="2">
                                </Col>
                                <Col size="6">
                                    <TableFilterSearch
                                        setIsFocusingMembersUI={setIsFocusingMembersUI}
                                        filterSearchText={filterSearchText}
                                        setFilterSearchText={setFilterSearchBarText}>
                                    </TableFilterSearch>
                                </Col>
                            </Row>
                            <div
                                onMouseEnter={() => setState({ isFocusingMembersUI: true })}
                                onMouseLeave={() => setState({ isFocusingMembersUI: false })}>
                                <Vocabulary
                                    changeNotifier={changeNotifier}
                                    isForm={true}
                                    formData={DataHandler.filterAvailableVocabs(vocabularies, filterSearchText, null)}
                                    onConfirmAddInputRow={onConfirmAddInputRow}
                                    onDeleteRow={onDeleteRow}
                                    changeNotifier={changeNotifier}
                                    isInputDisabled={!state["Controlled?"]}
                                    isDateTime={dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
                                    requiredDataType={dataType}
                                    setAreDataOfRightType={setAreDataOfRightType}
                                >
                                </Vocabulary>
                            </div>
                        </Col> */}
                </Row>
                {isStatusEnabled ? renderStatus() : <></>}
                <Row className="SelectionStatus">
                    <Col size="12">
                        <div className="metadataFormButtonsContainer">
                            {!isRightDataType ?
                                <>
                                    <OverlayTrigger key="vocabTypeMismatchTooltip_ManageVocabs" placement="bottom" overlay={
                                        <Tooltip className={isRightDataType ? "" : "wrongDataType"}>Please Fix Vocabulary<br />Type Mismatches</Tooltip>
                                    }>
                                        {renderManageVocabsButton()}
                                    </OverlayTrigger>
                                    <OverlayTrigger key="vocabTypeMismatchTooltip" placement="bottom" overlay={<Tooltip>Please Fix Vocabulary<br />Type Mismatches<br />Before Proceeding</Tooltip>}>
                                        <Button color="light" type="submit" size="sm" className={`disabled disabledWithTooltip`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                    </OverlayTrigger>
                                </>
                                :
                                !controlled ?
                                    <>
                                        <OverlayTrigger key="vocabTypeMismatchTooltip_ManageVocabs" placement="bottom" overlay={
                                            <Tooltip>You Cannot Manage<br />Uncontrolled Vocabularies</Tooltip>
                                        }>
                                            {renderManageVocabsButton()}
                                        </OverlayTrigger>
                                        < Button color="light" type="submit" size="sm" className={`disabledWithTooltip`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                    </>
                                    :
                                    <>
                                        {renderManageVocabsButton()}
                                        < Button color="light" type="submit" size="sm">{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                    </>
                            }
                        </div>
                    </Col>
                </Row>
            </Form >
        </div >
    );
}

export default withRouter(connect(
    mapMetadataStateToProps,
    dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch)
)(MetadataForm));