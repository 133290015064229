import * as ActionType from '../ActionType'
import * as Logger from 'loglevel';

const api =  ({dispatch, getState}) => next => async action => {
    if (action.type !== ActionType.API_FETCH) {
        return next(action);
    }
    Logger.debug(action.payload)

    dispatch(!!action.payload.additionalParams ? action.payload.begin(action.payload.additionalParams) : action.payload.begin());

    await fetch(action.payload.url, {
            method: action.payload.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${action.payload.token}`,
            },
            body: (!!action.payload.body) ? JSON.stringify(action.payload.body) : undefined
        })
        .then(async response => {
            if (response.ok) {
                return response.json();
            } else {
                //decode body readable stream
                var errorDetails = await response.json();
                Logger.debug(errorDetails);
                response["details"] = errorDetails;
                throw response;
            }
        })
        .then(data => 
            {
                dispatch(!!action.payload.additionalParams ? action.payload.success(data, action.payload.additionalParams) : action.payload.success(data))
        })
        .catch(error => {
            dispatch(action.payload.error(error));
        });
}

export default api;