import React from 'react';
import { DateRangePicker } from 'react-date-range';

import { OverlayTrigger, Popover, Button, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import Utilities from 'hub-utilities'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'styles/libraries/HubDateRangePicker.scss'

const DATE_RANGE_TRANSITION_TYPE = Object.freeze({
    FORWARD: "FORWARD",
    BACKWARD: "BACKWARD"
})

class HubDateRangePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bShowDatePicker: false
        }
        this.datePickerRef = React.createRef()
        this.datePickerToggleRef = React.createRef()
    }

    async componentWillMount() {
        document.addEventListener('mouseup', this.handleClickDatePicker);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickDatePicker);
    }


    handleClickDatePicker = (event) => {
        if (this.datePickerRef && this.datePickerRef.current
            && !this.datePickerRef.current.contains(event.target)
            && !this.datePickerToggleRef.current.contains(event.target) // to prevent conflict with date picker toggle onclick
        ) {
            this.setShowDatePicker(false, event); // outside date picker
        }
    }

    setShowDatePicker = (bShow, e) => {
        this.setState({ bShowDatePicker: bShow })
    }

    renderDateRangePicker() {
        const selectionRange = {
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            key: 'selection',
        }
        return (
            <div ref={this.datePickerRef} >
                <DateRangePicker
                    ref={this.datePickerRef}
                    ranges={[selectionRange]}
                    color="#f69b1d"
                    rangeColors={["#f69b1d"]}
                    onChange={(ranges) => this.props.queryDateRange(ranges.selection.startDate, ranges.selection.endDate)}
                    months={2}
                    direction="horizontal"
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                />
            </div>
        )
    }

    isLastDay(endDate) {
        var date = new Date(endDate.getTime()),
            month = date.getMonth();
    
         date.setDate(date.getDate() + 1);
        return date.getMonth() !== month;
    }

    IsMonthSelected(startDate, endDate) {
        const isStartDay = new Date(startDate).getDate() === 1;
        const isLastDay = this.isLastDay(endDate);
        return isStartDay && isLastDay;
    }

    IsYearSelected(startDate, endDate) {
        const _startDate = new Date(startDate);
        const _endDate = new Date(endDate);
        return _startDate.getDate() === _endDate.getDate() && _startDate.getMonth() === _endDate.getMonth();
    }

    translateDateRange = (translationType) => {
        var startDate = new Date(this.props.startDate);
        var endDate = new Date(this.props.endDate);

        const isYearSelected = this.IsYearSelected(this.props.startDate, this.props.endDate);
        const isMonthSelected = this.IsMonthSelected(this.props.startDate, this.props.endDate);
        var direction = 0;
        switch (translationType) {
            case DATE_RANGE_TRANSITION_TYPE.FORWARD:
                direction = 1;
                break;
            case DATE_RANGE_TRANSITION_TYPE.BACKWARD:
                direction = -1;
                break;
        }
        if (isYearSelected) {
            startDate.setFullYear(startDate.getFullYear() + direction);
            endDate.setFullYear(endDate.getFullYear() + direction);
            this.props.queryDateRange(startDate, endDate);
        } else if (isMonthSelected) {
            // set month is BUGGED
            startDate = new Date(startDate.getFullYear(), startDate.getMonth() + direction, 1);
            endDate = new Date(endDate.getFullYear(), endDate.getMonth() + direction + 1, 0); // 0tg date = set the the end of prev month
            this.props.queryDateRange(startDate, endDate);
        } else {
            const diffTime = Math.abs(this.props.endDate - this.props.startDate);
            var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
            diffDays = direction * diffDays;
            this.props.queryDateRange(
                Utilities.addDays(startDate, diffDays), 
                Utilities.addDays(endDate, diffDays)
            );
        }
    }

    renderDateRangePickerPopup() {
        const placement = 'bottom-start';
        return (
            <OverlayTrigger
                show={this.state.bShowDatePicker}
                key={placement}
                placement={placement}
                overlay={
                    <Popover id={`MetricsDateRangePickerPopover`}>
                        <Popover.Content className="MetricsDateRangePickerPopoverBody">
                            {this.renderDateRangePicker()}
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button
                    variant="dark"
                    className="hubButton PopoverButton"
                    ref={this.datePickerToggleRef}
                    onMouseUp={(e) => this.setShowDatePicker(!this.state.bShowDatePicker, e)}>
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip" {...this.props}>
                            Edit Date Range
                    </Tooltip>}
                    >
                        <div>
                            <FontAwesomeIcon icon={faCalendarAlt} />&nbsp;
                    {Utilities.formatObjectDate(this.props.startDate, "MMM dd, yyyy", true, false, false)}&nbsp;
                    - &nbsp;
                    {Utilities.formatObjectDate(this.props.endDate, "MMM dd, yyyy", true, false, false)}
                        </div>
                    </OverlayTrigger>
                </Button>
            </OverlayTrigger>
        )
    }

    render() {
        return (
            <div className="MetricsDateRangePicker">
                <Button variant="dark" className="hubButton transitionForward" onClick={() => this.translateDateRange(DATE_RANGE_TRANSITION_TYPE.BACKWARD)}>
                    <FontAwesomeIcon icon={faCaretLeft} />
                </Button>
                {this.renderDateRangePickerPopup()}
                <Button variant="dark" className="hubButton transitionBackward" onClick={() => this.translateDateRange(DATE_RANGE_TRANSITION_TYPE.FORWARD)}>
                    <FontAwesomeIcon icon={faCaretRight} />
                </Button>
            </div>
        )
    }
}
export default HubDateRangePicker;