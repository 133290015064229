import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import * as ProdCenterBoardActions from 'store/actions/HubConfigActions';

import { faCity } from '@fortawesome/free-solid-svg-icons';

import * as HubConstant from 'util/HubConstant';
import { UtilConstant } from 'hub-utilities'
import authService from 'components/api-authorization/AuthorizeService';

import 'styles/tables/ProductionCenter.scss'

class ProductionCenter extends React.Component {
    constructor(props) {
        super(props);
        this.headerConfig = {
            id: -1,
            ProductionCentre: { val: "", canSort: true },
            Description: { val: "", canSort: true },
            ...!this.props.isForm && { Website: { val: "", canSort: true } },
            rowActions: !props.isForm ?
                [
                    { action: TableRowAction.EDIT_INFO_BY_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                    { action: TableRowAction.RSS_FEED, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN }
                ]
                :
                [
                    { action: TableRowAction.CHECKBOX, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
                ]
        };
    }

    async componentWillMount() {
        if (!this.props.isForm) {
            this.props.setActivePageName({ singular: "Production Centre", plural: "Production Centres", icon: faCity });
            if (this.props.nodes == null) {
                this.props.requestNodes();
                Logger.debug(this.props.nodes);
            }
            //objectList is the redux state
            this.setState({ fetchedData: this.fetchData(this.props.nodes) });
        } else {
            this.setState({ fetchedData: this.fetchData(this.props.nodeFormData) });
        }
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //Don't requestNodes here, or else datatable would fetch twice in the beginning, and 
        //mistreat it as new data added
        return new Promise((resolve, reject) => {
            Logger.debug(nextPropsFromRedux)
            if (!this.props.isForm && nextPropsFromRedux.nodes !== this.props.nodes) {
                this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.nodes) }, () => resolve(true));
            } else if (this.props.changeNotifier !== nextPropsFromRedux.changeNotifier) {
                Logger.debug(nextPropsFromRedux.nodeFormData)
                this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.nodeFormData) }, () => resolve(true));
            } else {
                resolve(true);
            }
        })
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            Logger.debug(objects);
            return objects.map((o) => {
                Logger.debug("o", JSON.parse(JSON.stringify(o)))
                return (
                    {
                        //Lower case keys won't get posted to table
                        id: o.id,
                        name: o.name,
                        ...this.props.isForm && { isChecked: o.isChecked },
                        ProductionCentre: { val: o.name, canSort: true },
                        Description: { val: (!!o.description && o.description.length > 128) ? `${o.description.substr(0, 128)} ...` : o.description, canSort: true },
                        ...!this.props.isForm && { Website: { val: (<a className="text-primary" href={o.webUrl} target="window">{o.webUrl}</a>), canSort: true } },
                        rowActions: !this.props.isForm ?
                            [
                                {
                                    action: TableRowAction.EDIT_INFO_BY_FORM,
                                    form: HubConstant.HUB_UPDATE_NODE_FORM,
                                    data: {
                                        id: o.id,
                                        name: o.name,
                                        description: o.description
                                    },
                                    minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN,
                                    tooltipText: "Edit Production Center"
                                },
                                { action: TableRowAction.RSS_FEED, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN }
                            ]
                            :
                            [
                                { action: TableRowAction.CHECKBOX, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
                            ]
                    }
                );
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        Logger.debug("FETCHED DATA: ", this.state.fetchedData);
        Logger.debug("DATAACTION: ", this.props.action);
        Logger.debug(this.props.isForm);
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable
                        isPageless={this.props.isForm}
                        canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={
                            !this.props.isForm ?
                                [
                                    { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                                    { action: TableToolType.PAGE_DISPLAY },
                                    { action: TableToolType.PAGINATION },
                                    { action: TableToolType.NEWS_FEED }
                                ]
                                :
                                []
                        }
                        dataAction={this.props.action}
                        onChangeSetData={this.props.onChangeSetNodeData}
                        changeNotifier={this.props.changeNotifier}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="ProductionCentre">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData && this.state.fetchedData != null) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default withRouter(connect(
    ProdCenterBoardActions.mapStateToProps,
    dispatch => bindActionCreators(ProdCenterBoardActions.mapDispatchToProps, dispatch)
)(ProductionCenter));