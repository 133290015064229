import * as Logger from 'loglevel';
import authService from 'components/api-authorization/AuthorizeService';

export class FolderApi {

    createNewFolder = async (newFolderData) => {
        
        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`/api/v2/Folder/${newFolderData.parentId}/folder`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newFolderData),
        });


        if (response.ok) {
            var resp = await response.json()      
            return resp;
        }
        else {
            Logger.error('creatinng folder failed, response=', response);
            return null;
        }
    }

    deleteFolder = async (folderId) => {

        Logger.debug(`deleting folder ${folderId}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/v2/Folder/${folderId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok)
        {
            Logger.error('deleting folder failed, response=', response);
        }
    }

    updateFolder = async (request) => {

        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`/api/v2/folder/${request.id}`,{
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });

        if (response.ok) {
            return true;
        } else {
            Logger.error('updating folder details failed, response=', response.json());
            return false;
        }
    }

    getAvailableUsers = async () => {
        let token = await authService.getAccessTokenAsync();
        let url = `api/v2/foldersecurity/principals`; 

        let response = await fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
            var resp = await response.json();
            return resp.securityDefs;
        } else {
            Logger.error('retrieving available users failed, response=', response);
            return [];
        }
    }


    getFolderPermissions = async (folderId) => {

        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`api/v2/foldersecurity/${folderId}`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        });


        if (response.ok) {
            var resp = await response.json()
            return resp.securityDefs;
        }
        else {
            Logger.error('retrieving user permissions failed, response=', response);
            return null;
        }
    }

    savePermission = async (newUserPermission) => {

        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`api/v2/foldersecurity`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newUserPermission),
        });

        if (response.ok) {
            var resp = await response.json()
            return resp;
        }
        else {
            Logger.error('creating user permission failed, response=', response);
            return null;
        }

    }

    updatePermission = async (newUserPermission) => {
        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`api/v2/foldersecurity`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newUserPermission),
        });

        if (response.ok) {
            var resp = await response.json()
            return resp;
        }
        else {
            Logger.error('updating user permission failed, response=', response);
            return null;
        }
    }

    deletePermission = async (folderId, principalId) => { 

        Logger.debug(`deleting folder ${folderId}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/v2/foldersecurity/${folderId}/principal/${principalId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            Logger.error('deleting permission failed, response=', response);
            return null
        } else {
            return response.json();
        }
    }

    getUserFolderPermission = async (folderId, userId) => {
        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`/api/v2/foldersecurity/${folderId}/principal/${userId}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
        });

        if (response.ok) {
            var resp = await response.json()
            return resp;
        } else {
            Logger.error('updating folder details failed, response=', response.json());
            return [];
        }
    }
}


const folderApi = new FolderApi();
export default folderApi;