import React from 'react';
import {Bar} from 'react-chartjs-2'
import * as moment from 'moment'
import { HUB_METRICS_REPORT_TYPE } from 'util/HubConstant';

class MetricsChart extends React.Component {
    constructor(props) {
        super(props);
    }

    isToday(date) {
        return moment(date).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")
    }

    render() {
        let data ={ 
            datasets:[
                {
                    label: `${this.props.metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION ? "Downloaded" : "Added"} With Media`,
                    data : this.props.metrics.map(metric => 
                        this.props.metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION ? metric.metrics.downloadsWithMedia : metric.metrics.addedWithMedia),
                    backgroundColor: '#f69b1d'
                },
                {
                    label: `${this.props.metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION ? "Downloaded" : "Added"} Without Media`,
                    data: this.props.metrics.map(metric => 
                        this.props.metricsReportType === HUB_METRICS_REPORT_TYPE.CONTENT_CONSUMPTION ? 
                            metric.metrics.downloads - metric.metrics.downloadsWithMedia :
                            metric.metrics.added - metric.metrics.addedWithMedia),
                    backgroundColor: 'rgba(52, 58, 64, 0.9)'
                }
            ],
            labels: this.props.metrics.map(metric => // BUG 673: Chat for single day should be centred
                this.props.metrics.length > 1 ? new Date(metric.date) : moment(new Date(metric.date)).format("ddd MMM DD, YYYY"))
          }

        let that = this
        
        return (
            <div className="MetricsChart">
                <Bar 
                    data={data}
                    options={{
                        scales: {
                            xAxes: [{
                                stacked: true,
                                maxBarThickness: 100, //set bar width,
                                ...!!this.props.metrics && this.props.metrics.length > 1 && {
                                    ticks: {
                                        stepSize: 1,
                                        major: {
                                            enabled: true,
                                            fontColor: '#f69b1d',
                                            fontStyle: 'bold',
                                        },
                                        callback: function(value, index, values) {
                                            if (that.isToday(value) && typeof values[index] !== 'undefined') {
                                                values[index].major = true
                                            } else {
                                                values[index].major = false
                                            }
                                            return value
                                        }
                                    },
                                    type: 'time',
                                    time: {
                                    unit: 'day',
                                    displayFormats: {
                                        day: 'ddd MMM DD, YYYY',
                                        month: 'ddd MMM DD, YYYY'            
                                    },
                                    tooltipFormat: 'ddd MMM DD, YYYY'
                                }},
                                offset: true // BUG 671: Bars at edge of graph are cut off
                            }], // BUG 673: Chat for single day should be centred
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    suggestedMax: 5,
                                    beginAtZero: true,
                                    precision: 0 // BUG 672: Vertical scale cleanup
                                }
                            }]
                        },
                        legend: {
                            position: "top",
                            align: "end"
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        
                    }}
                />
            </div>
        );
    }
}

export default MetricsChart;