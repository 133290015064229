// config actions
export const FETCH_HUB_USERS_BEGIN = 'FETCH_HUB_USERS_BEGIN';
export const FETCH_HUB_USERS_SUCCESS = 'FETCH_HUB_USERS_SUCCESS';
export const FETCH_HUB_USERS_FAILURE = 'FETCH_HUB_USERS_FAILURE';

export const FETCH_HUB_NODES_BEGIN = 'FETCH_NODES_BEGIN';
export const FETCH_HUB_NODES_SUCCESS = 'FETCH_NODES_SUCCESS';
export const FETCH_HUB_NODES_FAILURE = 'FETCH_NODES_FAILURE';

export const UPDATE_HUB_NODES_BEGIN = 'UPDATE_HUB_NODES_BEGIN';
export const UPDATE_HUB_NODES_SUCCESS = 'UPDATE_HUB_NODES_SUCCESS';
export const UPDATE_HUB_NODES_FAILURE = 'UPDATE_HUB_NODES_FAILURE';

export const SET_HUB_NODE_FILTER_BEGIN = 'SET_HUB_NODE_FILTER_BEGIN';
export const SET_HUB_NODE_FILTER_SUCCESS = 'SET_HUB_NODE_FILTER_SUCCESS';
export const SET_HUB_NODE_FILTER_FAILURE = 'SET_HUB_NODE_FILTER_FAILURE';

export const GET_HUB_NODE_GROUP_BEGIN = 'GET_HUB_NODE_GROUP_BEGIN';
export const GET_HUB_NODE_GROUP_SUCCESS = 'GET_HUB_NODE_GROUP_SUCCESS';
export const GET_HUB_NODE_GROUP_FAILURE = 'GET_HUB_NODE_GROUP_FAILURE';

export const ADD_HUB_NODE_GROUP_BEGIN = 'ADD_HUB_NODE_GROUP_BEGIN';
export const ADD_HUB_NODE_GROUP_SUCCESS = 'ADD_HUB_NODE_GROUP_SUCCESS';
export const ADD_HUB_NODE_GROUP_FAILURE = 'ADD_HUB_NODE_GROUP_FAILURE';

export const SET_HUB_NODE_GROUP_BEGIN = 'SET_HUB_NODE_GROUP_BEGIN';
export const SET_HUB_NODE_GROUP_SUCCESS = 'SET_HUB_NODE_GROUP_SUCCESS';
export const SET_HUB_NODE_GROUP_FAILURE = 'SET_HUB_NODE_GROUP_FAILURE';

export const DELETE_HUB_NODE_GROUP_BEGIN = 'DELETE_HUB_NODE_GROUP_BEGIN';
export const DELETE_HUB_NODE_GROUP_SUCCESS = 'DELETE_HUB_NODE_GROUP_SUCCESS';
export const DELETE_HUB_NODE_GROUP_FAILURE = 'DELETE_HUB_NODE_GROUP_FAILURE';

export const GET_HUB_NODE_GROUP_NODES_BEGIN = 'GET_HUB_NODE_GROUP_NODES_BEGIN';
export const GET_HUB_NODE_GROUP_NODES_SUCCESS = 'GET_HUB_NODE_GROUP_NODES_SUCCESS';
export const GET_HUB_NODE_GROUP_NODES_FAILURE = 'GET_HUB_NODE_GROUP_NODES_FAILURE';

export const ADD_HUB_NODE_GROUP_NODES_BEGIN = 'ADD_HUB_NODE_GROUP_NODES_BEGIN';
export const ADD_HUB_NODE_GROUP_NODES_SUCCESS = 'ADD_HUB_NODE_GROUP_NODES_SUCCESS';
export const ADD_HUB_NODE_GROUP_NODES_FAILURE = 'ADD_HUB_NODE_GROUP_NODES_FAILURE';

export const DELETE_HUB_NODE_GROUP_NODES_BEGIN = 'DELETE_HUB_NODE_GROUP_NODES_BEGIN';
export const DELETE_HUB_NODE_GROUP_NODES_SUCCESS = 'DELETE_HUB_NODE_GROUP_NODES_SUCCESS';
export const DELETE_HUB_NODE_GROUP_NODES_FAILURE = 'DELETE_HUB_NODE_GROUP_NODES_FAILURE';

export const EDIT_NODE_GROUP = "EDIT_NODE_GROUP";

export const FETCH_LOGO_BEGIN = 'FETCH_LOGO_BEGIN';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';

export const UPDATE_LOGO_BEGIN = 'UPDATE_LOGO_BEGIN';
export const UPDATE_LOGO_SUCCESS = 'UPDATE_LOGO_SUCCESS';
export const UPDATE_LOGO_FAILURE = 'UPDATE_LOGO_FAILURE';

export const FETCH_SETTINGS_BEGIN = 'FETCH_SETTINGS_BEGIN';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

export const UPDATE_SETTINGS_BEGIN = 'UPDATE_SETTINGS_BEGIN';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const SET_HUB_NAME_BEGIN = 'SET_HUB_NAME_BEGIN';
export const SET_HUB_NAME_SUCCESS = 'SET_HUB_NAME_SUCCESS';
export const SET_HUB_NAME_FAILURE = 'SET_HUB_NAME_FAILURE';

export const GET_HUB_INFO_BEGIN = 'GET_HUB_INFO_BEGIN';
export const GET_HUB_INFO_SUCCESS = 'GET_HUB_INFO_SUCCESS';
export const GET_HUB_INFO_FAILURE = 'GET_HUB_INFO_FAILURE';

export const GET_IDP_INFO_BEGIN = 'GET_IDP_INFO_BEGIN';
export const GET_IDP_INFO_SUCCESS = 'GET_IDP_INFO_SUCCESS';
export const GET_IDP_INFO_FAILURE = 'GET_IDP_INFO_FAILURE';

export const GET_HUB_ENDPOINTS_BEGIN = 'GET_HUB_ENDPOINTS_BEGIN'
export const GET_HUB_ENDPOINTS_SUCCESS = 'GET_HUB_ENDPOINTS_SUCCESS'
export const GET_HUB_ENDPOINTS_FAILURE = 'GET_HUB_ENDPOINTS_FAILURE'

//Geo:
export const FETCH_GEO_BEGIN = 'FETCH_GEO_BEGIN';
export const FETCH_GEO_SUCCESS = 'FETCH_GEO_SUCCESS';
export const FETCH_GEO_FAILURE = 'FETCH_GEO_FAILURE';

export const ADD_GEO_BEGIN = 'ADD_GEO_BEGIN';
export const ADD_GEO_SUCCESS = 'ADD_GEO_SUCCESS';
export const ADD_GEO_FAILURE = 'ADD_GEO_FAILURE';

export const UPDATE_GEO_BEGIN = 'UPDATE_GEO_BEGIN';
export const UPDATE_GEO_SUCCESS = 'UPDATE_GEO_SUCCESS';
export const UPDATE_GEO_FAILURE = 'UPDATE_GEO_FAILURE';

export const DELETE_GEO_BEGIN = 'DELETE_GEO_BEGIN';
export const DELETE_GEO_SUCCESS = 'DELETE_GEO_SUCCESS';
export const DELETE_GEO_FAILURE = 'DELETE_GEO_FAILURE';

export const EDIT_SETTINGS_INPUT = 'EDIT_SETTINGS_INPUT';
export const EDIT_GEO_INPUT = 'EDIT_GEO_INPUT';

//Metadata:
export const FETCH_METADATA_BEGIN = 'FETCH_METADATA_BEGIN';
export const FETCH_METADATA_SUCCESS = 'FETCH_METADATA_SUCCESS';
export const FETCH_METADATA_FAILURE = 'FETCH_METADATA_FAILURE';

export const ADD_METADATA_BEGIN = 'ADD_METADATA_BEGIN';
export const ADD_METADATA_SUCCESS = 'ADD_METADATA_SUCCESS';
export const ADD_METADATA_FAILURE = 'ADD_METADATA_FAILURE';

export const UPDATE_METADATA_BEGIN = 'UPDATE_METADATA_BEGIN';
export const UPDATE_METADATA_SUCCESS = 'UPDATE_METADATA_SUCCESS';
export const UPDATE_METADATA_FAILURE = 'UPDATE_METADATA_FAILURE';

export const DELETE_METADATA_BEGIN = 'DELETE_METADATA_BEGIN';
export const DELETE_METADATA_SUCCESS = 'DELETE_METADATA_SUCCESS';
export const DELETE_METADATA_FAILURE = 'DELETE_METADATA_FAILURE';

export const ADD_METADATA_VOCAB_BEGIN = 'ADD_METADATA_VOCAB_BEGIN';
export const ADD_METADATA_VOCAB_SUCCESS = 'ADD_METADATA_VOCAB_SUCCESS';
export const ADD_METADATA_VOCAB_FAILURE = 'ADD_METADATA_VOCAB_FAILURE';

export const UPDATE_METADATA_VOCAB_BEGIN = 'UPDATE_METADATA_VOCAB_BEGIN';
export const UPDATE_METADATA_VOCAB_SUCCESS = 'UPDATE_METADATA_VOCAB_SUCCESS';
export const UPDATE_METADATA_VOCAB_FAILURE = 'UPDATE_METADATA_VOCAB_FAILURE';

export const DELETE_METADATA_VOCAB_BEGIN = 'DELETE_METADATA_VOCAB_BEGIN';
export const DELETE_METADATA_VOCAB_SUCCESS = 'DELETE_METADATA_VOCAB_SUCCESS';
export const DELETE_METADATA_VOCAB_FAILURE = 'DELETE_METADATA_VOCAB_FAILURE';

export const FETCH_METADATA_TO_NEWSITEM_BEGIN = 'FETCH_METADATA_TO_NEWSITEM_BEGIN';
export const FETCH_METADATA_TO_NEWSITEM_SUCCESS = 'FETCH_METADATA_TO_NEWSITEM_SUCCESS';
export const FETCH_METADATA_TO_NEWSITEM_FAILURE = 'FETCH_METADATA_TO_NEWSITEM_FAILURE';

export const ADD_METADATA_TO_NEWSITEM_BEGIN = 'ADD_METADATA_TO_NEWSITEM_BEGIN';
export const ADD_METADATA_TO_NEWSITEM_SUCCESS = 'ADD_METADATA_TO_NEWSITEM_SUCCESS';
export const ADD_METADATA_TO_NEWSITEM_FAILURE = 'ADD_METADATA_TO_NEWSITEM_FAILURE';

export const EDIT_METADATA = 'EDIT_METADATA';
export const REPORT_ERROR = 'REPORT_ERROR';

export const API_FETCH = "API_FETCH";


// node content actions
export const FETCH_NODE_CONTENTS_BEGIN = "FETCH_NODE_CONTENTS_BEGIN";
export const FETCH_NODE_CONTENTS_SUCCESS = "FETCH_NODE_CONTENTS_SUCCESS";
export const FETCH_NODE_CONTENTS_FAILURE = "FETCH_NODE_CONTENTS_FAILURE";

// scn actions
export const SCN_ADD_HUB_OBJECT = "SCN_ADD_HUB_OBJECT";
export const SCN_DELETE_HUB_OBJECT = "SCN_DELETE_HUB_OBJECT";
export const SCN_DELETE_HUB_OBJECTS = "SCN_DELETE_HUB_OBJECTS";
export const SCN_UPDATE_USER = "SCN_UPDATE_USER";

//ft actions
export const FT_UPDATE_PERMISSION = "FT_UPDATE_PERMISSION";
export const FT_DELETE_PERMISSION = "FT_DELETE_PERMISSION";
export const FT_ADD_PERMISSION = "FT_ADD_PERMISSION";

export const FT_ADD_FOLDER = "FT_ADD_FOLDER";
export const FT_DELETE_FOLDER = "FT_DELETE_FOLDER";
export const FT_UPDATE_FOLDER = "FT_UPDATE_FOLDER";

//Search actions
export const SEARCH_TEXT_UPDATED = "SEARCH_TEXT_UPDATED";

//to be deprecated
export const FETCH_FEEDS_BEGIN = 'FETCH_FEEDS_BEGIN';
export const FETCH_FEEDS_SUCCESS = 'FETCH_FEEDS_SUCCESS';
export const FETCH_FEEDS_FAILURE = 'FETCH_FEEDS_FAILURE';
export const FETCH_FEED_STORIES_BEGIN = 'FETCH_FEED_STORIES_BEGIN';
export const FETCH_FEED_STORIES_SUCCESS = 'FETCH_FEED_STORIES_SUCCESS';
export const FETCH_FEED_STORIES_FAILURE = 'FETCH_FEED_STORIES_FAILURE';
export const OPEN_NEWS_FEED = 'OPEN_NEWS_FEED';

// newsitem actions
export const GET_NEWSITEM_SUBOBJECT_BEGINS = 'GET_NEWSITEM_SUBOBJECT_BEGINS';
export const GET_NEWSITEM_SUBOBJECT_SUCCESS = 'GET_NEWSITEM_SUBOBJECT_SUCCESS';
export const GET_NEWSITEM_SUBOBJECT_FAILURE = 'GET_NEWSITEM_SUBOBJECT_FAILURE';

export const SET_UPDATE_TRIGGER = 'SET_UPDATE_TRIGGER';

// metrics actions
export const FETCH_METRICS_BEGIN = 'FETCH_METRICS_BEGIN';
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE';

// FETCH NEWSITEM BY FOLDER
export const FETCH_NEWSITEM_FOLDER_BEGIN = 'FETCH_NEWSITEM_FOLDER_BEGIN';
export const FETCH_NEWSITEM_FOLDER_SUCCESS = 'FETCH_NEWSITEM_FOLDER_SUCCESS';
export const FETCH_NEWSITEM_FOLDER_FAILURE = 'FETCH_NEWSITEM_FOLDER_FAILURE';


// Working Folder
export const SET_WORKING_FOLDER = 'SET_WORKING_FOLDER';


// GET ROOT FOLDERS
export const FETCH_ROOT_FOLDERS_BEGIN = 'FETCH_ROOT_FOLDERS_BEGIN'
export const FETCH_ROOT_FOLDERS_SUCCESS = 'FETCH_ROOT_FOLDERS_SUCCESS'
export const FETCH_ROOT_FOLDERS_FAILURE = 'FETCH_ROOT_FOLDERS_FAILURE'

// GET FOLDER TREE 
export const GET_FOLDER_TREE_BEGIN = 'GET_FOLDER_TREE_BEGIN'
export const GET_FOLDER_TREE_SUCCESS = 'GET_FOLDER_TREE_SUCCESS'
export const GET_FOLDER_TREE_FAILURE = 'GET_FOLDER_TREE_FAILURE'


//delete newstiem in folder
export const DELETE_NEWSITEM_IN_FOLDER_BEGIN = 'DELETE_NEWSITEM_IN_FOLDER_BEGIN'
export const DELETE_NEWSITEM_IN_FOLDER_SUCCESS = 'DELETE_NEWSITEM_IN_FOLDER_SUCCESS'
export const DELETE_NEWSITEM_IN_FOLDER_FAILURE = 'DELETE_NEWSITEM_IN_FOLDER_FAILURE'


//move newsitem to other folder
export const MOVE_NEWSITEM_OTHER_FOLDER_SUCCESS = 'MOVE_NEWSITEM_OTHER_FOLDER_SUCCESS'
export const MOVE_NEWSITEM_OTHER_FOLDER_FAILURE = 'MOVE_NEWSITEM_OTHER_FOLDER_FAILURE'


//add story to folder
export const ADD_STORY_TO_FOLDER_SUCCESS = 'ADD_STORY_TO_FOLDER_SUCCESS'
export const ADD_STORY_TO_FOLDER_FAILURE = 'ADD_STORY_TO_FOLDER_FAILURE'


// Folder Security Groups
export const FETCH_FOLDER_SECURITY_GROUPS_BEGIN = 'FETCH_FOLDER_SECURITY_GROUPS_BEGIN';
export const FETCH_FOLDER_SECURITY_GROUPS_SUCCESS = 'FETCH_FOLDER_SECURITY_GROUPS_SUCCESS';
export const FETCH_FOLDER_SECURITY_GROUPS_FAILURE = 'FETCH_FOLDER_SECURITY_GROUPS_FAILURE';

export const ADD_FOLDER_SECURITY_GROUP_BEGIN = 'ADD_FOLDER_SECURITY_GROUP_BEGIN';
export const ADD_FOLDER_SECURITY_GROUP_SUCCESS = 'ADD_FOLDER_SECURITY_GROUP_SUCCESS';
export const ADD_FOLDER_SECURITY_GROUP_FAILURE = 'ADD_FOLDER_SECURITY_GROUP_FAILURE';

export const UPDATE_FOLDER_SECURITY_GROUP_BEGIN = 'UPDATE_FOLDER_SECURITY_GROUP_BEGIN';
export const UPDATE_FOLDER_SECURITY_GROUP_SUCCESS = 'UPDATE_FOLDER_SECURITY_GROUP_SUCCESS';
export const UPDATE_FOLDER_SECURITY_GROUP_FAILURE = 'UPDATE_FOLDER_SECURITY_GROUP_FAILURE';

export const DELETE_FOLDER_SECURITY_GROUP_BEGIN = 'DELETE_FOLDER_SECURITY_GROUP_BEGIN';
export const DELETE_FOLDER_SECURITY_GROUP_SUCCESS = 'DELETE_FOLDER_SECURITY_GROUP_SUCCESS';
export const DELETE_FOLDER_SECURITY_GROUP_FAILURE = 'DELETE_FOLDER_SECURITY_GROUP_FAILURE';

export const ADD_USER_TO_FOLDER_SECURITY_GROUP_BEGIN = 'ADD_USER_TO_FOLDER_SECURITY_GROUP_BEGIN';
export const ADD_USER_TO_FOLDER_SECURITY_GROUP_SUCCESS = 'ADD_USER_TO_FOLDER_SECURITY_GROUP_SUCCESS';
export const ADD_USER_TO_FOLDER_SECURITY_GROUP_FAILURE = 'ADD_USER_TO_FOLDER_SECURITY_GROUP_FAILURE';

export const REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_BEGIN = 'REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_BEGIN';
export const REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_SUCCESS = 'REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_SUCCESS';
export const REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_FAILURE = 'REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_FAILURE';

export const EDIT_FOLDER_SECURITY_GROUP = 'EDIT_FOLDER_SECURITY_GROUP';