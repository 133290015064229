import React from "react";
import { Form, Row, Col, Button } from 'react-bootstrap'
import { TextField } from '@material-ui/core';
import * as Logger from 'loglevel';
import ProductionCenter from 'components/post-authorization/main-content/admin/ProductionCenter'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import * as ProdCenterBoardActions from 'store/actions/HubConfigActions';
import { FilterSearchBar } from 'hub-dashboard-framework'
import * as DataHandler from 'components/post-authorization/libraries/DataHandler';

import 'styles/widgets/ProductionGroup.scss'


class ProductionGroupForm extends React.Component {
    constructor(props) {
        const { payload: nodeGroup } = props;
        super(props);
        this.form = React.createRef();
        Logger.debug(nodeGroup)
        this.state = !!nodeGroup
            ? {
                groupName: nodeGroup.Group.val,
                description: nodeGroup.Description.val,
                members: nodeGroup.Members.formVal,
                nodes: this.addAttToNodeData(nodeGroup.Members.formVal, this.props.nodes),
                filterSearchText: "",

                formError: undefined,
                isStatusEnabled: false,
                changeNotifier: false,
                isFocusingMembersUI: false
            }
            : {
                groupName: undefined,
                description: undefined,
                members: undefined,
                nodes: undefined,
                filterSearchText: "",

                formError: undefined,
                isStatusEnabled: false,
                changeNotifier: false,
                isFocusingMembersUI: false
            };
        this.nodeGroupId = !!nodeGroup ? nodeGroup.id : -1;
        Logger.debug(this.state.nodes)
    }

    setIsFocusingMembersUI = (isFocusing) => {
        this.setState({ isFocusingMembersUI: isFocusing })
    }

    componentWillMount() {
        if (this.nodeGroupId === -1) {
            this.initializeNodeDataIfNull();
        }
    }

    //Filter text:
    setFilterSearchText = (text) => {
        this.setState({
            filterSearchText: text
        }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
    }

    initializeNodeDataIfNull() {
        var nodesWithChecked = this.props.nodes;
        nodesWithChecked.forEach(node => node["isChecked"] = false);
        this.setState({
            nodes: nodesWithChecked,
            members: this.props.nodes
        })
    }

    addAttToNodeData(members, nodeData) {
        var newNodeData = nodeData;
        newNodeData.forEach(node => {
            node["isChecked"] = members.some(member => member.id === node.id);
        });
        return newNodeData;
    }

    onChangeSetNodeData = (nodeId) => {
        var newNodeData = this.state.nodes;
        newNodeData.forEach(node => {
            if (node.id === nodeId)
                node["isChecked"] = !node["isChecked"];
        });
        Logger.debug(newNodeData)
        this.setState({ nodes: newNodeData, changeNotifier: !this.state.changeNotifier })
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    validateForm = () => (this.form.current.checkValidity());

    submitHandler = (event) => {
        Logger.debug('Saving nodeGroup, event=', event);
        event.preventDefault();
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }
        if (this.validateForm()) {
            this.saveNodeGroup();
        }
    };

    createNodeGroup = async () => {
        var membersToAdd = this.state.nodes.filter(node => node.isChecked);
        var request = {
            groupName: this.state.groupName,
            description: this.state.description
        }

        await this.props.createNodeGroup(request, membersToAdd);
        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }

    updateNodeGroup = async () => {
        var oldMembers = this.state.members;
        var newMembers = this.state.nodes.filter(node => node.isChecked);
        var membersToAdd = newMembers.filter(newNode => !oldMembers.some(oldNode => oldNode.id === newNode.id));
        var membersToDelete = oldMembers.filter(oldNode => !newMembers.some(newNode => oldNode.id === newNode.id));
        var request = {
            groupName: this.state.groupName,
            description: this.state.description
        }

        Logger.debug(request);
        Logger.debug("oldMembers: ", oldMembers)
        Logger.debug("newMembers: ", newMembers);
        Logger.debug("membersToAdd are: ", membersToAdd);
        Logger.debug("membersToDelete are: ", membersToDelete);

        await this.props.updateNodeGroups(this.nodeGroupId, request, membersToAdd, membersToDelete);
        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }

    saveNodeGroup = async () => {
        const { payload: nodeGroup } = this.props;
        if (!!nodeGroup) {
            await this.updateNodeGroup();
        }
        else {
            await this.createNodeGroup();
        }
    }

    renderStatus() {
        if (!!this.props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">Error {this.props.error.status}: {this.props.error.statusText}</p></Col></Row>
        } else if (!!this.props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{this.props.success}</p></Col></Row>
        }
    }

    render() {
        Logger.debug(this.state.nodes)
        const { payload: nodeGroup } = this.props;
        let isUpdateNodeGroup = !!nodeGroup;
        return (
            <div className="HubUserForm HubNodeGroupForm">
                <Form ref={this.form} className="needs-validation burli-nodeGroup-form mt-3" onSubmit={this.submitHandler} >
                    <Row>
                        <Col size="4" className="HubNameRow"> <TextField
                            group
                            value={this.state.groupName}
                            name="groupName"
                            onChange={this.onChange}
                            type="text"
                            id="userformPassword1"
                            label="GROUP NAME"
                            required
                        >
                            <div className="invalid-feedback">Please enter a password that is at least 6 chatacters long.</div>
                        </TextField> </Col>
                        <Col size="8"><TextField
                            group
                            value={this.state.description}
                            name="description"
                            onChange={this.onChange}
                            type="text"
                            id="userformPassword2"
                            label="DESCRIPTION"
                            required
                        >
                            <div className="invalid-feedback">This field cannot be empty!</div>
                        </TextField> </Col>
                    </Row>
                    <Row className="TitleRow">
                        <Col size="4">
                            <h5 className={this.state.isFocusingMembersUI ? "focused" : ""}>MEMBERS</h5>
                        </Col>
                        <Col size="4">
                        </Col>
                        <Col size="4">
                            <FilterSearchBar
                                setIsFocusing={this.setIsFocusingMembersUI}
                                searchText={this.state.filterSearchText}
                                setSearchText={this.setFilterSearchText}>
                            </FilterSearchBar>
                        </Col>
                    </Row>
                    <div
                        onMouseEnter={() => this.setState({ isFocusingMembersUI: true })}
                        onMouseLeave={() => this.setState({ isFocusingMembersUI: false })}>
                        <ProductionCenter
                            changeNotifier={this.state.changeNotifier}
                            isForm={true}
                            nodeFormData={DataHandler.filterAvailableFilterObjs(this.state.nodes, this.state.filterSearchText, null)}
                            onChangeSetNodeData={this.onChangeSetNodeData}>
                        </ProductionCenter>
                    </div>
                    {this.state.isStatusEnabled ? this.renderStatus() : <></>}
                    <Row className="SelectionStatus">
                        <Col size="4" className="statusText">
                            {this.state.nodes.filter(node => node.isChecked).length} of {this.state.nodes.length} Nodes included
                            </Col>
                        <Col size="8">
                            <Button color="light" type="submit" size="sm" className="float-right mr-0">{isUpdateNodeGroup ? "Save and Close" : "Create and Close"}</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

const mapNodeAndNodeGroupStatesToProps = (state) => {
    return {
        hubNodeGroups: state.hubObjectContent.hubNodeGroups,
        nodes: state.hubConfig.nodeConfig.nodes,
        action: state.hubObjectContent.action,
        success: state.hubObjectContent.success,
        error: state.hubObjectContent.error
    }
}

export default withRouter(connect(
    mapNodeAndNodeGroupStatesToProps,
    dispatch => bindActionCreators({ ...ProdCenterBoardActions.mapDispatchToProps, ...mapDispatchToHubObjectBoardProps }, dispatch),
)(ProductionGroupForm));