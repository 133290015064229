import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faFolder, faTasks, faSync, faPlus, faTrash, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { FolderForm } from "./FolderForm";
import { UtilConstant } from 'hub-utilities';
import authService from 'components/api-authorization/AuthorizeService';
import * as HubConstant from 'util/HubConstant';
import { DeleteFolderModal } from "./DeleteFolderModal";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export function FolderTreeItem(props) {
    const [node, setNode] = useState(props.node);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({});
    const [hasCreatePermission, setHasCreatePermission] = useState((props.node.folderPermissions & HubConstant.USER_FOLDER_PERMISSIONS.CREATE) === HubConstant.USER_FOLDER_PERMISSIONS.CREATE )
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalPayload, setModalPayload] = useState(null);
    const [modalTitle, setModalTitle] = useState("");
    const folderContextMenuRef = useRef(null);

    useEffect(() => {
        function syncFolderData() {
            setNode(props.node);
        }
        syncFolderData();
    }, [])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (folderContextMenuRef.current && !folderContextMenuRef.current.contains(e.target)) {
        setMenuOpen(false);
    }
    };
    
    document.addEventListener("click", handleClickOutside);
    
    return () => {
        document.removeEventListener("click", handleClickOutside);
    };
        }, [folderContextMenuRef]);
    
    useEffect(() => {
        if (props.openMenuNodeId !== node.id) {
            setMenuOpen(false);
        }
    }, [props.openMenuNodeId]);
    
    const handleContextMenu = (event) => {
        if (!props.displayMode) {
            props.onContextMenuOpen(event, node.id);
            setMenuOpen(true);
            setMenuPosition({ top: event.clientY, left: event.clientX });
        }
    };
    
    const handleRefreshClick = async () => {
        await props.refreshSubFolders(node);
        setMenuOpen(false);
    }
    
    const handlePropertiesClick = async () => {
        setModalTitle(`Edit Folder Properties`);
        setModalPayload(node);
        setShowModal(true);
        setMenuOpen(false);
    }
    
    const handleAddClick = async () => {
        setModalTitle(`Add Folder To '${node.name}'`);
        setModalPayload({ parentId: node.id });
        setShowModal(true);
        setMenuOpen(false);
    }
    
    const handleDeleteClick = async () => {
        setModalTitle(`Delete '${node.name}' Folder`);
        setShowDeleteModal(true);
    }
    
    const handleCloseModal = async () => {
        setModalTitle("");
        setModalPayload(null);
        setShowModal(false);
        setShowDeleteModal(false);
    }
    
    const onModalSubmitted = async (newFolderData) => {
        //we know if there is no existing folder id, that it must be a new folder.
        if (!!newFolderData.id) {
            var success = await props.updateFolder(newFolderData);
        } else {
            var success = await props.createNewFolder(newFolderData);
        }
        if (success) {
            handleCloseModal();
        }
        return success;
    }
    
    const onDeleteFolder = async () => {
        props.deleteFolder(node.id);
        handleCloseModal();
    }
    
    const handleFolderIconClick = async () => {
        if (!props.displayMode) {
            await props.onFolderIconClick(node);
        }
    }

    const renderFolderName = () => {
        const displayCreateBlockMsg = props.displayMode && hasCreatePermission !== undefined && !hasCreatePermission;
        if (displayCreateBlockMsg) {
            return (
                <OverlayTrigger
                    placement="right-start"
                    overlay={<Tooltip>You do not have permissions to move a story to this folder.</Tooltip>}
                >
                    <span
                        className={`folderGroupItem ${!!props.workingFolder && props.workingFolder.id === node.id ? 'workingFolder' : ''} ${props.selected.id === node.id ? 'selected' : ''}`} 
                    >
                        {node.name}
                    </span>
                </OverlayTrigger>
            );
        } else {
            return (
                <span
                    className={`folderGroupItem ${!!props.workingFolder && props.workingFolder.id === node.id ? 'workingFolder' : ''} ${props.selected.id === node.id ? 'selected' : ''}`}
                    onClick={() => props.onSelected(node)}
                    onContextMenu={handleContextMenu}
                    onMouseEnter={() => props.setActiveContextMenu(node.id)}
                >
                    {node.name}
                </span>
            );
        }
    };

    const isActive = props.activeContextMenu === node.id;
    
    return (
        <>
            {props.hasChildren ? (
                node.isOpen ? (
                    <FontAwesomeIcon icon={faFolderOpen} onClick={() => handleFolderIconClick()} />
                ) : (
                    <FontAwesomeIcon icon={faFolderPlus} onClick={() => handleFolderIconClick()} />
                )
            ) : (
                <FontAwesomeIcon icon={faFolder} onClick={() => handleFolderIconClick()} />
            )}
            {renderFolderName()}
            {isActive && menuOpen && (
                <div className="folderContextMenu"
                    ref={folderContextMenuRef}
                    style={{
                        position: "fixed",
                        top: `${menuPosition.top}px`,
                        left: `${menuPosition.left}px`,
                        zIndex: 9999
                    }}
                >
                    <ul>
                        <li onClick={() => handleRefreshClick()}><FontAwesomeIcon icon={faSync} /> Refresh</li>
                        {authService.getUserType() === UtilConstant.HUB_USER_TYPE.HUB_ADMIN && (<li onClick={() => handlePropertiesClick()}><FontAwesomeIcon icon={faTasks} /> Properties</li>)}
                        {authService.getUserType() === UtilConstant.HUB_USER_TYPE.HUB_ADMIN && (<li onClick={() => handleAddClick()}><FontAwesomeIcon icon={faPlus} /> Add Folder</li>)}
                        {authService.getUserType() === UtilConstant.HUB_USER_TYPE.HUB_ADMIN && !!node.parentId && (<li onClick={() => handleDeleteClick()}><FontAwesomeIcon icon={faTrash} /> Delete</li>)}
                    </ul>
                </div>
            )}
            {showModal && (
                <FolderForm payload={modalPayload} showModal={showModal} onHide={handleCloseModal} title={modalTitle} onSaveClicked={onModalSubmitted} />
            )}
            {showDeleteModal && (
                <DeleteFolderModal showModal={showDeleteModal} folderName={node.name} onHide={handleCloseModal} title={modalTitle} onDeleteFolder={onDeleteFolder} />
            )}
        </>
    )
}
