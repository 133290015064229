export default class PermissionManager {

    static chooseEffectivePermission(securityDefs, userId) {
        if (!securityDefs || securityDefs.length === 0) return 1;

        const userPermission = securityDefs.find(perm => perm.principalId === userId);
        if (userPermission) return userPermission.securityWord;

        const maxGroupPermission = securityDefs.reduce((max, perm) => perm.securityWord > max ? perm.securityWord : max, 1);
        return maxGroupPermission;
    }

    static storePermissions(securityDefs) {

        return securityDefs || [];
    }
}
