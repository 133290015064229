import * as Logger from 'loglevel';
import authService from 'components/api-authorization/AuthorizeService';
import * as ActionType from '../ActionType';
import hubApi from 'util/HubApi'
import { DataTableConstant } from 'hub-dashboard-framework'

const fetchHubUsersBegin = () => ({
    type: ActionType.FETCH_HUB_USERS_BEGIN
});

const fetchHubUsersSuccess = payload => ({
    type: ActionType.FETCH_HUB_USERS_SUCCESS,
    payload: payload
});

const fetchHubUsersFailure = (sts, errorMsg) => ({
    type: ActionType.FETCH_HUB_USERS_FAILURE,
    payload: { status: sts, error: errorMsg },
});

const fetchHubUsers = async (url, token, dispatch) => {
    dispatch(fetchHubUsersBegin());
    Logger.debug(`loading from ${url}`);
    Logger.debug('FETCH_CONTRIBUTER_BEGIN dispatched');
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (response.ok) {
        Logger.debug(`feeds loaded`);
        let payload = await response.json();
        dispatch(fetchHubUsersSuccess(payload.users));
        Logger.debug('FETCH_CONTRIBUTER_SUCESS dispatched');
    }
    else {
        let errorMsg = await response.text();
        Logger.debug(`loading feed data failed, error=${errorMsg}`);
        dispatch(fetchHubUsersFailure(response.status, errorMsg));
        Logger.debug('FETCH_CONTRIBUTER_FAILURE dispatched');
    }
}

export const BOARD_COLUMNS = [
    {
        label: 'Login Name',
        field: 'name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'First Name',
        field: 'firstName',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Last Name',
        field: 'lastName',
        sort: 'disabled',
        width: 100
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Description',
        field: 'description',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Production Centre',
        field: 'productionCentre',
        sort: 'disabled',
        width: 150
    },
];

export const mapStateToProps = (state) => (state.hubConfig.userConfig);

export const mapDispatchToProps = {
    onLoaded: payload => (dispatch, getState) => {
        Logger.debug(`contributors loaded, payload = ${payload}`);
    },
    requestData: () => async (dispatch, getState) => {
        const state = getState().hubConfig.userConfig;
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING || state.error) {
            // Don't issue a duplicate request (we are loading or already have or are loading the requested data)
            Logger.debug('already loaded or loading ');
            return;
        }
        const url = '/api/hubuser?activeOnly=false';
        let token = await authService.getAccessTokenAsync();
        await fetchHubUsers(url, token, dispatch);
    },

    getBoardData: () => (dispatch, getState) => {
        //Logger.debug('content:', state.hubContent.storyContent);
        const state = getState().hubConfig.userConfig;
        Logger.debug('users= ', state.users);
        let data = {
            columns: BOARD_COLUMNS,
            rows: state.users.map((u) => {
                return ({
                    name: u.userName,
                    firstName: u.firstName,
                    lastName: u.lastName,
                    email: u.email,
                    description: u.description,
                    productionCentre: authService.getHubNodeName(u.hubNodeId),
                    _user_id: u.userId,
                });
            }).reverse(),
        };
        return data;
    },

    disableUser:  (userId) => async (dispatch, getState) => {
        Logger.debug('disableUser, id=', userId);
        await hubApi.disableHubUser(userId);
    },

    enableUser: (userId) => async (dispatch, getState) => {
        Logger.debug('enableUser, id=', userId);
        await hubApi.enableHubUser(userId);
    }
};

