import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Overlay, Popover } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker'; // Import DateTimePicker
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Utilities, { UtilConstant } from 'hub-utilities';
import 'styles/main-content/VocabularyTable.scss';

export function VocabularyTable(props) {
    const [vocabData, setVocabData] = useState(props.vocabData || []);
    const [showAddOverlay, setShowAddOverlay] = useState(false);
    const [showEditOverlay, setShowEditOverlay] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [editRowIndex, setEditRowIndex] = useState(null);
    const [addValue, setAddValue] = useState("");
    const [editValue, setEditValue] = useState("");
    const [selectedDatetime, setSelectedDatetime] = useState(new Date);

    const addContainerRef = useRef(null);
    const editContainerRef = useRef(null);

    const addTarget = useRef(null);
    const editTarget = useRef(null);

    useEffect(() => {
        setVocabData(props.vocabData);
    }, [props.vocabData]);

    const handleEditClick = (index, value, e) => {
        setShowEditOverlay(true);
        setEditRowIndex(index);
        setEditValue(value);
        // Set the editTarget to the clicked edit icon
        editTarget.current = e.target;
    };

    const handleDeleteClick = (index) => {
        props.deleteVocab(index);
        setShowConfirmDelete(false);
    };

    const handleEditConfirm = () => {
        if (props.vocabType === UtilConstant.HUB_DATA_TYPE.DATETIME) {
            // Handle datetime save
            props.editVocab(editRowIndex, selectedDatetime);
        } else {
            // Handle other types
            props.editVocab(editRowIndex, editValue);
        }
        setEditRowIndex(null);
    };

    const formatVocab = (keyword) => {
        if (Utilities.verifyDataHasCorrectDataType(keyword, UtilConstant.HUB_DATA_TYPE.DATETIME)) {
            return Utilities.formatObjectDate(keyword, UtilConstant.HUB_TIME_FORMAT.CANADIAN, true)
        } else {
            return keyword;
        }
    };

    const handleAddConfirm = () => {
        switch (props.vocabType) {
            case UtilConstant.HUB_DATA_TYPE.KEYWORD:
            case UtilConstant.HUB_DATA_TYPE.TEXT:
            case UtilConstant.HUB_DATA_TYPE.DECIMAL:
                props.addVocab(addValue);
                setAddValue("");
                break;
            case UtilConstant.HUB_DATA_TYPE.DATETIME:
                props.addVocab(selectedDatetime);
                setSelectedDatetime(new Date());
                break;
            default:
                // Handle the default case
                break;
        }
        setShowAddOverlay(false);
    };

    const handleDocumentClick = (event) => {
        if (showAddOverlay && !event.target.closest("#popover") && !event.target.closest(".addVocabBtn")) {
            setShowAddOverlay(false);
        }

        if (showEditOverlay && !event.target.closest("#popover") && !event.target.closest(".edit-icon")) {
            setShowEditOverlay(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [showAddOverlay, showEditOverlay]);

    return (
        <>
            <div ref={addContainerRef}>
                <Button variant="primary" className="addVocabBtn" ref={addTarget} onClick={() => setShowAddOverlay(!showAddOverlay)}>
                    <FontAwesomeIcon icon={faPlus} /> Add
                </Button>
                <Overlay
                    show={showAddOverlay}
                    target={addTarget.current}
                    placement="bottom"
                    container={addContainerRef.current}
                >
                    <Popover
                        className="rowEditor addRowEditor"
                        id="popover"
                        key="popover"
                    >
                        <Popover.Title className="rowEditorTitle" as="h3">
                            Add Value
                        </Popover.Title>
                        <Popover.Content className="rowEditorContent">
                            {props.vocabType === UtilConstant.HUB_DATA_TYPE.DATETIME ? (
                                <DateTimePicker
                                    onChange={setSelectedDatetime}
                                    value={selectedDatetime}
                                    format="MMM dd, yyyy hh:mma"
                                    clearIcon={null}
                                    disableClock
                                />
                            ) : (
                                <input
                                    name="0"
                                    placeholder="New Value"
                                    type={props.vocabType === UtilConstant.HUB_DATA_TYPE.DECIMAL ? "number" : "text"}
                                    className="rowEditorTextField form-control"
                                    onChange={(e) => setAddValue(e.target.value)}
                                    style={{ marginBottom: '5px', zIndex: 999 }}
                                />
                            )}
                            <button
                                type="button"
                                className="rowEditorButton btn btn-dark"
                                onClick={() => handleAddConfirm()}
                            >
                                Confirm
                            </button>
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
            <Table striped hover className="vocabTable">
                <thead>
                    <tr>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {!!vocabData && vocabData.map((item, index) => (
                        <tr key={item.index}>
                            <td>{formatVocab(item.keyword)}</td>
                            <td className="actions" style={{ textAlign: "right" }}>
                                <div ref={editContainerRef}>
                                    <div
                                        className="edit-icon"
                                        style={{ cursor: "pointer", marginRight: "10px", zIndex: 999, display: "inline-block" }}
                                        onClick={(e) => handleEditClick(item.index, item.keyword, e)}
                                    >
                                        <FontAwesomeIcon icon={faEdit} />
                                    </div>
                                    {editRowIndex === item.index && (
                                        <Overlay
                                            show={editRowIndex === item.index && showEditOverlay}
                                            target={editTarget.current}
                                            placement="bottom-end"
                                            containerPadding={10}
                                            container={editContainerRef.current}
                                        >
                                            <Popover
                                                className="rowEditor addRowEditor"
                                                id="popover"
                                                key="popover"
                                            >
                                                <Popover.Title className="rowEditorTitle" as="h3">
                                                    Edit Value
                                                </Popover.Title>
                                                <Popover.Content className="rowEditorContent">
                                                    {props.vocabType === UtilConstant.HUB_DATA_TYPE.DATETIME ? (
                                                        <DateTimePicker
                                                            onChange={setSelectedDatetime}
                                                            value={selectedDatetime}
                                                            format="MMM dd, yyyy hh:mma"
                                                        />
                                                    ) : (
                                                        <input
                                                            name="0"
                                                            value={editValue}
                                                            type={props.vocabType === UtilConstant.HUB_DATA_TYPE.DECIMAL ? "number" : "text"}
                                                            className="rowEditorTextField form-control"
                                                            onChange={(e) => setEditValue(e.target.value)}
                                                            style={{ marginBottom: '5px', zIndex: 999 }}
                                                        />
                                                    )}
                                                    <button
                                                        type="button"
                                                        className="rowEditorButton btn btn-dark"
                                                        onClick={() => handleEditConfirm()}
                                                    >
                                                        Confirm
                                                    </button>
                                                </Popover.Content>
                                            </Popover>
                                        </Overlay>
                                    )}

                                    <FontAwesomeIcon
                                        className="delete-icon"
                                        onClick={() => setShowConfirmDelete(true)}
                                        style={{ zIndex: 999, cursor: 'pointer' }}
                                        icon={faTrash}
                                    />
                                    <Overlay
                                        show={showConfirmDelete}
                                        target={document.querySelector('.delete-icon')}
                                        placement="bottom"
                                        containerPadding={10}
                                    >
                                        <Popover
                                            className='confirmTooltip'
                                            onClick={() => handleDeleteClick(item.index)}
                                            onMouseLeave={() => setShowConfirmDelete(false)}
                                        >
                                            {' '}
                                            Confirm Delete?{' '}
                                        </Popover>
                                    </Overlay>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default VocabularyTable;
