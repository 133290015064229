// The integrated version combining both the OG + Metadata Form

import React , {useState, useEffect} from "react";
import * as Logger from 'loglevel';
import { OverlayTrigger, Tooltip, Tabs, Tab, Row, Col } from "react-bootstrap";
import MetadataPerNewsItemForm from '../Metadata/MetadataPerNewsItemForm';
import authService from 'components/api-authorization/AuthorizeService';
import AnimatingArrows from 'components/post-authorization/libraries/AnimatingArrows';

import { WIDGET_ANIMATION_STATE } from '../HubForm';
import * as HubConstant from 'util/HubConstant';

//Redux:
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import 'styles/widgets/NewsItem.scss'

function IntegratedNewsItemForm(props) {

    const { payload: newsItemIdFromProps } = props;
    const newsItemId = !!newsItemIdFromProps ? newsItemIdFromProps : -1;
    const [maxWidthConstraint ] = useState(1570);
    
    const [isHoveringExpand, setIsHoveringExpand] = useState(false);
    const [width, setWidth] = useState(Math.min(window.innerWidth, 1570));
    const [height, setHeight] = useState(window.innerHeight);
    const [addCounter, setAddCounter] = useState(1);
    const [metadata, setMetadata] = useState(null);
    const [changeNotifier, setChangeNotifier] = useState(false);
    const [isSmallScreenSize, setIsSmallScreenSize] = useState(false); 
    
    useEffect(()=> {
        onScreenSizeRestructure();
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    },[])

    //re-do initializations as of the child:
    const initializeNewsItemMetadata = async () => {
        Logger.debug(props)
        //fetch newsobject metadata if it's null:
        await props.getMetadata();
        //if (!(!!props.objectList.find(newsObj => newsObj.id === newsItemId).metadata)) {
        if (newsItemId > -1) await props.getNewsItemMetadata(newsItemId);
        //}

        var newMetadataList = [];
        props.metadata.forEach(metadata => {
            var metadataFieldInNewsObj = null;
            var newsItem = props.objectList.find(newsObj => newsObj.id === newsItemId);
            if (!!newsItem) metadataFieldInNewsObj = newsItem.metadata.find(newsObjMetadata => newsObjMetadata.fieldId === metadata.fieldId);

            if (!!metadataFieldInNewsObj) {
                //if metadataField is found in objectList, use metadataFieldInNewsObj
                Logger.debug(props.metadata);
                var metadataFieldInNewsObjWithCompleteMetadata = JSON.parse(JSON.stringify(metadataFieldInNewsObj)); //remove object ref
                if (metadata.isControlledVocabulary) metadataFieldInNewsObjWithCompleteMetadata["availableControlledVocabularies"] = metadata.vocabulary;
                metadataFieldInNewsObjWithCompleteMetadata["isControlledVocabulary"] = metadata.isControlledVocabulary;
                metadataFieldInNewsObjWithCompleteMetadata.values = metadataFieldInNewsObjWithCompleteMetadata.values.map(vocab => { return { ...vocab, isChecked: false } }); //for delete selection
                newMetadataList.push(metadataFieldInNewsObjWithCompleteMetadata);
            } else {
                newMetadataList.push({
                    fieldId: metadata.fieldId,
                    dataType: metadata.dataType,
                    fieldName: metadata.fieldName,
                    values: [],
                    ...metadata.isControlledVocabulary && { availableControlledVocabularies: metadata.vocabulary },
                    isControlledVocabulary: metadata.isControlledVocabulary
                });
            }
        });
        Logger.debug(newMetadataList);
        var newChangeNotifier = !changeNotifier;

        setMetadata(newMetadataList);
        setChangeNotifier(newChangeNotifier);
    }

    const onSelectValue = (fieldId, valIndex, isSelected) => {
        Logger.debug(fieldId); Logger.debug(valIndex);
        var newMetadata = null;
        if (isSelected !== undefined) {
            newMetadata = metadata;
            Logger.debug(newMetadata);
            newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isSelected;
            //newMetadata.forEach(dataField => dataField.values.forEach(val => {Logger.debug(val.isChecked);val.isChecked = isSelected}));
        } else {
            //Toggle on/off:
            var isChecked = metadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked;
            Logger.debug(isChecked);
            isChecked = !isChecked;
            Logger.debug(metadata);

            newMetadata = metadata;
            newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isChecked;
        }

        var newChangeNotifier = !changeNotifier;
        setChangeNotifier(newChangeNotifier);
    }

    const onDeleteSelectedValues = () => {
        metadata.forEach(dataField => {
            dataField.values = dataField.values.filter(val => !val.isChecked);
        });
        var newChangeNotifier = !changeNotifier;
        setChangeNotifier(newChangeNotifier);
    }

    const onDeleteSelectedVal = (fieldId, valIndex) => {
        Logger.debug(fieldId);
        Logger.debug(valIndex);
        var foundVals = metadata.find(dataField => dataField.fieldId === fieldId).values;
        foundVals = foundVals.filter(val => val.index !== valIndex);

        //Assign to state:
        metadata.find(dataField => dataField.fieldId === fieldId).values = foundVals;

        Logger.debug(foundVals);

        var newChangeNotifier = !changeNotifier;
        setChangeNotifier(newChangeNotifier);
    }

    const onChange = (val, index) => {
        Logger.debug(val);
        Logger.debug(index);
        var newMetadata = JSON.parse(JSON.stringify(metadata));
        newMetadata[index].fieldVal = val;
        Logger.debug(newMetadata);
        setMetadata(newMetadata);
    }

    /*async onUpdateNewsItemMetadata() {
        var body = {
            metadata: state.metadata.filter(data => data.values.length > 0).map(data => {
                return {
                    fieldId: data.fieldId,
                    values: data.values.map(value => value.value)
                }
            })
        };
        await this.props.updateNewsItemMetadata(this.newsItemId, body);
        await this.props.getNewsItemMetadata(this.newsItemId);

        Logger.debug(this.props.error);
        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }*/

    const onLocalAddNewsItemMetadata = (id, value) => {
        Logger.debug(value);
        Logger.debug(id);
        var newMetadata = metadata;
        newMetadata.forEach(dataVal => {
            Logger.debug(dataVal)
            if (id === dataVal.fieldId)
                dataVal.values.push({ index: -addCounter, value: value, isChecked: false });
        });
        Logger.debug(newMetadata)
        var newAddCounter = addCounter + 1;
        setMetadata(newMetadata);
        setMetadata(newMetadata, () => setChangeNotifier(!changeNotifier));

    }

    const onLocalResetNewsItemMetadata = (id, arrOfMetadata) => {
        var newMetadata = metadata;
        Logger.debug(newMetadata);
        newMetadata.forEach(dataVal => {
            Logger.debug(dataVal)
            if (id === dataVal.fieldId)
                dataVal.values = arrOfMetadata.map(mdata => { return { index: mdata.index, value: mdata.value, isChecked: false } });
        });
        var newAddCounter = addCounter + 1;
        setMetadata(newMetadata);
        setAddCounter(newAddCounter, () => setChangeNotifier(!changeNotifier));

        
        // newMetadata.forEach(dataVal => {
        //     newMetadata.values.push({ index: -this.state.addCounter, value: value, isChecked: false });
        // });
        // Logger.debug(newMetadata)
        // var newAddCounter = this.state.addCounter + 1;
        // this.setState({ metadata: newMetadata, addCounter: newAddCounter }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
    }



    //==============end of metadata news form functions===============================================

    const updateDimensions = () => {
        setWidth(Math.min(window.innerWidth, maxWidthConstraint));
        setHeight(window.innerHeight);
        onScreenSizeRestructure();
    };

    const onScreenSizeRestructure = () => {
        if (window.innerWidth <= 1007 && !isSmallScreenSize) {
            setIsSmallScreenSize(true)
            
        } else if (window.innerWidth > 1007 && isSmallScreenSize) {
            setIsSmallScreenSize(false)
        }
    }

    const createNewsItemWithMetadataInFolder = (newsItemWithoutMetadata, elementType, folderId, file) => {
        var metadataWithVals = metadata.filter(data => data.values.length > 0).map(data => {
            return {
                fieldId: data.fieldId,
                values: data.values.map(value => value.value)
            }
        })

        let formData = new FormData();
        if (elementType === HubConstant.HUB_OBJECT_STORY) {
            newsItemWithoutMetadata["metadata"] = metadataWithVals
        } else {
            newsItemWithoutMetadata["metadata"] = metadataWithVals;
            formData.append("elementDescriptor", JSON.stringify(newsItemWithoutMetadata));
            formData.append("file", file);
        }
        Logger.debug(newsItemWithoutMetadata);
        Logger.debug(JSON.stringify(newsItemWithoutMetadata))
        let url = `/api/v2/Folder/${folderId}/${elementType === HubConstant.HUB_OBJECT_STORY ? "story" : "element"}`;
        return new Promise(async (resolve, reject) => {
            let token = await authService.getAccessTokenAsync();
            resolve(await fetch(url, {
                method: "POST",
                headers: {
                    ...elementType === HubConstant.HUB_OBJECT_STORY && {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    'Authorization': `Bearer ${token}`,
                },
                body: elementType === HubConstant.HUB_OBJECT_STORY ? JSON.stringify(newsItemWithoutMetadata) : formData
            }));
        });
    }

    

    //the submit button of storyform is called
    const createNewsItemWithMetadata = (newsItemWithoutMetadata, elementType, file) => {

        //construct the story object, by combining it with metadata:
        //this.metadataPerNewsItemForm.onCreateStoryWithMetadata()

        var metadataWithVals = metadata.filter(data => data.values.length > 0).map(data => {
            return {
                fieldId: data.fieldId,
                values: data.values.map(value => value.value)
            }
        })

        let formData = new FormData();
        if (elementType === HubConstant.HUB_OBJECT_STORY) {
            newsItemWithoutMetadata["metadata"] = metadataWithVals
        } else {
            newsItemWithoutMetadata["metadata"] = metadataWithVals;
            formData.append("elementDescriptor", JSON.stringify(newsItemWithoutMetadata));
            formData.append("file", file);
        }
        Logger.debug(newsItemWithoutMetadata);
        Logger.debug(JSON.stringify(newsItemWithoutMetadata))

        return new Promise(async (resolve, reject) => {
            let token = await authService.getAccessTokenAsync();
            Logger.debug("!")
            resolve(await fetch(`/api/CloudQ/1/${elementType === HubConstant.HUB_OBJECT_STORY ? "story" : "element"}`, {
                method: "POST",
                headers: {
                    ...elementType === HubConstant.HUB_OBJECT_STORY && {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    'Authorization': `Bearer ${token}`,
                },
                body: elementType === HubConstant.HUB_OBJECT_STORY ? JSON.stringify(newsItemWithoutMetadata) : formData
            }));
        });
    }

    const getCategory = () => {
        if (!!props.metadata) {
            return props.metadata.find(mdata => mdata.fieldId === 1);
        }
    }

    const renderMetadataPerNewsItemForm = () => {
        return (
            <MetadataPerNewsItemForm
                isIntegratedForm={true}
                payload={props.payload}
                //all the duplicated functions... all-hail react top-down architecture:
                initializeNewsItemMetadata={initializeNewsItemMetadata}
                onSelectValue={onSelectValue}
                onDeleteSelectedValues={onDeleteSelectedValues}
                onDeleteSelectedVal={onDeleteSelectedVal}
                onChange={onChange}
                onUpdateNewsItemMetadata={props.onUpdateNewsItemMetadata}
                onLocalAddNewsItemMetadata={onLocalAddNewsItemMetadata}

                integratedMetadata={metadata}
                integratedChangeNotifier={changeNotifier}
                {... props} />
        )
    }

    const renderNewsItemForm = () => {
        /* isIntegratedForm consists of both MetadataPerNewsItemForm and StoryForm */
        return (
            <props.form
                createNewsItemWithMetadata={createNewsItemWithMetadata}
                createNewsItemWithMetadataInFolder={createNewsItemWithMetadataInFolder }
                isIntegratedForm={true}
                submitHandler={props.submitHandler}
                getCategory={getCategory}
                storyMetadata={metadata}
                folderData={props.hubWorkingFolder}
                onDeleteSelectedVal={onDeleteSelectedVal}
                onSubmitRowEditor={onLocalAddNewsItemMetadata}
                onLocalResetNewsItemMetadata={onLocalResetNewsItemMetadata}
                {... props} />
        )
    }


    if (!!props.form) {
        return (
            true === false ? // !this.state.isSmallScreenSize ? BUG 439: Use tab view in New Story form
                <>
                    {renderNewsItemForm()}
                    <div className="collapsableDiv">
                        {
                            !!props.metadata ?
                                <OverlayTrigger key="ExpandTooltip" placement="left" overlay={
                                    <Tooltip className="additionalSettingsTooltip rowActionTooltip">
                                        {(props.metadata.find(mdata => mdata.fieldId !== 1) === undefined) //1 means category; if can't find a metadata field other than category
                                            ?
                                            <>
                                                Data Fields< br />Need to be Added< br />For Additional Settings
                                </>
                                            :
                                            <>
                                                Additional < br /> Settings
                            </>
                                        }
                                    </Tooltip>}>
                                    <div
                                        className={`${props.widgetAnimState === WIDGET_ANIMATION_STATE.NONE && !props.isHorizontallyExpanded ? `ExpandDiv` : `UnexpandDiv`} ${
                                            (props.metadata.find(mdata => mdata.fieldId !== 1) === undefined) ? "disabledWithTooltip" : ""
                                            }`}
                                        onClick={() => { if (!!props.metadata.find(mdata => mdata.fieldId !== 1)) props.horizontalCollapse() }}
                                        onMouseEnter={() => {
                                            if (!!props.metadata.find(mdata => mdata.fieldId !== 1)) setIsHoveringExpand(true)
                                        }} 
                                        onMouseLeave={() => { if (!!props.metadata.find(mdata => mdata.fieldId !== 1)) setIsHoveringExpand(false) }}>
                                        <AnimatingArrows
                                            isHorizontallyExpanded={props.isHorizontallyExpanded}
                                            shouldAnimate={isHoveringExpand} />
                                    </div>
                                </OverlayTrigger>
                                : <></>
                        }
                        <div className="HorizontalMetadataCol" style={{ width: width * 0.9 - 500 }}>
                            {renderMetadataPerNewsItemForm()}
                        </div>
                    </div>
                </>
                :
                <Row className="HubTabRow">
                    <Col xs="12">
                        <Tabs
                            id="controlled-tab-example"
                            defaultActiveKey="general"
                        //mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                        //unmountOnExit
                        >
                            <Tab eventKey="general" title="General">
                                {renderNewsItemForm()}
                            </Tab>
                            <Tab eventKey="extraSettings" title={`Properties`}>
                                {renderMetadataPerNewsItemForm()}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
        )
    } else { return <></> }

}

//React expects me to do the same thing as i did in the child due to it's top-down architecture.... sad life

const mapMetadataAndObjectBoardStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        hubWorkingFolder: state.hubObjectContent.hubWorkingFolder,
        metadata: state.hubConfig.hubMetadata.metaData,
        success: state.hubConfig.hubMetadata.success,
        error: state.hubConfig.hubMetadata.error
    }
}

export default withRouter(connect(
    mapMetadataAndObjectBoardStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(IntegratedNewsItemForm));