import { UserManager, WebStorageStateStore } from 'oidc-client';
import * as Logger from 'loglevel';
import { ApplicationPaths, ApplicationName } from './ApiAuthorizationConstants';
import Utilities, { UtilConstant } from 'hub-utilities'

const LoginMode = {
    Silent: 'silent',
    PopUp: 'popup',
    Redirect: 'redirect'
}

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};

export class AuthorizeService {
    _callbacks = [];
    _nextSubscriptionId = 0;
    _user = null;
    _isAuthenticated = false;


    _oidcSettings = null;
    _accessToken = null;
    _currentHubUser = null;
    _hubInfo_ = null;
    _hubNodes_ = null;
    _newsFeeds = null;
    _allUsers_ = null;

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    subscribe(callback) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        let ns = this._callbacks.length;
        Logger.debug(`subscribe: # of subscribers = ${ns}`);
        Logger.debug(this._callbacks);
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks = this._callbacks.splice(subscriptionIndex[0].index, 1);
        let ns = this._callbacks.length;
        Logger.debug(`unsubscribe: # of subscribers = ${ns}`);
        Logger.debug(this._callbacks);
    }

    notifySubscribers() {
        let ns = this._callbacks.length;
        Logger.debug(this._callbacks);
        Logger.debug(`notifySubscribers: # of subscribers = ${ns}`);
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    async updateState(user) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        if (this._isAuthenticated) {
            Logger.debug("------------ ASYNC JOB HUB_METADATA STARTED  -------------");
            await this.loadHubMetadata();
            this._accessToken = this._user.access_token;
            Logger.debug(`Authentication update state: acccess Token acquired.`);
            Logger.debug("------------ ASYNC JOB HUB_METADATA FINISHED -------------");
        }
        else {
            Logger.debug("Authentication: User signed out, clean up hub metadata");
            this._accessToken = null;
            this._currentHubUser = null;
            this._hubNodes_ = null;
            this._newsFeeds = null;
            this._allUsers_ = null;
        }
        this.notifySubscribers();
    }


    // create oidc usermanager
    async ensureUserManagerInitialized() {
        if (this.userManager !== undefined) {
            return;
        }

        Logger.debug(navigator.userAgent);
        var isIOS = (/iPad|iPhone|iPod/).test(navigator.userAgent) && !window.MSStream;
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

        // load IDP settings from OIDC api end point(/_configuration/BurliNewsHub), which are loaded by HubHub API from config file
        let settings;
        Logger.debug("ApiConfigUrl is: ", ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
        let response = await fetch(ApplicationPaths.ApiAuthorizationClientConfigurationUrl);
        if (response.ok) {
            Logger.debug("response is: ", response);
            settings = await response.json();
        }
        else {
            Logger.debug("no...");
            // default settings
            settings = {
                authority: 'https://localhost:5001',                    // The URL to the OIDC/OAuth2 provider
                client_id: '33784E3A-4185-42AB-8065-BBDF1BF147A7',      // the registered client id (BurliNewsHub Client Id)
                redirect_uri: "http://localhost:5003/authentication/login-callback",
                silent_redirect_uri: "http://localhost:5003/authentication/silent-renew-callback",
                post_logout_redirect_uri: 'http://localhost:5003/authentication/logout-callback',
                //redirect_uri: "http://localhost/authentication/login-callback",
                //post_logout_redirect_uri: 'http://localhost/authentication/logout-callback',
                //response_type: 'id_token token' , // implicit flow, requires id token and access token
                //scope: 'openid profile burli_user_profile hub_api_admin hub_api_node_admin hub_api_basic hub_idp_api offline_access',
                hub_id: 'C67FB6D2-B972-4D38-A2B0-7B5FEE980192', // GLOBAL_HUB_ID = new Guid("C67FB6D2-B972-4D38-A2B0-7B5FEE980192");
            }
        }
        settings.response_type = 'id_token token'; // implicit flow, requires id token and access token
        settings.scope = "openid profile offline_access burli_user_profile hub_idp_api hub_api_admin hub_api_node_admin hub_api_basic";
        settings.automaticSilentRenew = !isIOS && !isSafari;  // will fire accesstokenExpiring event (by default 60 seconds before token expiration - accessTokenExpiringNotificationTime )
        settings.monitorSession = false;
        settings.loadUserInfo = true;
        settings.includeIdTokenInSilentRenew = true;  //id_token is included as id_token_hint in silent renew calls
        //settings.popupWindowFeatures = 'location=no,toolbar=no,width=500,height=500,left=100,top=100';
        //config usermanager to use session storage
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName,
            store: sessionStorage
        });
        /*
        settings.userStore = new WebStorageStateStore({
            prefix: ApplicationName
        });
        */
        // Burli Identity server requires hub_id parameter in querystring
        settings.extraQueryParams = {
            hub_id: settings.hub_id,
        };

        Logger.debug(settings);

        this._oidcSettings = settings;
        this.userManager = new UserManager(this._oidcSettings);
        Logger.debug("userManager created.");
        /*
        // add event handlers
        // when a user session has been(re-)established
        this.userManager.events.addUserLoaded(async (loadedUser) => {
            Logger.debug(`!!!UserLoaded event - use session established, user = ${loadedUser}`);
            //await this.updateState(user);
        });
        // the user session has be terminated
        this.userManager.events.addUserUnloaded(async () => {
            Logger.debug(`!!!UserUnloaded event - use session terminated.`);
            //await this.updateState(user);
        });
        */
        /*
        let user = await this.userManager.getUser();
        if (user && user.expired) {
            await this.userManager.removeUser();
            user = null;
        }
        
        await this.updateState(user);
        */
        // When the user's Sign-in status in OP (Openid Provider) has changed
        this.userManager.events.addUserSignedOut(async () => {
            Logger.debug("!!!UserSignedOut event fiired!!!");
            await this.userManager.removeUser();
            await this.updateState(undefined);
        });


        this.userManager.events.addAccessTokenExpiring(async () => {

            let frameName = Utilities.inIframe() ? `IFRAME` : `TOP`;
            Logger.debug(`!!!AccessTokenExpiring event fired, window= ${frameName} !!!`);
            let user = await this.renewToken();
            let oldUser = await this.userManager.getUser();
            if (!!user && user.expires_at !== oldUser.expires_at) {
                Logger.debug(`!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!window= ${frameName}!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ACCESSTOKEN NOT RENEWED!!!`);
            }
            let isAuthenticated = !!user;
            if (isAuthenticated !== this._isAuthenticated) {
                await this.updateState(user);
            }
            else {
                this._user = user;
                Logger.debug(`new expire time ${Utilities.formatUnixTime(this._user.expires_at)}`);
            }
        });
        Logger.debug(`userManager initialized`);
    }

    async renewToken() {
        let user = null;
        let frameName = Utilities.inIframe() ? `IFRAME` : `TOP`;
        try {
            user = await this.userManager.signinSilent(this.createArguments(LoginMode.Silent));
            Logger.debug(`token refreshed (window=${frameName}) user:`);
            Logger.debug(user);
        }
        catch (error) {
            Logger.debug(`token refreshed error (window=${frameName}) ${error}`);
        }
        return user;
    }
    getIdentity() {
        return this._user && this._user.profile;
    }

    isAdministrator() {
        const identity = authService.getIdentity();
        const isAdmin = identity && identity.hub_user_role && (identity.hub_user_role === UtilConstant.USER_CLAIM_HUB_ADMIN || identity.hub_user_role === UtilConstant.USER_CLAIM_NODE_ADMIN);
        return !!isAdmin;
    }

    getUserType() {
        const identity = authService.getIdentity();
        const isAdmin = identity && identity.hub_user_role && (identity.hub_user_role === UtilConstant.USER_CLAIM_HUB_ADMIN || identity.hub_user_role === UtilConstant.USER_CLAIM_NODE_ADMIN);
        return !!isAdmin ?
            identity.hub_user_role === UtilConstant.USER_CLAIM_HUB_ADMIN ?
                UtilConstant.HUB_USER_TYPE.HUB_ADMIN : UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN : UtilConstant.HUB_USER_TYPE.HUB_USER;
    }

    getUserGuid() {
        return authService.getIdentity().sub;
    }

    async getUserAsync() {
        if (this._user && this._user.profile) {
            return this._user.profile;
        }

        await this.ensureUserManagerInitialized();
        let user = await this.userManager.getUser();
        if (!!user) {
            if (user.expires_in <= 60) {
                await this.userManager.removeUser();
                user = null;
                /*
                Logger.debug("------------ ASYNC JOB RENEW_TOKEN STARTED  -------------");
                user = await this.renewToken();
                Logger.debug("------------ ASYNC JOB RENEW_TOKEN FINISHED  -------------");
                */
            }
            if (!!user && !this._user) {
                this._user = user;
                this._isAuthenticated = true;
                Logger.debug("------------ ASYNC JOB INIT_HUB_METADATA STARTED  -------------");
                await this.loadHubMetadata();
                this._accessToken = this._user.access_token;
                Logger.debug(`Authentication getUserAsync: acccess Token acquired, token=`, this._accessToken.substring(0, 16));
                Logger.debug("------------ ASYNC JOB INIT_HUB_METADATA FINISHED -------------");
                Logger.debug(`token info: expires at ${Utilities.formatUnixTime(this._user.expires_at)}`);
            }
        }
        return user && user.profile;
    }

    async isAuthenticatedAsync() {
        const user = await this.getUserAsync();
        return !!user;
    }

    isAuthenticated() {
        return this._isAuthenticated;
    }
    async syncHubUser(user) {
        // the logged in user is not synchronized with hub yet, an imediate hub user sync is required
        if (!!user)
            return;
        //todo:
        //call hub api to synchronize user
    }

    async getAccessTokenAsync() {
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        if (user) {
            this._accessToken = user.access_token;
            return user.access_token;
        }
        else {

        }
        return undefined;
    }

    getAccessToken = () => this._accessToken;

    error(message) {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state) {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect(redirectUrl) {
        return { status: AuthenticationResultStatus.Redirect, redirectUrl };
    }

    static get instance() { return authService }

    createArguments(mode, state) {
        if (mode !== LoginMode.Silent) {
            return { data: { mode, userState: state } };
        } else {
            return { data: { mode, userState: state }, redirect_uri: this.userManager.settings.silent_redirect_uri };
        }
    }

    async getSignInResponse(url) {
        const keys = await this.userManager.settings.stateStore.getAllKeys();
        const states = keys.map(key => ({ key, state: this.userManager.settings.stateStore.get(key) }));
        for (const state of states) {
            state.state = await state.state;
        }
        try {
            const response = await this.userManager.processSigninResponse(url);
            Logger.debug(response);
            return response;
        } finally {
            for (const state of states) {
                await this.userManager.settings.stateStore.set(state.key, state.state);
            }
        }
    }


    async getSignOutResponse(url) {
        const keys = await this.userManager.settings.stateStore.getAllKeys();
        const states = keys.map(key => ({ key, state: this.userManager.settings.stateStore.get(key) }));
        for (const state of states) {
            state.state = await state.state;
        }
        try {
            const response = await this.userManager.processSignoutResponse(url);
            return response;
        } finally {
            for (const state of states) {
                await this.userManager.settings.stateStore.set(state.key, state.state);
            }
        }
    }

    // By redirecting the browser to the IdP to perform a traditional redirect flow.
    async signIn(state) {
        await this.ensureUserManagerInitialized();
        try {
            Logger.debug("------------ ASYNC JOB5 STARTED - Create redirect signin request");
            const signInRequest = await this.userManager.createSigninRequest(this.createArguments(LoginMode.Redirect, state));
            Logger.debug("------------ ASYNC JOB5 FINISHED - Redirect signin request created: ");
            Logger.debug(signInRequest);
            let signInUrl = signInRequest.url;
            Logger.debug(`redirecting to ${signInUrl}`);
            return this.redirect(signInUrl);
        } catch (redirectError) {
            Logger.debug("------------ ASYNC JOB5 EXCEPTION - Redirect signin request created: ", redirectError);
            Logger.error("Redirect authentication error: ", redirectError);
            return this.error(redirectError);
        }
    }

    async completeSignIn(url) {
        Logger.debug(`CompleteSign, url= ${url}`);
        await this.ensureUserManagerInitialized();
        let response = undefined;
        try {
            Logger.debug(`------------ ASYNC JOB4 STARTED - Get signin resp from url ${url}`);
            response = await this.getSignInResponse(url);
            Logger.debug(`------------ ASYNC JOB4 FINISHED - Get signin resp, response:`);
            if (!!response.error) {
                return this.error(`${response.error}: ${response.error_description}`);
            }
        } catch (processSignInResponseError) {
            Logger.debug(`------------ ASYNC JOB4 EXCEPTION - Get signin resp exception :`);
            Logger.error(processSignInResponseError);
            if (processSignInResponseError.error === "login_required") {
                // This error is thrown by the underlying oidc client when it tries to log in
                // the user silently as in case 1 defined above and the IdP requires the user
                // to enter credentials. We let the user manager handle the response to notify
                // the main window.
                response = processSignInResponseError;
            } else {
                Logger.error("There was an error processing the sign-in response: ", processSignInResponseError);
                return this.error("There was an error processing the sign-in response.");
            }
        }
        try {
            Logger.debug(`------------ ASYNC JOB5_3 STARTED - signinRedirectCallback started`);
            let user = await this.userManager.signinRedirectCallback(url);
            Logger.debug(`------------ ASYNC JOB5_3 FINISHED - signinRedirectCallback started`);
            await this.updateState(user);
            return this.success(response.state.userState);
        } catch (redirectCallbackError) {
            Logger.error(`------------ ASYNC JOB5_3 EXCEPTION - signinRedirectCallback started`, redirectCallbackError);
            return this.error("Redirect callback authentication error.");
        }
    }

    async signOut(state) {
        await this.ensureUserManagerInitialized();
        try {
            Logger.debug(`------------ ASYNC JOB10_1 - Create signout request, state=${state}`);
            var arg = this.createArguments(LoginMode.Redirect);
            Logger.debug(`------------ ASYNC JOB10_2 - popup signout arguments:`);
            Logger.debug(arg);
            await this.userManager.signoutRedirect(arg);
            return this.success(state);
            /*
            Logger.debug(`------------ ASYNC JOB10_1 - Create signout request, state=${state}`);
            const signOutRequest = await this.userManager.createSignoutRequest(this.createArguments(LoginMode.Redirect, state));
            Logger.debug(`------------ ASYNC JOB10_2 - signout request:`);
            Logger.debug(signOutRequest);
            return this.redirect(signOutRequest.url);
            */
        } catch (redirectSignOutError) {
            Logger.error("Redirect signout error: ", redirectSignOutError);
            return this.error(redirectSignOutError);
        }
    }


    // signInEx try to authenticate the user in three different ways:
    // 1) Try to see if we can authenticate the user silently. This happens when the user 
    //    is already logged in on the IdP and is done using a hidden iframe on the client.
    // 2) Try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signInEx(state) {
        await this.ensureUserManagerInitialized();
        try {
            let siginArg = this.createArguments(LoginMode.Silent);
            Logger.debug("------------ ASYNC JOB2 STARTED - silent sigin with arguments: ");
            Logger.debug(siginArg);
            const silentUser = await this.userManager.signinSilent(siginArg);
            Logger.debug("------------ ASYNC JOB2 RETURNED, silentUser: ");
            Logger.debug(silentUser);
            await this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            Logger.debug("------------ ASYNC JOB2 EXCEPTION - Silent authentication error: ", silentError);
            try {
                Logger.debug("------------ ASYNC JOB7 STARTED - TRY POP UP signin");
                if (this._popUpDisabled) {
                    throw new Error('Popup disabled. Change \'AuthorizeService.js:AuthorizeService._popupDisabled\' to false to enable it.')
                }

                const popUpUser = await this.userManager.signinPopup(this.createArguments(LoginMode.PopUp));
                Logger.debug("------------ ASYNC JOB7 STARTED - POP UP signin Finished");

                await this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError) {
                Logger.debug("------------ ASYNC JOB7 EXCEPTION - POP UP signin error");
                Logger.debug(popUpError);
                if (popUpError.message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    Logger.debug("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    Logger.debug("------------ ASYNC JOB5 STARTED - Create redirect signin request");
                    const signInRequest = await this.userManager.createSigninRequest(
                        this.createArguments(LoginMode.Redirect, state));
                    Logger.debug("------------ ASYNC JOB5 FINISHED - Redirect signin request created: ");
                    Logger.debug(signInRequest);
                    let signInUrl = signInRequest.url;
                    Logger.debug(`redirecting to ${signInUrl}`);
                    return this.redirect(signInUrl);
                } catch (redirectError) {
                    Logger.debug("------------ ASYNC JOB5 EXCEPTION - Redirect signin request created: ", redirectError);
                    Logger.error("Redirect authentication error: ", redirectError);
                    return this.error(redirectError);
                }
            }
        }
    }

    // We are receiving a callback from the IdP. This code can be running in 3 situations:
    // 1) As a hidden iframe started by a silent login on signIn (above). The code in the main
    //    browser window will close the iframe after returning from signInSilent.
    // 2) As a PopUp window started by a pop-up login on signIn (above). The code in the main
    //    browser window will close the pop-up window after returning from signInPopUp
    // 3) On the main browser window when the IdP redirects back to the app. We will process
    //    the response and redirect to the return url or display an error message.
    async completeSignInEx(url) {
        Logger.debug(`CompleteSign, url= ${url}`);
        await this.ensureUserManagerInitialized();
        let response = undefined;
        try {
            Logger.debug(`------------ ASYNC JOB4 STARTED - Get signin resp from url ${url}`);
            response = await this.getSignInResponse(url);
            Logger.debug(`------------ ASYNC JOB4 FINISHED - Get signin resp, response:`);
            if (!!response.error) {
                return this.error(`${response.error}: ${response.error_description}`);
            }
        } catch (processSignInResponseError) {
            Logger.debug(`------------ ASYNC JOB4 EXCEPTION - Get signin resp exception :`);
            Logger.debug(processSignInResponseError);
            if (processSignInResponseError.error === "login_required") {
                // This error is thrown by the underlying oidc client when it tries to log in
                // the user silently as in case 1 defined above and the IdP requires the user
                // to enter credentials. We let the user manager handle the response to notify
                // the main window.
                response = processSignInResponseError;
            } else {
                Logger.error("There was an error processing the sign-in response: ", processSignInResponseError);
                return this.error("There was an error processing the sign-in response.");
            }
        }

        const authenticationState = response.state;
        const mode = authenticationState.mode;

        switch (mode) {
            case LoginMode.Silent:
                try {
                    Logger.debug(`------------ ASYNC JOB5_1 STARTED - signinSilentCallback started`);
                    await this.userManager.signinSilentCallback(url);
                    Logger.debug(`------------ ASYNC JOB5_1 FINISHED - signinSilentCallback returned`);
                    return this.success(undefined);
                } catch (silentCallbackError) {
                    console.debug("------------ ASYNC JOB5_1 EXCEPTION: ", silentCallbackError);
                    return this.error("Silent callback authentication error");
                }
            case LoginMode.PopUp:
                try {
                    Logger.debug(`------------ ASYNC JOB5_2 STARTED - signinPopupCallback started`);
                    await this.userManager.signinPopupCallback(url);
                    Logger.debug(`------------ ASYNC JOB5_2 FINISHED - signinPopupCallback started`);
                    return this.success(undefined);
                } catch (popupCallbackError) {
                    Logger.debug(`------------ ASYNC JOB5_2 EXCEPTION - signinPopupCallback started`, popupCallbackError);
                    return this.error("Popup callback authentication error.");
                }
            case LoginMode.Redirect:
                try {
                    Logger.debug(`------------ ASYNC JOB5_3 STARTED - signinRedirectCallback started`);
                    let user = await this.userManager.signinRedirectCallback(url);
                    Logger.debug(`------------ ASYNC JOB5_3 FINISHED - signinRedirectCallback started`);
                    await this.updateState(user);
                    return this.success(response.state.userState);
                } catch (redirectCallbackError) {
                    Logger.error(`------------ ASYNC JOB5_3 EXCEPTION - signinRedirectCallback started`, redirectCallbackError);
                    Logger.debug("Redirect callback authentication error: ", redirectCallbackError);
                    return this.error("Redirect callback authentication error.");
                }
            default:
                throw new Error(`Invalid login mode '${mode}'.`);
        }
    }

    // signOutEx Try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOutEx(state) {
        await this.ensureUserManagerInitialized();
        try {
            Logger.debug(`------------ ASYNC JOB11_1 - Create popup signout arguments, state=${state}`);
            var arg = this.createArguments(LoginMode.PopUp);
            Logger.debug(`------------ ASYNC JOB11_2 - popup signout arguments:`);
            Logger.debug(arg);
            await this.userManager.signoutPopup(arg);
            await this.updateState(undefined);
            return this.success(state);
        } catch (popupSignOutError) {
            Logger.debug("Popup signout error: ", popupSignOutError);
            try {
                const signOutRequest = await this.userManager.createSignoutRequest(
                    this.createArguments(LoginMode.Redirect, state));
                return this.redirect(signOutRequest.url);
            } catch (redirectSignOutError) {
                Logger.debug("Redirect signout error: ", redirectSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }



    // We are receiving a callback from the IdP. This code can be running in 2 situations:
    // 1) As a PopUp window started by a pop-up login on signOut (above). The code in the main
    //    browser window will close the pop-up window after returning from signOutPopUp
    // 2) On the main browser window when the IdP redirects back to the app. We will process
    //    the response and redirect to the logged-out url or display an error message.
    async completeSignOut(url) {
        Logger.debug(`------------ ASYNC JOB10_2 - Compeleting signout, url=${url}`);
        await this.ensureUserManagerInitialized();
        let response = undefined;
        try {
            response = await await this.getSignOutResponse(url);
        } catch (processSignOutResponseError) {
            Logger.debug("There was an error processing the sign-out response: ", processSignOutResponseError);
            response = processSignOutResponseError;
        }

        if (!!response.error) {
            return this.error(`${response.error}: ${response.error_description}`);
        }
        Logger.debug(`------------ ASYNC JOB10_2 Finished- response:`);
        Logger.debug(response);
        const authenticationState = response.state;
        const mode = (authenticationState && authenticationState.mode) ||
            !!window.opener ? LoginMode.PopUp : LoginMode.Redirect;

        switch (mode) {
            case LoginMode.PopUp:
                try {
                    await this.userManager.signoutPopupCallback(url);
                    return this.success(response.state && response.state.userState);
                } catch (popupCallbackError) {
                    Logger.debug("Popup signout callback error: ", popupCallbackError);
                    return this.error("Popup signout callback error");
                }
            case LoginMode.Redirect:
                try {
                    await this.userManager.signoutRedirectCallback(url);
                    await this.updateState(undefined);
                    return this.success(response.state.userState);
                } catch (redirectCallbackError) {
                    Logger.debug("Redirect signout callback error: ", redirectCallbackError);
                    return this.error("Redirect signout callback error");
                }
            default:
                throw new Error(`Invalid LoginMode '${mode}'.`);
        }
    }


    loadHubMetadata = async () => {
        if (!this._user) {
            throw new Error(`Invalid Login, user not found!`);
        }
        let sub = this._user.profile.sub;
        const token = this._user.access_token;
        await this.loadHubInfo();
        await this.loadHubNodes(token);
        await this.loadNewsFeeds(token);
        await this.loadAllUsers(token, sub);
        Logger.debug(`hub metadata loaded.`);
    }

    loadHubInfo = async () => {
        const response = await fetch('/api/hubInfo');
        if (response.ok) {
            this._hubInfo_ = await response.json();
            Logger.debug('Hub infomation loaded: ', this._hubInfo_);
        }
    }

    loadHubNodes = async (token) => {
        //Logger.debug('loading Hub nodes... ');
        const response = await fetch('/api/node?includeCloudQ=true', {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            let nodeResponse = await response.json();
            this._hubNodes_ = new Map(nodeResponse.hubNodes.map(n => ([n.id, n])));
            Logger.debug('Hub nodes loaded: ', this._hubNodes_);
        }
    }

    loadNewsFeeds = async (token) => {
        //        Logger.debug('loading Hub news feeds... ');
        const response = await fetch('/api/newsFeed', {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.ok) {
            let nodeResponse = await response.json();
            this._newsFeeds = new Map(nodeResponse.hubNodes.map(n => ([n.id, n])));
            Logger.debug('Hub news feeds  loaded: ', this._newsFeeds);
        }
    }

    loadAllUsers = async (token, sub) => {
        //        Logger.debug('loading users... ');
        const response = await fetch('/api/hubuser', {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
        //        Logger.debug(response);
        if (response.ok) {
            let userResponse = await response.json();
            Logger.debug(userResponse);
            this._allUsers_ = new Map(userResponse.users.map(u => {
                if (u.userGuid === sub) {
                    this._currentHubUser = u;
                }
                return ([u.id, u]);
            }));
            Logger.debug('all users loaded: ', this._allUsers_);
        }
    }
    getCreatorName(userId) {
        let name = "N/A";
        if (this._allUsers_) {
            let u = this._allUsers_.get(userId);
            if (u) {
                name = this.getNormalizedUsername(u);
            }
        }
        return name;
    }
    getCurrentHubUser = () => this._currentHubUser;
    getCurrentHubUsername = () => this.getNormalizedUsername(this._currentHubUser);
    getNormalizedUsername = (u) => {
        let name = u.userName;
        if (u.firstName) {
            name = u.firstName;
            if (u.lastName) {
                name = `${name} ${u.lastName}`;
            }
        }
        return name;
    }
    getNodeNameByUserId(userId) {
        //        Logger.debug(`getting organization name of user ${userId}`);
        let name = "N/A";
        Logger.debug(this._allUsers_);
        if (this._allUsers_) {
            let u = this._allUsers_.get(userId);
            if (u) {
                Logger.debug('user = ', u);
                name = this.getHubNodeName(u.hubNodeId);
            }
        }
        return name;
    }
    getNodeIdByUserGuid(userGuid) {
        //        Logger.debug(`getting organization name of user ${userId}`);
        let id = -1;
        if (this._allUsers_) {
            for (let [, v] of this._allUsers_) {
                //Logger.debug(v)
                if (v.userGuid === userGuid) {
                    return v.hubNodeId;
                }
            }
        }
        return id;
    }
    getNodeNameByUserGuid(/*userGuid*/) {
        //        Logger.debug(`getting organization name of user ${userId}`);
        const userGuid = this.getUserGuid()
        let name = "N/A";
        if (this._allUsers_) {
            for (let [, v] of this._allUsers_) {
                //Logger.debug(v)
                if (v.userGuid === userGuid) {
                    name = this.getHubNodeName(v.hubNodeId);
                    break;
                }
            }
        }
        return name;
    }
    getHubVersion() {
        let version = "dev_version";
        if (this._hubInfo_) {
            version = this._hubInfo_.version;
        }
        if (this._hubInfo_.hostEnvironment && this._hubInfo_.hostEnvironment !== "Production") {
            version += ` - ${this._hubInfo_.hostEnvironment}`;
        }
        return version;
    }

    getHubInfo() {
        return this._hubInfo_;
    }
    getHubNodeName(nodeId) {
        let name = "N/A";
        //        Logger.debug(`getting Hub node name of ${nodeId}`);
        //        Logger.debug('available nodes=', this._hubNodes_);
        if (this._hubNodes_) {
            let node = this._hubNodes_.get(nodeId);
            //            Logger.debug('node=', node);
            name = node ? node.name : "Hub Admin";
        }
        return name;
    }
    getHubNodes() {
        //        Logger.debug('getHubNodes from available nodes: ', this._hubNodes_);
        if (this._hubNodes_) {
            return Array.from(this._hubNodes_.values());
        }
        else {
            return null;
        }
    }

}

const authService = new AuthorizeService();
export default authService;


