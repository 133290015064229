import * as Logger from 'loglevel';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import Utilities, {UtilConstant} from 'hub-utilities'

//dispatcher and actions here:
//To sync logo between About and Side Navbar

const fetchLogoBegin = () => ({
    type: ActionType.FETCH_LOGO_BEGIN
});

const fetchLogoSuccess = payload => ({
    type: ActionType.FETCH_LOGO_SUCCESS,
    payload: payload
});

const fetchLogoFailure = error => ({
    type: ActionType.FETCH_LOGO_FAILURE,
    payload: error
});

const updateLogoBegin = () => ({
    type: ActionType.UPDATE_LOGO_BEGIN
});

const updateLogoSuccess = payload => ({
    type: ActionType.UPDATE_LOGO_SUCCESS,
    payload: payload
});

const updateLogoFailure = error => ({
    type: ActionType.UPDATE_LOGO_FAILURE,
    payload: error
});

export const mapLogoStateToProps = state => {
    Logger.debug(`map Logo props, state=`, state);
    return state.hubConfig.hubLogo;
};


const fetchLogo = async (dispatch, getState) => {
    dispatch(fetchLogoBegin());
    let token = await authService.getAccessTokenAsync();
    const response = await fetch(`/api/HubInfo/logo`, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    let resp = await response;
    if (resp.ok) {
        var respBlob = await resp.blob();
        // Then create a local URL for that image and print it 
        var logoURL = URL.createObjectURL(respBlob)
        Logger.debug(`response is:`, logoURL);
        dispatch(fetchLogoSuccess(logoURL));
    }
    else if (Utilities.detectBrowser() === UtilConstant.BrowserType.IE) { // for IE {
        //Logger.debug(`error is:`, respObj);
        dispatch(fetchLogoFailure({
            status:9000,
            statusText:"IE does not support customized icons. Please use Chrome or Firefox for more functionalities."
        }));
    }
    else {
        var respObj = await resp.json();
        Logger.debug(`error is:`, respObj);
        dispatch(fetchLogoFailure(respObj));
    }
}

export const mapLogoDispatcherToProps = {

    startLogoFetch:() => async (dispatch, getState) => {
       await fetchLogo(dispatch, getState);
    }, 

    startUpdateLogo:(displayName, ignoreNullValue, uploadedFile) => async (dispatch, getState) => {
        dispatch(updateLogoBegin());
        let formData = new FormData();
        //formData.append("hubName", displayName);
        //formData.append("ignoreNullValue", ignoreNullValue);
        formData.append("logoFile", uploadedFile);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/HubInfo/logo`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });

        let respObject = await response.json();
        if (response.ok) {
            Logger.debug(`response is: ${respObject}`);
            dispatch(updateLogoSuccess(respObject));
            await fetchLogo(dispatch, getState);
        }
        else {
            Logger.debug(`error is: ${respObject.error}`);
            dispatch(updateLogoFailure(respObject));

        }
    }
};