import React from 'react';
import Utilities from 'hub-utilities';
import Clock from 'react-clock';

class MyClock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date()
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(
      () => this.tick(),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    var date = new Date();
    var hours = date.getHours();
    this.props.setIsClockHourOneDigit((hours > 0 && hours < 10) || (hours > 12 && hours < 22));
    this.setState({
      time: date//new Date().toLocaleTimeString()
    });
  }
  render() {
    return (
      <>
        {!!this.props.shouldDisplayClock ? <Clock value={this.state.time} size={25} /> : <></>}
        {!!this.props.shouldDisplayText ?
          <>
            <span>{
              Utilities.formatDate(this.state.time, `${!!this.props.isFullDateFormat ? "MMM dd, yyyy h:mm" : "h:mm"}${!!this.props.withSeconds ? ":ss" : ""}`, false, false)
            }</span><span className="TT">{
              Utilities.formatDate(this.state.time, `TT`, false, false)
            }</span>
          </>
          :
          <></>}
      </>
    );
  }
}

export default MyClock