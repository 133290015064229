import React from "react";
import { Form, Button, Row, Col } from 'react-bootstrap'
import { TextField } from '@material-ui/core';
import * as Logger from 'loglevel';
import Utilities from 'hub-utilities';
import Cookies from 'js-cookie';
import * as HubConstant from 'util/HubConstant';

import { faListAlt } from '@fortawesome/free-solid-svg-icons'

import { MultiValsSelector } from 'hub-dashboard-framework';

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

class ElementForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            title: "",
            text: "",
            keywords: "",
            fileName: null,
            formError: null, //was fileUploadStatus
            isStatusEnabled: false,
            file: null,
            isCreatingMedia: false,
            isUrgent: false,
            editorActiveIndex: -1
        };
    }

    async componentWillMount() {
        var cachedTitle = await Cookies.get('CreateElemTitle');

        var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
        var cachedText = "";
        if (!!numOfBodyCookies) {
            for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                cachedText += await Cookies.get(`CreateElemBody${i}`);
            }
        }

        this.setState({
            title: !!cachedTitle ? cachedTitle : "",
            text: !!cachedText ? cachedText : ""
        })
    }

    componentDidMount() {
        //Add a timeout here
        setTimeout(function () {
            // this.initialInputFocusRef.current.focus();
            document.getElementById("elementTitle").focus();
        }, 1)
    }

    setEditorActiveIndex = (rowIndex) => {
        if (rowIndex === this.state.editorActiveIndex) {
            //Set editing row to none
            this.setState({ editorActiveIndex: -1 });
        } else {
            this.setState({ editorActiveIndex: rowIndex });
        }
    }


    //Each cookie has 4096 byte limit (around 3900 characters)
    cacheCreateElemInfo(stateName, val) {
        if (stateName === 'title') {
            Cookies.set(`CreateElemTitle`, val);
        }
        else if (stateName === 'text') {
            var infoArrs = Utilities.chunkInfoOnExceedByteLimit(val, 4096);
            for (var i = 0; i < infoArrs.length; i++) {
                Cookies.set(`CreateElemBody${i}`, infoArrs[i]);
            }
            Cookies.set(`NumOfBodyCookies`, infoArrs.length);
        }
    }

    async clearCookies() {
        var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
        if (!!numOfBodyCookies) {
            for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                Cookies.set(`CreateElemBody${i}`, "");
            }
        }

        Cookies.set(`CreateElemTitle`, "");
        Cookies.set('NumOfBodyCookies', "");
    }

    createElement = async () => {
        this.setState({ isCreatingMedia: true }, async () => {
            this.setState({ ...this.state, formError: null, uploading: true });

            const response = await this.props.createNewsItemWithMetadata({
                name: this.state.title,
                text: this.state.text,
                priorityLevel: this.state.isUrgent ? 5 : 7,
                keyword: this.state.keywords
            }, HubConstant.HUB_OBJECT_ELEMENT, this.state.file);


            if (response.ok) {
                let respObj = await response.json()
                Logger.debug('element created with: ', respObj);
                let elementId = respObj.hubObject != null ? respObj.hubObject.id : -1;

                if (!!this.props.onElementCreated) {
                    this.props.onElementCreated(elementId);
                }
                if (!!this.props.onFormSubmitted) {
                    this.props.onFormSubmitted();
                }
                this.clearCookies();
            }
            else {
                Logger.debug('creating element failed, response=', response);
                let respText = await response.text();
                let newSts = ({
                    ...this.state,
                    uploading: false,
                    formError: `Creating element failed: ${respText}`,
                });
                this.setState(newSts);
                this.setState({ isCreatingMedia: false });
            }
        })
    }

    validate = () => (this.form.current.reportValidity());

    submitHandler = event => {
        Logger.debug('element from submitHander, event=', event);
        event.preventDefault();
        event.target.className += " was-validated";
        if (this.validate() && !this.state.isCreatingMedia) {
            this.createElement();
        }
    };

    toggleStatus = () => {
        let newSts = {
            ...this.state,
            isStatusEnabled: !this.state.isStatusEnabled,
        }
        this.setState(newSts);
    }

    changeHandler = event => {
        this.cacheCreateElemInfo(event.target.name, event.target.value);
        let newSts = {
            ...this.state,
            [event.target.name]: event.target.value,
        }
        this.setState(newSts);
    };

    fileSelectorHandler = event => {
        this.props.setIsBrowseWindowOpen(false);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);
        let newSts = {
            ...this.state,
            fileName: selectedFilename,
            formStatus: null,
            file: event.target.files[0],
            uploading: false,
        }
        this.setState(newSts);
    };

    setCategoriesToSelect = (newArr) => {
        var categories = this.props.getCategory();
        if (!!categories) {
            this.props.onLocalResetNewsItemMetadata(categories.fieldId, newArr.map(val => {
                return { index: val.id, value: val.name }
            }));
        }
    }

    renderCategory() {
        var availableCategories = this.props.getCategory();
        var chosenCategoriesState = !!this.props.storyMetadata ? this.props.storyMetadata.find(mdata => mdata.fieldId === 1) : null;
        return (
            !!availableCategories && !!chosenCategoriesState ?
                <>
                    {/* Change to set to remove duplicates */}
                    <div className="multiValsSelectorWrapper">
                        <MultiValsSelector
                            id={0}
                            title={availableCategories.fieldName}
                            toggleIcon={faListAlt}
                            availableVals={availableCategories.vocabulary.map(vocab => { return { id: vocab.index, name: vocab.keyword } })}
                            selectedVals={chosenCategoriesState.values.map(value => { return { id: value.index, name: value.value } })}
                            setSelectedVals={this.setCategoriesToSelect}
                        />
                    </div>

                    {/* {chosenCategoriesState.values.map(val => {
                        Logger.debug(val);
                        return (
                            <div name="" className="categoryBadge">
                                {val.value}<FontAwesomeIcon className="deleteIcon" icon={faTrash} onClick={() => this.props.onDeleteSelectedVal(chosenCategoriesState.fieldId, val.index)} />
                            </div>
                        );
                    })}
                    <RowEditor
                        title="Add Category"
                        icon={faPlusCircle}
                        mediaEditInfo={[
                            availableCategories.isControlledVocabulary ? { type: DataEditorType.DROP_DOWN, dataType: availableCategories.dataType, dropdownVal: availableCategories.vocabulary, key: "keyword" } :
                                availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.TEXT || availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.KEYWORD ?
                                    { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "text" } :
                                    availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.DECIMAL ?
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "number" } :
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.DATE_TIME_FIELD }
                        ]}
                        editorId={availableCategories.fieldId}
                        objectType={this.props.objectType}
                        editingRowIndex={this.state.editorActiveIndex}
                        setEditingRow={this.setEditorActiveIndex}
                        onSubmitRowEditor={this.props.onSubmitRowEditor}
                        disablePopup={true}
                    /> */}
                </>
                : <></>
        );
    }

    render() {
        const collapsibleLoaderOverride = css`
        position: relative;
        margin-top: 5px !important;
    `;

        let fileInputLabel = this.state.fileName ? this.state.fileName : "Select Media";
        let uploadPrepend = this.state.uploading ?
            <ClipLoader
                size={20}
                color={"#f69b1d"}
                css={collapsibleLoaderOverride}
            />
            : (<></>);
        return (
            <div>
                <Form ref={this.form} className="needs-validation ElementDetailsForm" onSubmit={this.submitHandler} >
                    <Row className="ImportantInfoRow">
                        <Col xs="10">
                            <TextField
                                value={this.state.title}
                                name="title"
                                onChange={this.changeHandler}
                                type="text"
                                id="elementTitle"
                                className="form-control"
                                label="Media Title"
                                required
                            />
                            <div className="invalid-feedback">
                                Please provide a title for the element.
                            </div>
                        </Col>
                        <Col xs="2" className="LargeCheckbox">
                            <div className="formTitle">Urgent?</div>
                            <label
                                className='checkboxContainer'
                                style={{ pointerEvents: 'none' }}
                            >
                                <input
                                    id={`UrgencyCheckbox`}
                                    name={`UrgencyCheckbox`}
                                    className="filterGroupChild"
                                    label=""
                                    type="checkbox"
                                    onChange={() => {
                                        var newUrgency = !this.state.isUrgent;
                                        this.setState({ isUrgent: newUrgency });
                                    }}
                                    // onChange={(e) => this.handleCheckboxChange(e, availableFilterObjs, objsToFilter, null)}
                                    checked={this.state.isUrgent}
                                />
                                <span className='checkmark large' />
                            </label>
                        </Col>
                    </Row>
                    <div className="valid-feedback"></div>
                    <textarea
                        value={this.state.text}
                        name="text"
                        onChange={this.changeHandler}
                        id="elementSummary"
                        className="form-control"
                        placeholder="Summary"
                        rows="6"
                    />
                    {this.renderCategory()}
                    <TextField
                        value={this.state.keywords}
                        name="keywords"
                        onChange={this.changeHandler}
                        type="text"
                        id="elementKeyword"
                        className="form-control elementKeyword"
                        label="Tags"
                    />
                    <div className="input-group">
                        <div className="input-group-prepend"> {uploadPrepend}</div>
                        <div className="custom-file">
                            <input
                                type="file"
                                onChange={this.fileSelectorHandler}
                                onClick={() => { Logger.debug("opened"); this.props.setIsBrowseWindowOpen(true); }}
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                required
                            />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">{fileInputLabel}</label>
                        </div>
                    </div>
                    {
                        !!this.state.formError ?
                            <div className="errorMsg">
                                {this.state.formError}
                            </div>
                            :
                            <></>
                    }
                    <Button color="light" type="submit" size="sm" className="float-right mr-0">Create</Button>
                </Form >
            </div >
        );
    }
}

export default ElementForm;