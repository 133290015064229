import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import 'styles/widgets/ProductionGroup.scss';
import { UserTable } from "./UserTable";

export function FolderSecurityGroupForm(props) {
    const { payload: folderSecurityGroup = null, users = [] } = props;
    const formRef = useRef(null);

    const [groupName, setGroupName] = useState((!!folderSecurityGroup && folderSecurityGroup.Group && folderSecurityGroup.Group.val) || '');
    const [description, setDescription] = useState((!!folderSecurityGroup && folderSecurityGroup.Description && folderSecurityGroup.Description.val) || '');
    const [members, setMembers] = useState((!!folderSecurityGroup && folderSecurityGroup.Members && folderSecurityGroup.Members.formVal) || []);
    const [usersState, setUsersState] = useState(() => (!!folderSecurityGroup && folderSecurityGroup.Members ? addAttToUserData(folderSecurityGroup.Members.formVal, users) : users));
    const [changeNotifier, setChangeNotifier] = useState(false);
    const [isFocusingMembersUI, setIsFocusingMembersUI] = useState(false);
    const [isStatusEnabled, setIsStatusEnabled] = useState(false);

    const folderSecurityGroupId = !!folderSecurityGroup && folderSecurityGroup.id || -1;

    useEffect(() => {
        if (folderSecurityGroupId === -1) {
            initializeUserDataIfNull();
        }
    }, [folderSecurityGroupId]);

    useEffect(() => {
        if (!!props.error) {
            setIsStatusEnabled(true);
        }
    }, [props.error]);

    function initializeUserDataIfNull() {
        const usersWithChecked = users.map(user => ({ ...user, isChecked: false }));
        setUsersState(usersWithChecked);
        setMembers(usersWithChecked);
    }

    function addAttToUserData(members, userData) {
        return userData.map(user => ({
            ...user,
            isChecked: members.some(member => member.id === user.id),
        }));
    }

    function onChangeSetUserData(userId) {
        const newMembers = members.map(member => {
            if (member.id === userId) {
                return { ...member, isChecked: !member.isChecked };
            }
            return member;
        });
        setMembers(newMembers);
        setChangeNotifier(!changeNotifier);
    }

    function onUserCheck(userId) {
        const updatedUsers = usersState.map(user => {
            if (user.id === userId) {
                return { ...user, isChecked: !user.isChecked };
            }
            return user;
        });
        setUsersState(updatedUsers);
        setChangeNotifier(!changeNotifier);
    }

    function onChange(event) {
        const { name, value } = event.target;
        if (name === 'groupName') {
            setGroupName(value);
        } else if (name === 'description') {
            setDescription(value);
        }
    }

    function validateForm() {
        return formRef.current.checkValidity();
    }

    async function submitHandler(event) {
        event.preventDefault();
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }
        if (validateForm()) {
            await saveFolderSecurityGroup();
        }
    }

    async function createFolderSecurityGroup() {
        const membersToAdd = usersState.filter(member => member.isChecked);
        const request = { groupName, description };
        await props.addFolderSecurityGroup(request, membersToAdd);
        props.onFormClosed();
    }

    async function updateExistingFolderSecurityGroup() {
        const oldMembers = members;
        const newMembers = usersState.filter(member => member.isChecked);
        const membersToAdd = newMembers.filter(newMember => !oldMembers.some(oldMember => oldMember.id === newMember.id));
        const membersToDelete = oldMembers.filter(oldMember => !newMembers.some(newMember => oldMember.id === newMember.id));

        const request = { groupName, description };
        await props.updateFolderSecurityGroup(folderSecurityGroupId, request, membersToAdd, membersToDelete);
        props.onFormClosed();
    }

    async function saveFolderSecurityGroup() {
        if (folderSecurityGroupId > -1) {
            await updateExistingFolderSecurityGroup();
        } else {
            await createFolderSecurityGroup();
        }
    }

    function renderStatus() {
        if (!!props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">Error: {props.error}</p></Col></Row>;
        } else if (!!props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{props.success}</p></Col></Row>;
        }
    }

    const isUpdateFolderSecurityGroup = !!folderSecurityGroup;

    return (
        <div className="HubUserForm HubFolderSecurityGroupForm">
            <Form ref={formRef} className="needs-validation burli-folderSecurityGroup-form mt-3" onSubmit={submitHandler} noValidate>
                <Row>
                    <Col size="4" className="HubNameRow">
                        <TextField
                            group
                            value={groupName}
                            name="groupName"
                            onChange={onChange}
                            type="text"
                            id="userformPassword1"
                            label="GROUP NAME"
                            required
                        />
                        <div className="invalid-feedback">This field cannot be empty!</div>
                    </Col>
                    <Col size="8">
                        <TextField
                            group
                            value={description}
                            name="description"
                            onChange={onChange}
                            type="text"
                            id="userformPassword2"
                            label="DESCRIPTION"
                            required
                        />
                        <div className="invalid-feedback">This field cannot be empty!</div>
                    </Col>
                </Row>
                <Row className="TitleRow">
                    <Col size="4">
                        <h5 className={isFocusingMembersUI ? "focused" : ""}>MEMBERS</h5>
                    </Col>
                    <Col size="4" />
                </Row>
                <div className="UserTable"
                    onMouseEnter={() => setIsFocusingMembersUI(true)}
                    onMouseLeave={() => setIsFocusingMembersUI(false)}
                >
                    <UserTable users={usersState} onUserCheck={onUserCheck} />
                </div>
                <Row className="SelectionStatus">
                    <Col xs="6">
                        <div className="statusText">
                            {usersState.filter(user => user.isChecked).length} of {users.length} Users included
                        </div>
                    </Col>
                    <Col xs="6" className="submitBtnWrapper">
                        <Button color="light" type="submit" size="sm" className="mr-0">
                            {isUpdateFolderSecurityGroup ? "Save and Close" : "Create and Close"}
                        </Button>
                    </Col>
                </Row>
                {isStatusEnabled ? renderStatus() : <></>}
            </Form>
        </div>
    );
}

const mapStateToProps = state => ({
    users: state.hubConfig.userConfig.users,
    success: state.hubObjectContent.success,
    error: state.hubObjectContent.error,
    folderSecurityGroups: state.hubObjectContent.folderSecurityGroups
});

export default withRouter(connect(
    mapStateToProps,
    dispatch => bindActionCreators({ ...mapDispatchToHubObjectBoardProps }, dispatch),
)(FolderSecurityGroupForm));
