import React, { Fragment, useRef, useState } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from 'store/actions/HubConfigActions';

function HubNodeForm(props) {
    // constructor(props) {
    //     const { payload: nodeData } = props;
    //     super(props);
    //     this.form = React.createRef();
    //     this.state = {
    //         name: nodeData.name,
    //         description: nodeData.description
    //     };
    //     this.nodeId = nodeData.id
    // }
    const { payload: nodeData } = props;
    const form = useRef(null);
    const nodeId = nodeData.id
    const [state, setState] = useState({
        name: !!nodeData.name ? nodeData.name : null,
        description: nodeData.description
    })


    const onChange = (event) => {

        setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    async function submitHandler(event) {
        Logger.debug('props: ', props)

        // event is only passing the edited info and not the state
        event.preventDefault()
        var updateNodeRequest = {
            name: state.name,
            description: state.description,
        }

        await props.updateNode(nodeId, updateNodeRequest)

        if (props.success) {
            Logger.debug(`Updated Node Success.`);
            props.onFormSubmitted();
        }
    }

    return (
        <Form
            ref={form}
            onSubmit={submitHandler}
        >
            <Row>
                <Col xs={12}>
                    <TextField
                        value={state.name}
                        name='name'
                        onChange={onChange}
                        type='text'
                        id='name'
                        label='Name'
                        required
                    >
                    </TextField>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <TextField
                        value={state.description}
                        name='description'
                        onChange={onChange}
                        type='text'
                        id='description'
                        label='Description'
                    >
                    </TextField>
                </Col>
            </Row>
            {props.error ? (
                <div className='errorMsg' style={{ textAlign: "center" }}>{props.error}</div>
            ) : (
                    <Fragment />
                )}
            <Button
                color='light'
                type='submit'
                size='sm'
                className='float-right mr-0'
            >
                Save
            </Button>
        </Form>
    );
    
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(mapDispatchToProps, dispatch)
)(HubNodeForm);