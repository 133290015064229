import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import Cookies from 'js-cookie';

//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import MetadataPerNewsItem from "./MetadataPerNewsItem";

//const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);

// Metadata structure:
// "fieldId": 10006,
// "type": 0,
// "dataType": 3,
// "fieldName": "tester",
// "description": "testerr",
// "isControlledVocabulary": true,
// "vocabulary": [
//   {
//     "index": 1,
//     "keyword": "testerr"
//   }
// ]

function MetadataPerNewsItemForm(props) {

    const { payload: newsItem } = props;
    const [newsItemId, setNewsItemId ] = useState(!!newsItem ? newsItem.id : null);

    const form = useRef(null);

    const [metadata, setMetadata] = useState(props.metadata);
    const [numOfMetadataPerRow, setNumOfMetadataPerRow] = useState(3);
    const [formError, setFormError] = useState(undefined);
    const [isStatusEnabled, setIsStatusEnabled] = useState(false);
    const [changeNotifier, setChangeNotifier] = useState(false);
    const [isFocusingMembersUI, setIsFocusingMembersUI] = useState(false);
    const [isCreatingMetadata, setIsCreatingMetadata] = useState(false);
    const [focusedFieldIndex, setFocusedFieldIndex] = useState(-1);
    const [addCounter, setAddCounter] = useState(1);
    const [hasLoaded, setHasLoaded] = useState(false)
    

    

    useEffect(() => {
        async function loadNewsItemMetadata() {
            if (!hasLoaded) {
                props.setformWidthMultiplier(numOfMetadataPerRow);
                await initializeNewsItemMetaData();
                setHasLoaded(true);
            }
            
        }
        loadNewsItemMetadata();

    }, []);

    async function initializeNewsItemMetaData() {

        if (!!props.isIntegratedForm) { await props.initializeNewsItemMetadata(); } else {
            //need to fetch nodes, for the ones without group
            //fetch newsobject metadata if it's null:
            await props.getMetadata();
            Logger.debug(newsItemId);
            //if (!(!!this.props.objectList.find(newsObj => newsObj.id == this.newsItemId).metadata)) {
            if (newsItemId > -1) await props.getNewsItemMetadata(newsItemId);
            //}
            setTimeout(() => {
                var newMetadataList = [];
                props.metadata.forEach(metadata => {
                    var metadataFieldInNewsObj = null;
                    var newsItem = props.objectList.find(newsObj => newsObj.id === newsItemId);
                    Logger.debug('newsItem: ', newsItem.metadata)
                    if (!!newsItem && newsItem.metadata) metadataFieldInNewsObj = newsItem.metadata.find(newsObjMetadata => newsObjMetadata.fieldId === metadata.fieldId);

                    if (!!metadataFieldInNewsObj) {
                        //if metadataField is found in objectList, use metadataFieldInNewsObj
                        Logger.debug(props.metadata);
                        var metadataFieldInNewsObjWithCompleteMetadata = JSON.parse(JSON.stringify(metadataFieldInNewsObj)); //remove object ref
                        if (metadata.isControlledVocabulary) metadataFieldInNewsObjWithCompleteMetadata["availableControlledVocabularies"] = metadata.vocabulary;
                        metadataFieldInNewsObjWithCompleteMetadata["isControlledVocabulary"] = metadata.isControlledVocabulary;
                        metadataFieldInNewsObjWithCompleteMetadata.values = metadataFieldInNewsObjWithCompleteMetadata.values.map(vocab => { return { ...vocab, isChecked: false } }); //for delete selection
                        newMetadataList.push(metadataFieldInNewsObjWithCompleteMetadata);
                    } else {
                        newMetadataList.push({
                            fieldId: metadata.fieldId,
                            dataType: metadata.dataType,
                            fieldName: metadata.fieldName,
                            values: [],
                            ...metadata.isControlledVocabulary && { availableControlledVocabularies: metadata.vocabulary },
                            isControlledVocabulary: metadata.isControlledVocabulary
                        });
                    }
                });
                Logger.debug(newMetadataList);
                var newChangeNotifier = !changeNotifier;
                setMetadata(newMetadataList, () => setChangeNotifier(newChangeNotifier));
            }, 200);
    }
    }

    const onSelectValue = (fieldId, valIndex, isSelected) => {
        if (!!props.isIntegratedForm) { props.onSelectValue(fieldId, valIndex, isSelected); } else {
            var newMetadata = null;
            Logger.debug(fieldId); Logger.debug(valIndex);
            if (isSelected !== undefined) {
                newMetadata = metadata;
                Logger.debug(newMetadata)
                newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isSelected;
                //newMetadata.forEach(dataField => dataField.values.forEach(val => {Logger.debug(val.isChecked);val.isChecked = isSelected}));
            } else {
                //Toggle on/off:
                var isChecked = metadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked;
                Logger.debug(isChecked);
                isChecked = !isChecked;
                Logger.debug(metadata);

                newMetadata = metadata;
                newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isChecked;
            }

            var newChangeNotifier = !changeNotifier;
            setChangeNotifier(newChangeNotifier);
        }
    }

    const onDeleteSelectedValues = () => {
        if (!!props.isIntegratedForm) { props.onDeleteSelectedValues() } else {
            metadata.forEach(dataField => {
                dataField.values = dataField.values.filter(val => !val.isChecked);
            });
            var newChangeNotifier = !changeNotifier;
            setChangeNotifier(newChangeNotifier);
        }
    }

    const onDeleteSelectedVal = (fieldId, valIndex) => {
        if (!!props.isIntegratedForm) { props.onDeleteSelectedVal(fieldId, valIndex) } else {
            Logger.debug(fieldId);
            Logger.debug(valIndex);

            var foundVals = metadata.find(dataField => dataField.fieldId === fieldId).values;
            foundVals = foundVals.filter(val => val.index !== valIndex);

            //Assign to state:
            metadata.find(dataField => dataField.fieldId === fieldId).values = foundVals;

            Logger.debug(foundVals);

            var newChangeNotifier = !changeNotifier;
            setChangeNotifier(newChangeNotifier);
        }
    }

    const onChange = (val, index) => {
        if (!!props.isIntegratedForm) { props.onChange(val, index) } else {
            Logger.debug(val);
            Logger.debug(index);
            var newMetadata = JSON.parse(JSON.stringify(metadata));
            newMetadata[index].fieldVal = val;
            Logger.debug(newMetadata);
            setMetadata(newMetadata)
        }
    }

    const validateForm = () => (form.current.checkValidity());

    const submitHandler = async (event) => {
        event.preventDefault();
        Logger.debug('Saving metadata, event=', event);
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }
        if (validateForm()) {
            await onUpdateNewsItemMetadata();
        }
    };

    async function onUpdateNewsItemMetadata() {
        if (!!props.isIntegratedForm) { await props.onUpdateNewsItemMetadata() } else {
            var body = {
                metadata: metadata.filter(data => data.values.length > 0).map(data => {
                    return {
                        fieldId: data.fieldId,
                        values: data.values.map(value => value.value)
                    }
                })
            };
            await props.updateNewsItemMetadata(newsItemId, body);
            await props.getNewsItemMetadata(newsItemId);

            Logger.debug(props.error);
            if (!!props.success) {
                setIsStatusEnabled(true);
                props.onFormClosed();

            } else if (!!props.error) {           
                setIsStatusEnabled(true);
            }
        }
    }

    const onLocalAddNewsItemMetadata = (id, value) => {
        if (!!props.isIntegratedForm) { props.onLocalAddNewsItemMetadata(id, value) } else {
            Logger.debug(value);
            Logger.debug(id);
            var newMetadata = metadata;
            newMetadata.forEach(dataVal => {
                Logger.debug(dataVal)
                if (id === dataVal.fieldId)
                    dataVal.values.push({ index: -addCounter, value: value, isChecked: false });
            });
            Logger.debug(newMetadata)
            var newAddCounter = addCounter + 1;
            setMetadata(newMetadata);
            setAddCounter(newAddCounter);

        }
    }

    function renderStatus() {
        if (!!props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">Error {props.error.status}: {props.error.statusText}</p></Col></Row>
        } else if (!!props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{props.success}</p></Col></Row>
        }
    }

    let isUpdateMetadata = !!newsItem.id;
    return (
        <div className="HubUserForm HubNodeGroupForm">
                <Form ref={form} className="needs-validation burli-metadata-form mt-3" onSubmit={submitHandler} noValidate >
                    <Row>
                        <Col size="12" className="HubNameRow">
                            <MetadataPerNewsItem
                                newsItemType={props.payload.type}
                                isForm={true}
                                formData={!!props.isIntegratedForm && !!props.integratedMetadata ? props.integratedMetadata.filter(mdata => mdata.fieldId !== 1) : metadata}
                                changeNotifier={!!props.isIntegratedForm ? props.integratedChangeNotifier : changeNotifier}
                                onLocalAddNewsItemMetadata={onLocalAddNewsItemMetadata}
                                onSelectValue={onSelectValue}
                                onDeleteSelectedValues={onDeleteSelectedValues}
                                onDeleteSelectedVal={onDeleteSelectedVal}
                                isNotificationAllowed={props.isNotificationAllowed}
                                arrOfNewData={props.arrOfNewData}
                            >
                            </MetadataPerNewsItem>
                        </Col>
                    </Row>
                 {isUpdateMetadata ?
                        <>{isStatusEnabled ?
                            <>
                                {renderStatus()}
                            </> : <></>}
                         <Row className="SelectionStatus">
                                <Col size="12">
                                    <Button color="light" type="submit" size="sm" className={`float-right mr-0`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                </Col>
                            </Row>
                        </>
                        : <></>
                    }
                </Form >
        </div >
    );

}

// export default withRouter(connect(
//     mapMetadataStateToProps,
//     dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch)
// )(MetadataPerNewsItemForm));

const mapMetadataAndObjectBoardStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        metadata: state.hubConfig.hubMetadata.metaData,
        success: state.hubConfig.hubMetadata.success,
        error: state.hubConfig.hubMetadata.error
    }
}

export default withRouter(connect(
    mapMetadataAndObjectBoardStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(MetadataPerNewsItemForm));