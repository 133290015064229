import React from 'react';
import * as Logger from 'loglevel';
import { Modal } from 'react-bootstrap';
import 'styles/widgets/HubForm.scss';

export const WIDGET_ANIMATION_STATE = Object.freeze({
    "NONE": 0,
    "EXPANDING": 1,
    "COLLAPSING": 2

});

class HubForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formWidthMultiplier: 0,
            isBrowseWindowOpen: false,
            widgetAnimState: WIDGET_ANIMATION_STATE.NONE,
            isHorizontallyExpanded: false
        }
        //Cached variables allow the form to collapse correctly!
        this.cachedPayload = null;
        this.cachedTitle = "";
        this.cachedForm = React.Component;
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onScreenSizeShrink);
    }

    onScreenSizeShrink = () => {
        if (window.innerWidth <= 1007 && (this.state.isHorizontallyExpanded || this.state.widgetAnimState !== WIDGET_ANIMATION_STATE.NONE)) {
            this.setState({ widgetAnimState: WIDGET_ANIMATION_STATE.NONE }, () => this.setState({ isHorizontallyExpanded: false }));
        }
    }

    setIsBrowseWindowOpen = (isOpen) => {
        this.setState({ isBrowseWindowOpen: isOpen }, () => Logger.debug(isOpen));
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKey);
        window.addEventListener('resize', this.onScreenSizeShrink);
    }

    handleKey = e => {
        if (e.keyCode === 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            if (!!this.props.formDesc)
                //disable Control S save functionality:
                e.preventDefault();
        }
        if (e.keyCode === 27) {
            //if (!!this.props.formDesc)
            //Prevent: Pressing Esc button when adding media closes the New Story window:
            Logger.debug("yy")

            e.preventDefault();
            return false;
        }
    }

    renderModalOnFormDesc() {
        const { formTitle: Title, component: Form, payload } = this.props.formDesc;
        this.cachedTitle = Title;
        this.cachedForm = Form;
        this.cachedPayload = payload;

        return this.renderModal(Title, Form, payload);
    }

    setformWidthMultiplier = (width) => {
        this.setState({ formWidthMultiplier: width });
    }

    renderModal(_title, _form, _payload) {
        let CustomizedForm = _form;
        return (
            <>
                <Modal.Header
                    className={`pt-2 pb-2 elegant-color lighten-2`}
                    toggle={() => this.props.onFormClosed()}>
                    {_title}
                </Modal.Header>
                <Modal.Body className="p-2" >
                    <CustomizedForm
                        setformWidthMultiplier={this.setformWidthMultiplier}
                        setIsBrowseWindowOpen={this.setIsBrowseWindowOpen}
                        onFormSubmitted={this.props.onFormSubmitted}
                        payload={_payload}
                        onFormClosed={this.props.onFormClosed}
                        horizontalCollapse={this.horizontalCollapse}
                        widgetAnimState={this.state.widgetAnimState}
                        isHorizontallyExpanded={this.state.isHorizontallyExpanded}
                        isNotificationAllowed={this.props.isNotificationAllowed}
                        arrOfNewData={this.props.arrOfNewData} />
                </Modal.Body>
            </>
        )
    }

    horizontalCollapse = () => {
        if (this.state.widgetAnimState === WIDGET_ANIMATION_STATE.NONE) {
            if (!this.state.isHorizontallyExpanded) {
                this.setState(
                    { widgetAnimState: WIDGET_ANIMATION_STATE.EXPANDING },
                    () => {
                        setTimeout(() => {
                            if (window.innerWidth > 1007)
                                this.setState({ widgetAnimState: WIDGET_ANIMATION_STATE.NONE, isHorizontallyExpanded: true })
                        }, 1000)
                    }
                );
            } else {
                this.setState(
                    { widgetAnimState: WIDGET_ANIMATION_STATE.COLLAPSING },
                    () => {
                        setTimeout(() => {
                            if (window.innerWidth > 1007)
                                this.setState({ widgetAnimState: WIDGET_ANIMATION_STATE.NONE, isHorizontallyExpanded: false })
                        }, 1000)
                    }
                );
            }
        };
    }

    render() {
        return (
            <div>
                <Modal
                    className={`${(!!this.props.formDesc) ? this.props.formDesc.formTitle.replace(/ /g, '') : this.cachedTitle.replace(/ /g, '')} ${
                        this.state.formWidthMultiplier > 0 ? `width${this.state.formWidthMultiplier}` : ""
                        } ${
                        this.state.widgetAnimState === WIDGET_ANIMATION_STATE.EXPANDING ? "expanding" :
                            this.state.widgetAnimState === WIDGET_ANIMATION_STATE.COLLAPSING ? "collapsing" :
                                ""
                        } ${
                        this.state.isHorizontallyExpanded ? "expanded" : "collapsed"
                        }`}
                    {...this.props}
                    isOpen={!!this.props.formDesc}
                    data-keyboard="false"
                    modalOptions={{ dismissible: false }}
                    handleEscape={() => Logger.debug("ESCAPED")}
                    toggle={() => this.props.onFormClosed()} >
                    {(!!this.props.formDesc) ? this.renderModalOnFormDesc() : this.renderModal(this.cachedTitle, this.cachedForm, this.cachedPayload)}
                </Modal>
            </div>
        );
    }
}

export default HubForm;