/*jshint loopfunc: true */
import * as Logger from 'loglevel';
import Utilities, {UtilConstant} from 'hub-utilities'
import memoize from 'memoize-one';
import * as _ from 'lodash'
import * as HubConstant from 'util/HubConstant';

export const SortOrder = Object.freeze({
    "DEFAULT": 0,
    "ASCENDING": 1, //Whole_col_right takes up the column in the right hand side, by 30%
    "DESCENDING": 2 //Paragraph takes up the whole 2 bottom rows
});

var _keyToSort = "";
var _order = SortOrder.DEFAULT;
var _arrOfTakenOutModifiedRows = [];

//sortConfig: {key: null, order: SortOrder.DEFAULT}`
export const sort = (data, keyToSort, sortConfig, arrOfNewFirstRows, arrOfNewRows, arrOfJustModifiedRows, arrOfModifiedRows) => {
    // Key is sometimes null?  Perhaps the onClick event handler cannot return the key on-time with its async nature?
    // so have to check key isn't null here to solve the undefined key bug

    if (!!keyToSort) {
        _keyToSort = keyToSort;
        _order = sortConfig.order;

        var sortedData = []

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < arrOfNewFirstRows.length; j++) {
                if (data[i].id === arrOfNewFirstRows[j].id) {
                    sortedData.push(data[i]);
                }
            }
        }
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < arrOfNewRows.length; j++) {
                if (data[i].id === arrOfNewRows[j].id) {
                    if (sortedData.indexOf(sortedDataObj => sortedDataObj.id === data[i].id) === -1) {
                        sortedData.push(data[i]);
                    }
                }
            }
        }

        var dataToBeSorted = data.filter((item) => { return sortedData.indexOf(item) === -1 });

        if (arrOfJustModifiedRows.length > 0 || arrOfModifiedRows.length > 0) {
            //Then, remove elemes that are in modifiedRows and create an array of {index: n, modifiedRowObj }
            //Data fetched is before sort, so to properly sort the previously sorted index of the edited row
            //Has to be fetched
            for (let i = 0; i < dataToBeSorted.length; i++) {
                // eslint-disable-next-line
                var index1 = arrOfJustModifiedRows.findIndex(elem => !!elem.val && elem.val.id === dataToBeSorted[i].id);
                // eslint-disable-next-line
                var index2 = arrOfModifiedRows.findIndex(elem => !!elem.val && elem.val.id === dataToBeSorted[i].id);
                if (index1 !== -1 || index2 !== -1) {
                    if (index1 !== -1) _arrOfTakenOutModifiedRows.push({ index: arrOfJustModifiedRows[index1].index, val: Object.assign({}, dataToBeSorted[i]) });
                    if (index2 !== -1) _arrOfTakenOutModifiedRows.push({ index: arrOfModifiedRows[index2].index, val: Object.assign({}, dataToBeSorted[i]) });
                    dataToBeSorted[i] = null;
                }
            }
        }

        dataToBeSorted = dataToBeSorted.filter(elem => elem != null);
        dataToBeSorted.sort(compare);

        for (let i = 0; i < _arrOfTakenOutModifiedRows.length; i++) {
            dataToBeSorted.splice(_arrOfTakenOutModifiedRows[i].index, 0, _arrOfTakenOutModifiedRows[i].val);
        }
        _arrOfTakenOutModifiedRows = [];

        return sortedData.concat(dataToBeSorted)
    } else {
        return data;
    }
};

const getCompareStr = (val) => {
    if (typeof val === 'string') {
        return val.toUpperCase();
    } else if (typeof val === 'object') {
        if (val.type === 'a') {
            return (!!val.props.href) ? val.props.href.toUpperCase() : "";
        }
    }
    Logger.error("Type of data is not supported by sort!");
    return "";
}

const compare = (a, b) => {
    //Compare the date here:
    const valA = (!!a[_keyToSort]["date"]) ? getCompareStr(a[_keyToSort]["date"]) : getCompareStr(a[_keyToSort]["val"]);
    const valB = (!!b[_keyToSort]["date"]) ? getCompareStr(b[_keyToSort]["date"]) : getCompareStr(b[_keyToSort]["val"]);

    let comparison = 0;

    if (valA > valB) {
        comparison = 1;
    } else if (valA < valB) {
        comparison = -1;
    }

    //date is sorted in reverse order:
    if ((_order === SortOrder.DESCENDING && a[_keyToSort]["date"] === undefined) || (!!a[_keyToSort]["date"] && _order === SortOrder.ASCENDING)) {
        comparison *= -1;
    }

    return comparison;
}

//Filter:
export const filterSearch = (data, text) => {
    if (text !== "") {
        var newData = [];
        Logger.debug(data);
        for (var i = 0; i < data.length; i++) {
            // eslint-disable-next-line
            Object.keys(data[i]).some((key, index) => {
                if (!!data[i][key] && !!data[i][key]["val"]) {
                    if (data[i][key]["val"].toLowerCase().includes(text.toLowerCase())) {
                        newData.push(data[i]);
                        return true;
                    }
                    return false;
                }
                return false;
            });
        }
        return newData;
    } else return data;
}

export const filterAvailableFilterObjs = (nodes, text, groups) => {
    if (text !== "") {
        var newNodes = [];
        for (var i = 0; i < nodes.length; i++) {
            if (nodes[i].name.toLowerCase().includes(text.toLowerCase())) {
                newNodes.push(nodes[i]);
                continue;
            }
            if (!!groups) {
                var matchedGrps = groups.filter(group => group.groupName.includes(text.toLowerCase()));
                if (!!nodes[i].groups) {
                    // eslint-disable-next-line
                    nodes[i].groups.forEach(nodeGroupId => {
                        if (matchedGrps.some(group => group.groupId === nodeGroupId)) {
                            newNodes.push(nodes[i]);
                        }
                    })
                }
            }
        }
        Logger.debug(newNodes);
        return newNodes;
    } else return nodes;
}

export const filterAvailableVocabs = (vocabs, text) => {
    if (text !== "") {
        var newVocabs = [];
        for (var i = 0; i < vocabs.length; i++) {
            if (vocabs[i].toLowerCase().includes(text.toLowerCase())) {
                newVocabs.push(vocabs[i]);
                continue;
            }
        }
        Logger.debug(newVocabs);
        return newVocabs;
    } else return vocabs;
}

export const filteredFilterGroups = (filterGroups, filteredAvailableFilterObjs) => {
    var filteredFilterGrps = [];
    Logger.debug(filteredAvailableFilterObjs)
    filterGroups.forEach(filterGroup => {

        if (filteredAvailableFilterObjs.some(filteredAvailableFilterObj => !!filteredAvailableFilterObj.groups && filteredAvailableFilterObj.groups.includes(filterGroup.groupId))) {
            filteredFilterGrps.push(filterGroup);
        }
    })
    return filteredFilterGrps;
}

export const filterNewsObjMetadata = (newsObjMetadata, text) => {
    if (text !== "") {
        var new_NewsObjMetadata = [];
        for (var i = 0; i < newsObjMetadata.length; i++) {
            if (newsObjMetadata[i].toLowerCase().includes(text.toLowerCase())) {
                new_NewsObjMetadata.push(newsObjMetadata[i]);
                continue;
            }
        }
        Logger.debug(new_NewsObjMetadata);
        return new_NewsObjMetadata;
    } else return newsObjMetadata;
}


export const updateDataPublishedTime = data => {
    var newData = data;
    newData.forEach(dataElem => {
        Object.keys(dataElem).forEach((key, index) => {
            if (!!dataElem[key] && !!dataElem[key].date && !!dataElem[key].val) {
                //format object date already helps to update such...
                dataElem[key].val = Utilities.formatObjectDate(dataElem[key].date, UtilConstant.HUB_TIME_FORMAT.CANADIAN);
                if (!!dataElem[key].icon && Math.round(Math.round((new Date() - new Date(dataElem[key].date)) / 1000) / 60) > 9) {
                    //Remove "new" icon:
                    dataElem[key].icon = undefined;
                }
            }
        });
    });
    return newData;
}

//Extracting nodes, nodeGroup and categories from data:

// recalculate the selectablt nodes only when this.props.nodes has changed
const allNodes = memoize(
    (allowAll, nodes) => {
        return allowAll
            ? [{
                id: 0,
                name: "All",
                description: "All objects Burli News Hub",
                webUrl: "www.BurliNewsHub.com",
            }].concat(nodes)
            : nodes;
    }
);

export const extractNodesFromData = (nodes, hubNodeGroups) => {
    var newNodes = [];
    if (!!hubNodeGroups && !!nodes) {
        allNodes(false, hubNodeGroups).forEach(nodeGroup => {
            //Debug:
            if (!!nodeGroup.hubNodes) {
                nodeGroup.hubNodes.forEach(node => {
                    var newNodeIndex = newNodes.findIndex(newNode => newNode.id === node.id)
                    if (newNodeIndex < 0) {
                        newNodes.push({ id: node.id, name: node.name, groupIds: [nodeGroup.groupId] });
                    } else {
                        newNodes[newNodeIndex].groupIds.push(nodeGroup.groupId);
                    }
                });
            }
        });
        //fetch those not included in a group:
        allNodes(false, nodes).forEach(node => {
            //Debug:
            if (!newNodes.map(nodesAlreadyThere => nodesAlreadyThere.id).includes(node.id)) {
                newNodes.push({ id: node.id, name: node.name, groupIds: [] });
            }
        });
        Logger.debug(newNodes);
    }
    return newNodes;
}

export const extractNodeGroups = (nodeGroups) => {
    return Utilities.transformObjAryKeys(
        nodeGroups,
        [
            { old: "groupId", new: "id" },
            { old: "groupName", new: "name" },
            { old: "hubNodes", new: "vals" }
        ]
    )
}

export const extractCategories = (metadata) => {
    var result = [];

    if (!!metadata) {
        var categories = metadata.find(mdata => mdata.fieldId === 1);
        if (!!categories) {
            result = categories.vocabulary.map(vocab => { return { id: vocab.index, name: vocab.keyword } });
        }
    }

    return result;
}

export const extractFolderNodes = (folders) => {

    const result = [];
    if (!folders) {
        return result;
    }

    for (let i = 0; i < folders.length; i++) {
        let folder = convertObjToFolderTree(folders[i]);
        result.push(folder)
    }
    return result;
}

export const convertObjToFolderTree = (folder) => {
    const newFolder = {}
    newFolder.parentId = !!folder.parentId ? folder.parentId : null;
    newFolder.id = !!folder.objectId ? folder.objectId : folder.id;
    newFolder.name = folder.name;
    newFolder.securityOption = folder.securityId;
    newFolder.isOpen = false;
    return newFolder;
}

export const removeChildFolders = (tree, parentId) => {
    const stack = [parentId];

    while (stack.length > 0) {
        const currentNode = stack.pop();

        for (let i = tree.length - 1; i >= 0; i--) {
            if (tree[i].parentId === currentNode) {
                stack.push(tree[i].id);
                tree.splice(i, 1);
            }
        }
    }

    return tree.filter(node => node.parentId !== parentId);
}


export const onReceivePropsParseMetadata = (newsObject, metadataAry) => {
    if (!!newsObject.metadata) {
        const clonedMetadata  = _.cloneDeep(newsObject.metadata);
        //index is actually just internal, and value is actually index...
        //use value to fetch keyword, and make keyword a value:
        Logger.debug(clonedMetadata)
        Logger.debug(metadataAry);
        clonedMetadata.forEach(mdata => {
            mdata.values.forEach(val => {
                var designatedMetadata = metadataAry[0].vocabulary.find(m => m.index === parseInt(val.value));
                if (!!designatedMetadata) {
                    val.index = parseInt(val.value);
                    val.value = designatedMetadata.keyword;
                }
            })
        })
        return clonedMetadata;
    }
    return null;
}

export const navigateDataObj = (data, dataObjKeys) => {
    for (var i = 0; i < dataObjKeys.length; i++) {
      if (!!data && !!data[dataObjKeys[i]]) {
        data = data[dataObjKeys[i]]
      } else {
        /* Logger.debug("Perhaps, wrong data hierarchy given that leads to incorrect media? "); */ return null;
      }
    }
    return data
}

export const extractAvailableObjsForSecurityPermissions = (nodes, users) => {
    const nodeObjs = nodes.map(node => ({ guid: node.nodeGuid, id: node.id, name: node.name, isProdCentre: true }));
    const userObjs = users.filter(u => u.state > 0).map(user => ({ guid: user.userGuid, id: user.id, name: user.userName, isProdCentre: false }));
    const mergedObjs = nodeObjs.concat(userObjs);
    return mergedObjs.sort((a, b) => a.name.localeCompare(b.name));
};


export const interpretSecurityWord = (securityWord) => {
    const permissions = {
        read: false,
        create: false,
        modify: false,
        delete: false,
        reorder: false,
    };

    if (securityWord & 1) {
        permissions.read = true;
    }
    if (securityWord & 2) {
        permissions.create = true;
    }
    if (securityWord & 4) {
        permissions.modify = true;
    }
    if (securityWord & 8) {
        permissions.delete = true;
    }
    if (securityWord & 16) {
        permissions.reorder = true;
    }

    return permissions;
};

export const convertToSecurityWord = (permissions) => {
    let securityWord = 0;

    if (permissions.read) {
        securityWord |= 1;
    }
    if (permissions.create) {
        securityWord |= 2;
    }
    if (permissions.modify) {
        securityWord |= 4;
    }
    if (permissions.delete) {
        securityWord |= 8;
    }
    if (permissions.reorder) {
        securityWord |= 16;
    }

    return securityWord;
};

export const assignInheritedPermissions = (tree, childFolder) => {
    const getParentFolder = (parentId) => {
        return tree.find((folder) => folder.id === parentId);
    };

    const traverseUpTree = (folder) => {
        if (folder.securityOption !== -2) {
            return folder;
        }

        const parentFolder = getParentFolder(folder.parentId);

        if (parentFolder && parentFolder.id !== folder.id) {
            return traverseUpTree(parentFolder);
        }

        return null;
    };

    const parentFolder = traverseUpTree(childFolder);
    if (parentFolder) {
        childFolder.folderPermissions = parentFolder.folderPermissions;
    }
};