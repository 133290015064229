import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faCircle } from '@fortawesome/free-solid-svg-icons';

const PasswordRequirements = ({ password, isActive }) => {
    const [hasBeenFocused, setHasBeenFocused] = useState(false);
    const [requirements, setRequirements] = useState([
        { description: 'At least 8 characters long', met: false },
        { description: 'At least one uppercase letter', met: false },
        { description: 'At least one lowercase letter', met: false },
        { description: 'At least one number', met: false }
    ]);

    useEffect(() => {
        if (isActive && !hasBeenFocused) {
            setHasBeenFocused(true);
        }
    }, [isActive]);

    useEffect(() => {
        if (hasBeenFocused) {
            setRequirements([
                {
                    description: 'At least 8 characters long',
                    met: password.length >= 8
                },
                {
                    description: 'At least one uppercase letter',
                    met: /[A-Z]/.test(password)
                },
                {
                    description: 'At least one lowercase letter',
                    met: /[a-z]/.test(password)
                },
                {
                    description: 'At least one number',
                    met: /\d/.test(password)
                }
            ]);
        }
    }, [password, hasBeenFocused]);

    const shouldShowValidation = hasBeenFocused && password.length > 0;

    return (
        <div className="password-requirements mt-2">
            {requirements.map((requirement, index) => (
                <div
                    key={index}
                    className="requirement-item"
                    style={{
                        color: shouldShowValidation ? (requirement.met ? '#28a745' : '#dc3545') : '#6c757d',
                        fontSize: '0.875rem',
                        marginBottom: '0.25rem',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                    }}
                >
                    <FontAwesomeIcon
                        icon={shouldShowValidation
                            ? (requirement.met ? faCheck : faTimes)
                            : faCircle}
                        style={{
                            width: '16px',
                            fontSize: shouldShowValidation ? '1.0rem' : '0.4rem' 
                        }}
                    />
                    {requirement.description}
                </div>
            ))}
        </div>
    );
};

export default PasswordRequirements;