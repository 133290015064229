import React, { Fragment } from 'react';
import { DataTable, DataTableConstant, TableToolType } from 'hub-dashboard-framework'
import { Row, Col } from 'react-bootstrap';

import authService from 'components/api-authorization/AuthorizeService';

import Utilities, { UtilConstant } from 'hub-utilities';

import * as Logger from 'loglevel';
import * as _ from 'lodash'
import 'styles/main-content/Users.scss';

import { onClickDownloadCSVButton, mapMetricsDetailsToTableData, initializeMetricDetailsHeader, mapCSVData } from 'util/DataRenderHelper'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment'

class DateMetricsTable extends React.Component {
    constructor(props) {
        super(props);
        this.headerConfig = {
            Date: { val: "", canSort: true },
            ... initializeMetricDetailsHeader(props.metricsReportType)
        };
        this.state = {
            fetchedData: []
        }
    }

    componentDidMount() {
        this.setState({ fetchedData: this.fetchData(this.props.metrics) })
    }

    componentWillReceiveProps(nextPropsFromRedux) {
        if (!_.isEqual(nextPropsFromRedux.metrics, this.props.metrics)
            || this.props.action != nextPropsFromRedux.action) {
            this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.metrics) })
        }
    }

    isToday(date) {
        return moment(date).format("MM/DD/YYYY") === moment(new Date()).format("MM/DD/YYYY")
    }

    renderDateVal(date) {
        return Utilities.formatObjectDate(date, UtilConstant.HUB_TIME_FORMAT.ONLY_DATE, true, false, false)
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.sort((a, b) => { return (a.id < b.id) ? 1 : -1 }).map((o) => {
                return (
                    {
                        //Lower case keys won't get posted to table
                        Date: {
                            val: this.isToday(o.date) ? 
                                <div style={{color: '#f69b1d', fontWeight: 'bold'}}>{this.renderDateVal(o.date)}</div> :
                                this.renderDateVal(o.date), 
                            canSort: true, 
                            date: o.date,
                            isExactDate: true,
                            isUtc: true,
                            utc: true,
                            initialSortOrder: 2 //TODO: Reverse Sorting issue, need to add an attr - initialSortOrder
                        },
                        ... mapMetricsDetailsToTableData(o.metrics, this.props.metricsReportType)
                    }
                );
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.PAGINATION }, 
                            { action: TableToolType.FILTER_SEARCH },
                            { 
                                action: TableToolType.CUSTOMIZED_BUTTON, 
                                tooltipName: 'Download CSV', 
                                buttonIcon: faFileDownload, 
                                onClick: () => onClickDownloadCSVButton("newshub_metrics_date", mapCSVData(this.props.metricsReportType, this.props.metrics))
                            },
                        ]}
                        canDeleteRow={false}
                        numOfRowsPerPage={5}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="DateMetricsTable">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

export default DateMetricsTable;
