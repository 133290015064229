// TitleComponent.jsx

import React from 'react';
import { Helmet } from 'react-helmet';

//Redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import * as HubSettingsActions from 'store/actions/HubSettingsActions';

//Sets the title of the website for the tab
class Title extends React.Component {
    constructor(props) {
        super(props);
        this.defaultTitle = 'Burli NewsHub';
    }

    render() {
        return (
            <Helmet>
                <title>{!!this.props.hubInfo && this.props.hubInfo.hubInfo.customerName ? this.props.hubInfo.hubInfo.customerName : this.defaultTitle}</title>
            </Helmet>
        );
    }
};

export default withRouter(connect(
    HubSettingsActions.mapSettingsStateToProps,
    dispatch => bindActionCreators(HubSettingsActions.mapSettingsDispatcherToProps, dispatch)
)(Title));