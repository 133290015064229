import * as Logger from 'loglevel';
import { DataTableConstant } from 'hub-dashboard-framework'

export class ScnReducer {
    //Add action here:
    onScnAddObject = (state, scnContent) => {
        Logger.debug(state);
        Logger.debug(scnContent);
        if (state.hubWorkingFolder.id == scnContent.folderId && state.action != DataTableConstant.HUB_ACTION_STATE.ELASTIC_SEARCH) {
            Logger.debug(`ScnSink: adding object = ${scnContent.hubObject.id} in Folder with Id: ${scnContent.folderId}`);
            let newState = {
                ...state,
                objectList: [
                    scnContent.hubObject
                ].concat(state.objectList),
                bTriggerUpdate: true
                //.concat([scnContent.hubObject])
            }
            newState.action = DataTableConstant.HUB_ACTION_STATE.ADD_ROW;
            return newState;
        }
        Logger.debug("Add failed?!!");
        return state;
    }

    onScnDeleteObject = (state, scnContent) => {
        if (state.hubWorkingFolder.id == scnContent.folderId) {
            Logger.debug(`ScnSink: deleting object = ${scnContent.objectId} from Folder with Id: ${scnContent.folderId}`);
            let newObjectList = state.objectList.filter(o => (o.id !== scnContent.objectId));
            Logger.debug('ScnSink: objectList', newObjectList);
            let next_state = {
                ...state,
                objectList: newObjectList,
                bTriggerUpdate: true
            };
            next_state.action = DataTableConstant.HUB_ACTION_STATE.DELETE_ROW;
            Logger.debug('ScnSink: next_state', next_state);
            return next_state;
        }
        return state;
    }


    onScnDeleteObjects = (state, scnContent) => {
        //Cannot read property 'filter' of null solution:
        if (!!state.objectList) {
            if (!!scnContent.objectIdList && scnContent.objectIdList.length > 0) {
                Logger.debug('ScnSink: deleting objects: nodeId=', scnContent.nodeId);
                Logger.debug(scnContent);
                let newObjectList = state.objectList.filter(o => !scnContent.objectIdList.includes(o.objectId));
                let next_state = {
                    ...state,
                    objectList: newObjectList,
                    bTriggerUpdate: true
                };
                return next_state;
            }
        }
        return state;
    }
    onScnUpdateUser = (state, scnContent) => {
        if (state.users) {
            Logger.debug(`ScnSink: adding or updating user, payload: ${scnContent}`);
            const user = scnContent.user;
            let index = state.users.findIndex(u => u.id === user.id);
            if (index === -1) {


                let newState = {
                    ...state,
                    users: state.users.concat([user])
                }
                Logger.debug(`ScnSink: added new user, newstate: ${newState}`);
                Logger.debug(newState);
                newState.action = DataTableConstant.HUB_ACTION_STATE.ADD_ROW;
                newState.bTriggerUpdate = true
                return newState;

            } else {

                let newState = {
                    ...state,
                    users: [
                        ...state.users.slice(0, index),
                        user,
                        ...state.users.slice(index + 1, state.users.length)
                    ]
                }
                Logger.debug(`ScnSink: updated user, newstate: ${newState}`);
                Logger.debug(newState);
                newState.action = DataTableConstant.HUB_ACTION_STATE.MODIFY_ROW;
                newState.bTriggerUpdate = true
                return newState;
            }
        }
        return state;
    }
}

const scnReducer = new ScnReducer();
export default scnReducer;
