import React, { useState } from 'react';
import { Table, Pagination } from 'react-bootstrap';
import authService from 'components/api-authorization/AuthorizeService';
import 'styles/tables/FolderSecurityGroup.scss';

export function UserTable(props) {
    const { users, onUserCheck } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    function generateCheckbox(user) {
        return (
            <td key={`checkbox_${user.id}`} className='checkboxRow'>
                <label className='checkboxContainer'>
                    <input
                        id={`checkbox_${user.id}`}
                        name={`checkbox_${user.id}`}
                        className='filterGroupChild'
                        type='checkbox'
                        onChange={() => onUserCheck(user.id)}
                        checked={user.isChecked !== undefined ? user.isChecked : false}
                    />
                    <span className='checkmark' />
                </label>
            </td>
        );
    }

    const paginatedUsers = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Login Name</th>
                        <th>Production Centre</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedUsers.map(user => (
                        <tr key={user.id}>
                            {generateCheckbox(user)}
                            <td>{user.userName}</td>
                            <td>{authService.getHubNodeName(user.hubNodeId)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination className="pagUserPerm">
                <Pagination.Prev
                    className="paginationFirst"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                />
                {Array.from({ length: Math.ceil(users.length / itemsPerPage) }, (_, i) => (
                    <Pagination.Item key={i + 1} active={i + 1 === currentPage} onClick={() => handlePageChange(i + 1)}>
                        {i + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next
                    className='paginationLast'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === Math.ceil(users.length / itemsPerPage)}
                />
            </Pagination>
        </div>
    );
}
