import * as Logger from 'loglevel';
import * as ActionType from '../ActionType'
import { DataTableConstant } from 'hub-dashboard-framework'

const initialState = {
    success: null,
    error: null,
    metrics: null,
    action: DataTableConstant.HUB_ACTION_STATE.NONE,
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_METRICS_BEGIN:
            Logger.debug(`HubMetrics reducer: action = ${action.type}`);
            return ({
                ...state,
                error: null,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING
            });
        case ActionType.FETCH_METRICS_SUCCESS:
            Logger.debug(`HubMetrics reducer: action = ${action.type}`);
            Logger.debug(action.payload)
            return (
                {
                    ...state,
                    metrics: action.payload.metrics,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE
                });
        case ActionType.FETCH_METRICS_FAILURE:
            Logger.debug(`HubMetrics reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    metrics: {},
                    error: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        default:
            break;
    }
    return state;
}

