export const MEDIA_CONTENT_AUDIO = 1;
export const MEDIA_CONTENT_VIDEO = 2;
export const MEDIA_CONTENT_IMAGE = 3;
export const MEDIA_CONTENT_PDF = 4;
export const MEDIA_CONTENT_TEXT = 50;
export const MEDIA_CONTENT_ATTACHMENT = 99;

export const HUB_OBJECT_COLLECTION = 101;
export const HUB_OBJECT_FOLDER = 102;
export const HUB_OBJECT_NEWSPLANNER = 103;
export const HUB_OBJECT_SHOW = 201;
export const HUB_OBJECT_STORY = 202;
export const HUB_OBJECT_ELEMENT = 301;
export const HUB_USERS = 401;
export const HUB_PRODUCTION_GROUP = 501;
export const HUB_METADATA = 601;
export const HUB_METADATA_PER_NEWSITEM = 701;
export const HUB_VOCABULARY = 701;
export const HUB_FOLDER_SECURITY_GROUP = 801;

export const HUB_USER_PROFILE_FORM = 1;
//export const HUB_NEWS_OBJECT_FORM  = 2; deprecated
export const HUB_CREATE_NEW_USER_FORM = 3;
export const HUB_UPDATE_USER_PASSWORD_FORM = 4;
export const HUB_UPDATE_USER = 5;
export const HUB_CREATE_PRODUCTION_GROUP_FORM = 6;
export const HUB_UPDATE_PRODUCTION_GROUP = 7;
export const HUB_CREATE_METADATA_FORM = 8;
export const HUB_UPDATE_METADATA_FORM = 9;
export const HUB_UPDATE_NEWSITEM_METADATA_FORM = 10;
export const HUB_STORY_FORM = 11;
export const HUB_ELEMENT_FORM = 12;
export const HUB_OUTQ_FORM = 13;
export const HUB_UPDATE_NODE_FORM = 14;
export const MOVE_NEWSITEM_FOLDER_FORM = 15;
export const HUB_UPDATE_FOLDER_SECURITY_GROUP_FORM = 16
export const HUB_CREATE_FOLDER_SECURITY_GROUP_FORM = 17

export const HUB_USER_STATE_ACTIVE = 1;
export const HUB_USER_STATE_INACTIVE = 0;

export const HUB_AUTO = 1679;

export const HUB_SUBOBJECT_STATE = Object.freeze({
    IDLE: 0,
    FETCHING: 1,
    FETCHED: 2
})

export const HUB_INFO_TYPE = Object.freeze({
    "DEFAULT": 0,
    "HUB_INFO": 1,
    "ENVIRONMENT": 2,
    "NODES": 3,
    "RUNTIME": 4,
    "GENERAL": 5,
    "CUSTOMIZED": 6
});

export const HUB_SETTINGS_TYPE = Object.freeze({
    "DEFAULT": 0,
    "SCHEDULE": 1,
    "PURGE": 2
});

export const HUB_GEO_FIELD_STATE = Object.freeze({
    "ADD": 0,
    "MODIFY": 1,
    "DELETE": 2
});

export const HUB_NEWSITEM_UPDATE_TYPE = Object.freeze({
    "SUBOBJECT": 0,
    "SUBOBJECT_METADATA": 1
});

export const HUB_METRICS_REPORT_TYPE = Object.freeze({
    "CONTENT_CREATION": 0,
    "CONTENT_CONSUMPTION": 1
});

export const FOLDER_SECURITY_OPTIONS = Object.freeze({
    "PUBLIC": 0,
    "PRIVATE": -1,
    "INHERIT": -2,
    "CUSTOM": -3
})

export const USER_FOLDER_PERMISSIONS = Object.freeze({
    NONE: 0,
    READ: 1,
    CREATE: 2,
    MODIFY: 4,
    DELETE: 8,
    REORDER: 16
})

export const HUB_ACTION_STATE = Object.freeze({
    NONE: 'NONE',
    REFRESH: 'REFRESH',
    ADD_ROW: 'ADD_ROW',
    DELETE_ROW: 'DELETE_ROW',
    MOVE_ROW: 'MOVE_ROW',
    ELASTIC_SEARCH: 'ELASTIC_SEARCH',
    FILTER: 'FILTER',
    LOADING: 'LOADING',
    INITIALIZE: 'INITIALIZE',
    MODIFY_ROW: 'MODIFY_ROW',
    SELECT_CHECKBOX: 'SELECT_CHECKBOX',
    EXPAND_ROW: 'EXPAND_ROW'
})