import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType';

// state and reducer here:

const initialState = {
    isLoading: false,
    logoObj: null,
    error: null
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_LOGO_BEGIN:
        case ActionType.UPDATE_LOGO_BEGIN:
        case ActionType.GET_HUB_INFO_BEGIN:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return ({
                ...state,
                error: null,
                isLoading: true,
            });
        case ActionType.FETCH_LOGO_SUCCESS:
        case ActionType.UPDATE_LOGO_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    isLoading: false,
                    logoObj: action.payload,
                });
        case ActionType.GET_HUB_INFO_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    isLoading: false
                });
        case ActionType.FETCH_LOGO_FAILURE:
        case ActionType.UPDATE_LOGO_FAILURE:
        case ActionType.GET_HUB_INFO_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    isLoading: false,
                    error: action.payload,
                });
        default:
            break;
    }
    return state;
}
