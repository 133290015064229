import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import * as DataRenderHelper from 'util/DataRenderHelper';
import * as HubConstant from 'util/HubConstant';
import { UtilConstant } from 'hub-utilities'
import hubApi from 'util/HubApi';
import authService from '../../../api-authorization/AuthorizeService';
import PasswordRequirements from './PasswordRequirements';

import 'styles/main-content/MyAccount.scss';

const MyAccount = (props) => {
    const [userData, setUserData] = useState(null);
    const [passwordVisibility, setPasswordVisibility] = useState({new: false,confirm: false});
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(true);
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const [passwords, setPasswords] = useState({new: '',confirm: ''});
    const [message, setMessage] = useState({ text: '', type: '' });

    useEffect(() => {
        const initializePage = async () => {
            props.setActivePageName({
                singular: "My Account",
                plural: "My Account",
                icon: faUser
            });

            const user = await authService.getCurrentHubUser();
            setUserData({
                ...user,
                displayRole: displayUserAuthority(user.adminLevel)
            });
        };

        initializePage();
    }, []);

    const handlePasswordChange = (e) => {
        e.preventDefault();

        const hasUpperCase = /[A-Z]/.test(passwords.new);
        const hasLowerCase = /[a-z]/.test(passwords.new);
        const hasNumber = /\d/.test(passwords.new);
        const isLongEnough = passwords.new.length >= 8;

        if (!isLongEnough || !hasUpperCase || !hasLowerCase || !hasNumber) {
            setMessage({ text: 'Password does not meet all requirements.', type: 'error' });
            return;
        }

        if (passwords.new !== passwords.confirm) {
            setMessage({ text: 'New passwords do not match.', type: 'error' });
            return;
        }
        setShowConfirmModal(true);
    };

    const confirmPasswordChange = async () => {
        setShowConfirmModal(false);

        try {
            const response = await hubApi.resetUserPassword(userData, passwords.new);

            if (response.ok) {
                setMessage({
                    text: 'Password updated successfully',
                    type: 'success'
                });
                setPasswords({ new: '', confirm: '' });
                setShowPasswordForm(false);  // Hide the form on success
            } else {
                const error = await response.json();
                setMessage({
                    text: `Failed: ${error.message || 'Unknown error occurred'}`,
                    type: 'error'
                });
            }
        } catch (error) {
            setMessage({
                text: 'An error occurred while updating the password',
                type: 'error'
            });
        }
    };

    const displayUserAuthority = (adminLevel) => {
        switch (adminLevel) {
            case UtilConstant.HUB_USER_TYPE.HUB_USER:
                return "Normal User";
            case UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN:
                return `${authService.getHubNodeName(authService.getNodeIdByUserGuid(authService.getUserGuid()))} Admin`;
            case UtilConstant.HUB_USER_TYPE.HUB_ADMIN:
                return "System Admin";
            default:
                return "";
        }
    };

    const renderPasswordInput = (name, placeholder, trackFocus = false) => (
        <div className="password-input-wrapper">
            <input
                type={passwordVisibility[name] ? "text" : "password"}
                placeholder={placeholder}
                value={passwords[name]}
                onChange={(e) => setPasswords({ ...passwords, [name]: e.target.value })}
                className="form-control"
                onFocus={() => trackFocus && setIsPasswordFocused(true)}
                onBlur={() => trackFocus && setIsPasswordFocused(false)}
            />
            <button
                type="button"
                tabIndex="-1"
                className="password-toggle-button"
                onClick={() => setPasswordVisibility(prev => ({
                    ...prev,
                    [name]: !prev[name]
                }))}
            >
                <FontAwesomeIcon icon={passwordVisibility[name] ? faEyeSlash : faEye} />
            </button>
        </div>
    );

    if (!userData) return null;

    return (
        <div className="my-account-container">
            <Row>
                <Col xs="12" className="mb-4">
                    <Card className="card-custom">
                        <Card.Header>
                            <h5 className="mb-0">MY ACCOUNT DETAILS</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs="6">
                                    {DataRenderHelper.renderUserInfo([
                                        {
                                            key: "userName",
                                            title: "Username",
                                            type: HubConstant.HUB_INFO_TYPE.GENERAL
                                        },
                                        {
                                            key: "firstName",
                                            title: "First Name",
                                            type: HubConstant.HUB_INFO_TYPE.GENERAL
                                        },
                                        {
                                            key: "lastName",
                                            title: "Last Name",
                                            type: HubConstant.HUB_INFO_TYPE.GENERAL
                                        }
                                    ], userData)}
                                </Col>
                                <Col xs="6">
                                    {DataRenderHelper.renderUserInfo([
                                        {
                                            key: "email",
                                            title: "Email",
                                            type: HubConstant.HUB_INFO_TYPE.GENERAL
                                        },
                                        {
                                            key: "displayRole",
                                            title: "Admin Level",
                                            type: HubConstant.HUB_INFO_TYPE.GENERAL
                                        },
                                        {
                                            key: "name",
                                            title: "NewsRoom",
                                            type: HubConstant.HUB_INFO_TYPE.HUB_NODE
                                        }
                                    ], userData)}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="12">
                    <Card className="card-custom">
                        <Card.Header>
                            <h5 className="mb-0">CHANGE PASSWORD</h5>
                        </Card.Header>
                        <Card.Body>
                            {showPasswordForm ? (
                                <form onSubmit={handlePasswordChange}>
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>New Password</b></label>
                                        {renderPasswordInput('new', 'Enter new password', true)}
                                        <PasswordRequirements
                                            password={passwords.new}
                                            isActive={isPasswordFocused}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="form-label"><b>Confirm New Password</b></label>
                                        {renderPasswordInput('confirm', 'Confirm new password')}
                                    </div>
                                    {message.text && (
                                        <div className={`alert alert-${message.type === 'error' ? 'danger' : 'success'}`}>
                                            {message.text}
                                        </div>
                                    )}
                                    <Button
                                        variant='light'
                                        type='submit'
                                        className='float-right mr-0'
                                    >
                                        Update Password
                                    </Button>
                                </form>
                            ) : (
                                <div className="alert alert-success">
                                    Password has been updated successfully.
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Password Change</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to change your password?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant='light'
                        type='submit'
                        className='float-right mr-0'
                        onClick={confirmPasswordChange}
                    >
                        Update Password
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

const mapDispatchToProps = {
   
};

export default connect(null, mapDispatchToProps)(MyAccount);