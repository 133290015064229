import * as ActionType from '../ActionType'
import * as Logger from 'loglevel'
import authService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'
//dispatcher and actions here:
//To sync logo between About and Side Navbar

const FetchType = Object.freeze({
    HUB_INFO: 0,
    HUB_SETTINGS: 1,
    HUB_ENDPOINTS: 2,
    IDP_INFO: 3
})

const apiFetch = (method, fetchType, name, body, ignoreNullOptions, isUpdatingLicense, idpUrl) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: fetchType === FetchType.HUB_SETTINGS ? `/api/Settings/maintenance${ignoreNullOptions !== undefined ? `?ignoreNullOptions=${ignoreNullOptions}` : ''}` :
            fetchType === FetchType.HUB_INFO ? `/api/HubInfo${!!name ? `/name?hubName=${name}` : ''}` : 
            fetchType === FetchType.HUB_ENDPOINTS ? `/api/HubInfo/endpoint` : 
            fetchType === FetchType.IDP_INFO ? `${idpUrl}/api/BurliIdp/info` : 
            '',
        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,
        begin: !!name ? setHubNameBegin :
            fetchType === FetchType.HUB_INFO ? getHubInfoBegin :
            fetchType === FetchType.HUB_SETTINGS ? method === "GET" ? fetchSettingsBegin : updateSettingsBegin :
            fetchType === FetchType.HUB_ENDPOINTS ? getHubEndpointsBegin :
            fetchType === FetchType.IDP_INFO ? getIdpInfoBegin :
                    null,
        success: !!name ? setHubNameSuccess :
            fetchType === FetchType.HUB_INFO ? getHubInfoSuccess :
            fetchType === FetchType.HUB_SETTINGS ? method === "GET" ? fetchSettingsSuccess : updateSettingsSuccess :
            fetchType === FetchType.HUB_ENDPOINTS ? getHubEndpointsSuccess :
            fetchType === FetchType.IDP_INFO ? getIdpInfoSuccess :
                    null,
        error: !!name ? setHubNameFailure :
            fetchType === FetchType.HUB_INFO ? getHubInfoFailure :
            fetchType === FetchType.HUB_SETTINGS ? method === "GET" ? fetchSettingsFailure : updateSettingsFailure :
            fetchType === FetchType.HUB_ENDPOINTS ? getHubEndpointsFailure :
            fetchType === FetchType.IDP_INFO ? getIdpInfoFailure :
                    null,
        additionalParams: {
            ...(ignoreNullOptions !== undefined) && {isReset: !ignoreNullOptions},
            isUpdatingLicense: isUpdatingLicense,
        }
    }
});

const fetchSettingsBegin = () => ({
    type: ActionType.FETCH_SETTINGS_BEGIN
});

const fetchSettingsSuccess = payload => ({
    type: ActionType.FETCH_SETTINGS_SUCCESS,
    payload: payload
});

const fetchSettingsFailure = error => ({
    type: ActionType.FETCH_SETTINGS_FAILURE,
    payload: error
});

const updateSettingsBegin = () => ({
    type: ActionType.UPDATE_SETTINGS_BEGIN
});

const updateSettingsSuccess = (payload, additionalParams) => ({
    type: ActionType.UPDATE_SETTINGS_SUCCESS,
    payload: payload,
    isReset: additionalParams.isReset
});

const updateSettingsFailure = error => ({
    type: ActionType.UPDATE_SETTINGS_FAILURE,
    payload: error
});

const setHubNameBegin = () => ({
    type: ActionType.SET_HUB_NAME_BEGIN,
});

const setHubNameSuccess = payload => ({
    type: ActionType.SET_HUB_NAME_SUCCESS,
    payload: payload
});

const setHubNameFailure = error => ({
    type: ActionType.SET_HUB_NAME_FAILURE,
    payload: error
});

const getHubInfoBegin = (additionalParams) => ({
    type: ActionType.GET_HUB_INFO_BEGIN,
    additionalParams: additionalParams
});

const getHubInfoSuccess = payload => ({
    type: ActionType.GET_HUB_INFO_SUCCESS,
    payload: payload
});

const getHubInfoFailure = error => ({
    type: ActionType.GET_HUB_INFO_FAILURE,
    payload: error
});

const getIdpInfoBegin = (additionalParams) => ({
    type: ActionType.GET_IDP_INFO_BEGIN,
    additionalParams: additionalParams
});

const getIdpInfoSuccess = payload => ({
    type: ActionType.GET_IDP_INFO_SUCCESS,
    payload: payload
});

const getIdpInfoFailure = error => ({
    type: ActionType.GET_IDP_INFO_FAILURE,
    payload: error
});

const getHubEndpointsBegin = () => ({
    type: ActionType.GET_HUB_ENDPOINTS_BEGIN
});

const getHubEndpointsSuccess = payload => ({
    type: ActionType.GET_HUB_ENDPOINTS_SUCCESS,
    payload: payload
});

const getHubEndpointsFailure = error => ({
    type: ActionType.GET_HUB_ENDPOINTS_FAILURE,
    payload: error
});

const editSettingsInput = () => ({
    type: ActionType.EDIT_SETTINGS_INPUT
});

export const mapSettingsStateToProps = state => {
    //Logger.debug(`map Settings props, state=`, state.hubConfig.hubSettings);
    return state.hubConfig.hubSettings;
};


const fetchSettings = async (isUpdatingLicense, dispatch, getState) => {
    Logger.debug("ISSETTINGFETCH: " , isUpdatingLicense)
    await dispatch(apiFetch("GET", FetchType.HUB_SETTINGS, undefined, undefined, undefined));
    await dispatch(apiFetch("GET", FetchType.HUB_INFO, undefined, undefined, undefined, isUpdatingLicense));
}

export const mapSettingsDispatcherToProps = {

    startEditingInput: () => (dispatch, getState) => {
        dispatch(editSettingsInput());
    },

    startSettingsFetch: (isUpdatingLicense) => async (dispatch, getState) => {
        await fetchSettings(isUpdatingLicense, dispatch, getState);
    },

    startUpdateSettings: (schedule, purge, ignoreNullOptions) => async (dispatch, getState) => {
        //formData does not take in object...
        await dispatch(apiFetch("PUT", FetchType.HUB_SETTINGS, undefined, { schedule: schedule, purge: purge }, ignoreNullOptions));
        //reset:
        //if (!ignoreNullOptions)

        //need to set timeout as below: or else the data won't get reset/fetched on time...
          setTimeout(async() => {
                await dispatch(apiFetch("GET", FetchType.HUB_SETTINGS, undefined, undefined, undefined));
            } , 500);
    },

    setHubName: (name, description) => async (dispatch, getState) => {
        await dispatch(apiFetch("PUT", FetchType.HUB_INFO, name, description));
        await dispatch(apiFetch("GET", FetchType.HUB_INFO, undefined, undefined, undefined));
    },

    getHubInfo: () => async (dispatch, getState) => {
        await dispatch(apiFetch("GET", FetchType.HUB_INFO, undefined, undefined, undefined, false));

    },

    getIdpInfo: (idpUrl) => async (dispatch, getState) => {
        await dispatch(apiFetch("GET", FetchType.IDP_INFO, undefined, undefined, undefined, false, idpUrl));
    },

    getHubEndpoints: () => async (dispatch, getState) => {
        await dispatch(apiFetch("GET", FetchType.HUB_ENDPOINTS, undefined, undefined, undefined));
    }
}