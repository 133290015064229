import * as Logger from 'loglevel';
import * as signalR from "@microsoft/signalr"
import * as ScnOpCope from './ScnOpCode';
import * as ActionType from '../ActionType';

export const createHubScnClientMiddleware = (url) => {
    return (store) => {
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect()
            .build();

        connection.on("onScnReceived", (message) => {
            Logger.debug(`SCN Received`, message)
            let scn = JSON.parse(message);
            Logger.debug(`SCN =`, scn)
            Logger.debug(`store =`, store)
            let actionType = null;
            switch (scn.opCode) {
                case ScnOpCope.SCN_OPCODE_ADD_HUB_OBJECT:
                    actionType = ActionType.SCN_ADD_HUB_OBJECT;
                    break;
                case ScnOpCope.SCN_OPCODE_DELETE_HUB_OBJECT:
                    actionType = ActionType.SCN_DELETE_HUB_OBJECT;
                    break;
                case ScnOpCope.SCN_OPCODE_DELETE_HUB_OBJECTS:
                    actionType = ActionType.SCN_DELETE_HUB_OBJECTS;
                    break;
                case ScnOpCope.SCN_OPCODE_UPDATE_USER:
                    actionType = ActionType.SCN_UPDATE_USER;
                    break;
                default:
                    break;
            }
            // DO NOT mutate store object here, hand it over to reducers by dispatching an action
            // Changes to store object can only be made by reducers 
            Logger.debug(`actionType =`, actionType)
            try {
                if (!!actionType) {
                    store.dispatch({
                        type: actionType,
                        payload: scn.content
                    });
                    Logger.debug(`SCN action ${actionType} dispatched`);
                }
                else {
                    Logger.error(`SCN ignored: no action type!`);
                }
            }
            catch (error) {
                Logger.error(`Scn error: ${error}`);
            }

            Logger.debug(`Scn Processed`)
        });
        connection.start();
        Logger.debug('Scn client connected to ', url);
        return next => action => {
            //Logger.debug('dispatching', action)
            var result = next(action)
            //Logger.debug('dispatched, next state:', store.getState())
            return result;
        };
    } 
}

