import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'


export function FolderTreeToast(props){

    useEffect(() => {
        if (props.isHardRefresh) {
            const timer = setTimeout(() => {
                //window.location.reload();
                props.onHardRefresh();  // might need to put this in a method in folder tree and pass as a prop
            }, 5000);
            

            return () => clearTimeout(timer);
        }
    }, []);

    const handleDismiss = () => {
        if (props.isDataRefresh) {
            props.onDataRefresh();
        } else if (props.isHardRefresh) {
            props.onHardRefresh()
        }
        props.onDismiss();
    };

    return (
        <Modal show={true} onHide={handleDismiss} size="sm">
            <Modal.Body>{props.message}</Modal.Body>
            <Modal.Footer>
                    <Button variant="primary" onClick={handleDismiss}>
                        Dismiss
                    </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FolderTreeToast;
