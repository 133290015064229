import React, {useState} from "react";
import { ResetPasswordFormEx } from 'hub-dashboard-framework'
import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';


export default function NewPasswordForm(props) {


    const [formState, setFormState] = useState({
            password1: "",
            password2: "",
            formError: null,
            isStatusEnabled: false,
            spaceWarningShow: false
    })

    const onChange = (event, isPassword) => {
        if (isPassword && event.target.value.includes(' ')) {
            setFormState(prevFormState => ({
                ...prevFormState,
                spaceWarningShow: true,
            }));

        } else {

            const { name, value} = event.target;
            setFormState(prevFormState => ({
                ...prevFormState,
                [name] : value,
                spaceWarningShow: false,
            }));

        }
    }


    const submitHandler = (event) => {
        Logger.debug('new password from submitHander, event=', event);
        event.preventDefault();
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }

        updatePassword();
    };

    const updatePassword = async () => {
        const { payload: user } = props;
        var response = await hubApi.resetUserPassword(user, formState.password1);
        if (response.ok) {
            Logger.debug(`user password reset.`);
            props.onFormSubmitted();
        }
        else {
            Logger.error(`user password reset failed, response=.`, response);
            let error = await response.json();
            setFormState(prevFormState => ({
                ...prevFormState,
                formError: `Failed: ${error.message}`,
                spaceWarningShow: true,
            }));
        }
    }
    /*
                                 validate
                                error="password must be at least 6 characters."
                                success="right"
                                required
                                pattern=".{6,}"
                                title="password must be at least 6 characters."
    */

    return (
        <ResetPasswordFormEx
            password1={formState.password1}
            password2={formState.password2}
            formError={formState.formError}
            onChange={onChange}
            onSubmit={updatePassword}
        />
    );

}