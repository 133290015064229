import React, { Fragment } from 'react';
import { Row, Col, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { UtilConstant } from 'hub-utilities';
import HubNodeSelector from './HubNodeSelector';

export function HubUserFormEx(props) {
    const getPasswordValidationText = () => {
        if (props.password1 !== '') {
            if (props.password1.includes(' ')) {
                return 'Spaces cannot be used for password!';
            } else if (props.password1.length < 6) {
                return 'Please enter a password that is at least 6 characters long.';
            }
        }
        return '';
    };

    const validateForm = () => {
        return getPasswordValidationText() === '' && props.password1 === props.password2;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            props.onSubmit(e);
        }
    };

    const renderPasswordInput = () => {
        return (
            <TextField
                value={props.password1}
                name="password1"
                onChange={(e) => props.onChange(e, true)}
                type="password"
                id="userformPassword1"
                label="Password"
                error={
                    props.password1 !== '' &&
                    (props.password1.length < 6 || props.password1.includes(' '))
                }
                helperText={getPasswordValidationText()}
                required
            />
        );
    };

    const renderUserLevelRadioButtons = () => {
        const currentNode = props.nodes.find(node => node.id === props.nodeId);
        const nodeName = currentNode ? currentNode.name : '';

        return (
            <>
                <div className="formTitle">User Level</div>
                <label className="radioContainer">
                    Normal user
                    <input
                        onChange={() => props.onSelectAdminLevel(UtilConstant.HUB_USER_TYPE.HUB_USER)}
                        checked={props.adminLevel === UtilConstant.HUB_USER_TYPE.HUB_USER}
                        value="Normal user"
                        type="radio"
                        id="userformAdminOptNormal"
                    />
                    <span className="checkmark" />
                </label>
                {props.userType > UtilConstant.HUB_USER_TYPE.HUB_ADMIN ? (
                    <OverlayTrigger
                        key="cantCreate"
                        placement="left"
                        overlay={
                            <Tooltip
                                id={String(props.nodeId)}
                                className="adminTooltip rowActionTooltip"
                            >
                                You Have No<br />
                                Permission to<br />
                                Create Administrator
                            </Tooltip>
                        }
                    >
                        <div>
                            <label className="radioContainer">
                                {nodeName + " Administrator"}
                                <input
                                    disabled
                                    onChange={() => props.onSelectAdminLevel(UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN)}
                                    checked={props.adminLevel === UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN}
                                    value={nodeName + " Administrator"}
                                    type="radio"
                                    id="userformAdminOptNode"
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="radioContainer">
                                System Administrator
                                <input
                                    disabled
                                    onChange={() => props.onSelectAdminLevel(UtilConstant.HUB_USER_TYPE.HUB_ADMIN)}
                                    checked={props.adminLevel === UtilConstant.HUB_USER_TYPE.HUB_ADMIN}
                                    value="System Administrator"
                                    type="radio"
                                    id="userformAdminOptHub"
                                />
                                <span className="checkmark" />
                            </label>
                        </div>
                    </OverlayTrigger>
                ) : (
                    <div>
                        <label className="radioContainer">
                            {nodeName + " Administrator"}
                            <input
                                onChange={() => props.onSelectAdminLevel(UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN)}
                                checked={props.adminLevel === UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN}
                                value={nodeName + " Administrator"}
                                type="radio"
                                id="userformAdminOptNode"
                            />
                            <span className="checkmark" />
                        </label>
                        <label className="radioContainer">
                            System Administrator
                            <input
                                onChange={() => props.onSelectAdminLevel(UtilConstant.HUB_USER_TYPE.HUB_ADMIN)}
                                checked={props.adminLevel === UtilConstant.HUB_USER_TYPE.HUB_ADMIN}
                                value="System Administrator"
                                type="radio"
                                id="userformAdminOptHub"
                            />
                            <span className="checkmark" />
                        </label>
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="HubUserForm">
            <Form className="needs-validation burli-user-form mt-3" onSubmit={handleSubmit}>
                <Row>
                    <Col xs={6}>
                        <TextField
                            value={props.username}
                            name="username"
                            onChange={(e) => props.onChange(e, false)}
                            type="text"
                            id="userformUsername"
                            label="Username"
                            required
                        >
                            <div className="invalid-feedback">
                                Please enter a valid username(must be unique in Hub).
                            </div>
                        </TextField>
                    </Col>
                    <Col xs={6}>
                        <TextField
                            value={props.email}
                            name="email"
                            onChange={(e) => props.onChange(e, false)}
                            type="email"
                            id="userformEmail"
                            label="Email"
                            required
                        >
                            <div className="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                        </TextField>
                    </Col>
                </Row>

                {!props.isUpdateUser && (
                    <Row>
                        <Col xs={6}>{renderPasswordInput()}</Col>
                        <Col xs={6}>
                            <TextField
                                value={props.password2}
                                name="password2"
                                onChange={(e) => props.onChange(e, true)}
                                type="password"
                                id="userformPassword2"
                                label="Confirm password"
                                error={props.password2 !== '' && props.password1 !== props.password2}
                                helperText={
                                    props.password2 !== '' && props.password1 !== props.password2
                                        ? 'Passwords do not match'
                                        : ''
                                }
                                required
                            />
                        </Col>
                    </Row>
                )}

                <Row>
                    <Col xs={6}>
                        <TextField
                            value={props.firstName}
                            name="firstname"
                            onChange={(e) => props.onChange(e, false)}
                            type="text"
                            id="userformFirstname"
                            label="First name"
                            InputLabelProps={{
                                style: {
                                    zIndex: 9999
                                },
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <TextField
                            value={props.lastName}
                            name="lastname"
                            onChange={(e) => props.onChange(e, false)}
                            type="text"
                            id="userformLastname"
                            label="Last name"
                            InputLabelProps={{
                                style: {
                                    zIndex: 9999
                                },
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>
                        <TextField
                            value={props.phoneNumber}
                            name="phoneNumber"
                            onChange={(e) => props.onChange(e, false)}
                            type="text"
                            id="userformPhoneNumber"
                            label="Phone Number"
                            InputLabelProps={{
                                style: {
                                    zIndex: 9999
                                },
                            }}
                        />
                    </Col>
                    <Col xs={6}>
                        <TextField
                            value={props.description}
                            name="description"
                            onChange={(e) => props.onChange(e, false)}
                            type="text"
                            id="userformDesc"
                            label="Description"
                            InputLabelProps={{
                                style: {
                                    zIndex: 9999
                                },
                            }}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={6}>{renderUserLevelRadioButtons()}</Col>
                    <Col xs={6}>
                        <HubNodeSelector
                            canModify={props.userType <= UtilConstant.HUB_USER_TYPE.HUB_ADMIN}
                            initialNode={props.nodes
                                ? (props.nodes.find(function (node) {
                                    return node.id === props.nodeId;
                                }) || {}).name || ''
                                : ''}
                            onHubNodeSelected={props.onHubNodeSelected}
                            onDefaultHubNodeSelected={props.onHubNodeSelected}
                            nodeId={props.nodeId}
                            nodes={props.nodes || []}
                        />
                    </Col>
                </Row>

                {props.formError && <div className="errorMsg">{props.formError}</div>}

                <Button
                    color="light"
                    type="submit"
                    size="sm"
                    className="float-right mr-0"
                >
                    {props.isUpdateUser ? 'Save' : 'Create'}
                </Button>
            </Form>
        </div>
    );
}

export default HubUserFormEx;