import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import FolderFactory from '../../util/FolderFactory'
import * as HubConstant from "util/HubConstant"
import { DataTableConstant } from 'hub-dashboard-framework'
import { UtilConstant } from 'hub-utilities'

//dispatcher and actions here:
//To sync logo between About and Side Navbar


const FetchType = Object.freeze({
    NODES: 0,
    NODE_GROUP: 1,
    NODE_GROUP_NODES: 2,
    NEWSITEM_METADATA: 3,
    NEWSITEM_SUBOBJECT: 4,
    NEWSITEM_FOLDER: 5,
    ROOT_FOLDER: 6,

})

const apiFetch = (method, fetchType, isTable, ids, body, canAffectLoadingScreen) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: fetchType === FetchType.NODES ? "api/node/1/search/newsitem" :
             fetchType === FetchType.NODE_GROUP ? `/api/NodeGroup${method === "PUT" || method === "DELETE" ? `/${ids.nodeGroupId}` : ""}` :
             fetchType === FetchType.NODE_GROUP_NODES ? `/api/NodeGroup/${ids.nodeGroupId}/node${method !== "GET" ? `/${ids.nodeId}` : ""}` :
             fetchType === FetchType.NEWSITEM_METADATA ? `/api/NewsItem/${ids.newsItemId}/metadata` :  
             fetchType === FetchType.NEWSITEM_SUBOBJECT ? `/api/NewsItem/${ids.newsItemId}` :  
             fetchType === FetchType.NEWSITEM_FOLDER ? `api/v2/folder/${ids.folderId}/newsitem` :  
             fetchType === FetchType.ROOT_FOLDER ? `api/v2/CloudQ/Folder` :  
             '',

        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,

        begin: fetchType === FetchType.NODES ? setNodeFilterBegin :
               fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupBegin : method === "POST" ? addNodeGroupBegin : method === "PUT" ? setNodeGroupBegin : deleteNodeGroupBegin :
               fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesBegin : method === "POST" ? addNodeGroupNodesBegin : deleteNodeGroupNodesBegin :
               fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemBegin : 
               fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectBegin : 
               fetchType === FetchType.NEWSITEM_FOLDER ? getFolderToNewsItemBegin :
               fetchType === FetchType.ROOT_FOLDER ? getRootFoldersBegin :  
               null,
        success: fetchType === FetchType.NODES ? setNodeFilterSuccess :
                 fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupSuccess : method === "POST" ? addNodeGroupSuccess : method === "PUT" ? setNodeGroupSuccess : deleteNodeGroupSuccess :
                 fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesSuccess : method === "POST" ? addNodeGroupNodesSuccess : addNodeGroupNodesFailure :
                 fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemSuccess : 
                 fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectSuccess : 
                 fetchType === FetchType.NEWSITEM_FOLDER ? getFolderToNewsItemSuccess :
                 fetchType === FetchType.ROOT_FOLDER ? getRootFoldersSuccess :  
                 null,
        error: fetchType === FetchType.NODES ? setNodeFilterFailure :
               fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupFailure : method === "POST" ? addNodeGroupFailure : method === "PUT" ? setNodeGroupFailure : deleteNodeGroupFailure :
               fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesFailure : method === "POST" ? deleteNodeGroupNodesSuccess : deleteNodeGroupNodesFailure :
               fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemFailure : 
               fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectFailure : 
               fetchType === FetchType.NEWSITEM_FOLDER ? getFolderToNewsItemFailure :
               fetchType === FetchType.ROOT_FOLDER ? getRootFoldersFailure :  
               null,
        additionalParams: { ...(!!ids && !!ids.nodeGroupId && { id: ids.nodeGroupId }), 
                            ...(!!ids && !!ids.newsItemId && { newsItemId: ids.newsItemId }),
                            ...(isTable !== undefined && { isTable: isTable }),
                            ...(canAffectLoadingScreen !== undefined && { canAffectLoadingScreen: canAffectLoadingScreen })}
    }
});


const apiFolderSecurityFetch = (method, body, url, beginAction, successAction, failureAction) => async (dispatch) => {
    dispatch(beginAction());
    try {
        const token = await authService.getAccessToken();
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: body ? JSON.stringify(body) : undefined
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data);
        }
        dispatch(successAction(data));
        return data;
    } catch (error) {
        dispatch(failureAction(error));
        throw error;
    }
};

const fetchNodeContentsBegin = (objectType) => ({
    type: ActionType.FETCH_NODE_CONTENTS_BEGIN,
    payload: objectType
});

const fetchNodeContentsSuccess = payload => ({
    type: ActionType.FETCH_NODE_CONTENTS_SUCCESS,
    payload: payload
});

const fetchNodeContentsFailure = error => ({
    type: ActionType.FETCH_NODE_CONTENTS_FAILURE,
    payload: error
});

const setNodeFilterBegin = (objectType) => ({
    type: ActionType.SET_HUB_NODE_FILTER_BEGIN,
    payload: objectType
});

const setNodeFilterSuccess = payload => ({
    type: ActionType.SET_HUB_NODE_FILTER_SUCCESS,
    payload: payload
});

const setNodeFilterFailure = error => ({
    type: ActionType.SET_HUB_NODE_FILTER_FAILURE,
    payload: error
});

const getNodeGroupBegin = (additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_BEGIN,
    isTable: additionalParams.isTable
});

const getNodeGroupSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_SUCCESS,
    payload: payload,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const getNodeGroupFailure = (error, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_FAILURE,
    payload: error,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const addNodeGroupBegin = () => ({
    type: ActionType.ADD_HUB_NODE_GROUP_BEGIN
});

const addNodeGroupSuccess = (payload) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const addNodeGroupFailure = error => ({
    type: ActionType.ADD_HUB_NODE_GROUP_FAILURE,
    payload: error
});

const setNodeGroupBegin = () => ({
    type: ActionType.SET_HUB_NODE_GROUP_BEGIN
});

const setNodeGroupSuccess = (payload) => ({
    type: ActionType.SET_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const setNodeGroupFailure = error => ({
    type: ActionType.SET_HUB_NODE_GROUP_FAILURE,
    payload: error
});

const deleteNodeGroupBegin = () => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_BEGIN
});

const deleteNodeGroupSuccess = (payload) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const deleteNodeGroupFailure = (error) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_FAILURE,
    payload: error,
});

const getNodeGroupNodesBegin = (objectType) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_BEGIN,
    payload: objectType,
});

const getNodeGroupNodesSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload,
    nodeGroupId: additionalParams.id,
    isTable: additionalParams.isTable,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const getNodeGroupNodesFailure = (error, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error,
    isTable: additionalParams.isTable,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const addNodeGroupNodesBegin = () => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_BEGIN
});

const addNodeGroupNodesSuccess = (payload) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload
});

const addNodeGroupNodesFailure = (error) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error
});

const deleteNodeGroupNodesBegin = () => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_BEGIN
});

const deleteNodeGroupNodesSuccess = (payload) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload
});

const deleteNodeGroupNodesFailure = (error) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error,
});

const editNodeGroup = () => ({
    type: ActionType.EDIT_NODE_GROUP
});

const getMetadataToNewsItemBegin = () => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_BEGIN
})
const getMetadataToNewsItemSuccess = (payload, additionalParams) => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_SUCCESS,
    payload: payload,
    newsItemId: additionalParams.newsItemId
})
const getMetadataToNewsItemFailure = error => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_FAILURE,
    payload: error
})

const getNewsItemSubObjectBegin = (additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_BEGINS,
    newsItemId: additionalParams.newsItemId
})
const getNewsItemSubObjectSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_SUCCESS,
    payload: payload,
    newsItemId: additionalParams.newsItemId
})
const getNewsItemSubObjectFailure = (error, additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_FAILURE,
    payload: error,
    newsItemId: additionalParams.newsItemId
})

const getFolderToNewsItemBegin = () => ({
    type: ActionType.FETCH_NEWSITEM_FOLDER_BEGIN
});

const getFolderToNewsItemSuccess = (payload) => ({
    type: ActionType.FETCH_NEWSITEM_FOLDER_SUCCESS,
    payload: payload
});

const getFolderToNewsItemFailure= (error) => ({
    type: ActionType.FETCH_NEWSITEM_FOLDER_FAILURE,
    payload: error
});

const getRootFoldersBegin = () => ({
    type: ActionType.FETCH_ROOT_FOLDERS_BEGIN
})

const getRootFoldersSuccess = (payload) => ({
    type: ActionType.FETCH_ROOT_FOLDERS_SUCCESS,
    payload: payload
})

const getRootFoldersFailure = (error) => ({
    type: ActionType.FETCH_ROOT_FOLDERS_FAILURE,
    payload: error
})

const getFolderTreeBegin = () => ({
    type: ActionType.GET_FOLDER_TREE_BEGIN,
});

const getFolderTreeSuccess = (payload) => ({
    type: ActionType.GET_FOLDER_TREE_SUCCESS,
    payload: payload
});

const getFolderTreeFailure = (error) => ({
    type: ActionType.GET_FOLDER_TREE_FAILURE,
    payload: error
});

const deleteNewsItemInFolderBegin = () => ({
    type: ActionType.DELETE_NEWSITEM_IN_FOLDER_BEGIN
})

const deleteNewsItemInFolderSuccess = (payload) => ({
    type: ActionType.DELETE_NEWSITEM_IN_FOLDER_SUCCESS,
    payload: payload
})

const deleteNewsItemInFolderFailure = (error) => ({
    type: ActionType.DELETE_NEWSITEM_IN_FOLDER_FAILURE,
    payload: error
})


const moveNewsItemToOtherFolderSuccess = (payload) => ({
    type: ActionType.MOVE_NEWSITEM_OTHER_FOLDER_SUCCESS,
    payload: payload
})

const moveNewsItemToOtherFolderFailure = (error) => ({
    type: ActionType.MOVE_NEWSITEM_OTHER_FOLDER_FAILURE,
    payload: error
})

const addStoryToFolderSuccess = (payload) => ({
    type: ActionType.ADD_STORY_TO_FOLDER_SUCCESS,
    payload: payload
})

const addStoryToFolderFailure = (error) => ({
    type: ActionType.ADD_STORY_TO_FOLDER_FAILURE,
    payload: error
})


const setWorkingFolder = (folder) => ({
    type: ActionType.SET_WORKING_FOLDER,
    payload: folder
});

const fetchFolderSecurityGroupsBegin = () => ({
    type: ActionType.FETCH_FOLDER_SECURITY_GROUPS_BEGIN
});

const fetchFolderSecurityGroupsSuccess = payload => ({
    type: ActionType.FETCH_FOLDER_SECURITY_GROUPS_SUCCESS,
    payload
});

const fetchFolderSecurityGroupsFailure = error => ({
    type: ActionType.FETCH_FOLDER_SECURITY_GROUPS_FAILURE,
    payload: error
});

const addFolderSecurityGroupBegin = () => ({
    type: ActionType.ADD_FOLDER_SECURITY_GROUP_BEGIN
});

const addFolderSecurityGroupSuccess = payload => ({
    type: ActionType.ADD_FOLDER_SECURITY_GROUP_SUCCESS,
    payload
});

const addFolderSecurityGroupFailure = error => ({
    type: ActionType.ADD_FOLDER_SECURITY_GROUP_FAILURE,
    payload: error
});

const updateFolderSecurityGroupBegin = () => ({
    type: ActionType.UPDATE_FOLDER_SECURITY_GROUP_BEGIN
});

const updateFolderSecurityGroupSuccess = payload => ({
    type: ActionType.UPDATE_FOLDER_SECURITY_GROUP_SUCCESS,
    payload
});

const updateFolderSecurityGroupFailure = error => ({
    type: ActionType.UPDATE_FOLDER_SECURITY_GROUP_FAILURE,
    payload: error
});

const deleteFolderSecurityGroupBegin = () => ({
    type: ActionType.DELETE_FOLDER_SECURITY_GROUP_BEGIN
});

const deleteFolderSecurityGroupSuccess = payload => ({
    type: ActionType.DELETE_FOLDER_SECURITY_GROUP_SUCCESS,
    payload
});

const deleteFolderSecurityGroupFailure = error => ({
    type: ActionType.DELETE_FOLDER_SECURITY_GROUP_FAILURE,
    payload: error
});

const addUserToFolderSecurityGroupBegin = () => ({
    type: ActionType.ADD_USER_TO_FOLDER_SECURITY_GROUP_BEGIN
});

const addUserToFolderSecurityGroupSuccess = payload => ({
    type: ActionType.ADD_USER_TO_FOLDER_SECURITY_GROUP_SUCCESS,
    payload
});

const addUserToFolderSecurityGroupFailure = error => ({
    type: ActionType.ADD_USER_TO_FOLDER_SECURITY_GROUP_FAILURE,
    payload: error
});

const removeUserFromFolderSecurityGroupBegin = () => ({
    type: ActionType.REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_BEGIN
});

const removeUserFromFolderSecurityGroupSuccess = payload => ({
    type: ActionType.REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_SUCCESS,
    payload
});

const removeUserFromFolderSecurityGroupFailure = error => ({
    type: ActionType.REMOVE_USER_FROM_FOLDER_SECURITY_GROUP_FAILURE,
    payload: error
});

const editFolderSecurityGroup = () => ({
    type: ActionType.EDIT_FOLDER_SECURITY_GROUP
});

const setUpdateTrigger = (updateTrigger) => ({
    type: ActionType.SET_UPDATE_TRIGGER,
    bUpdateTrigger: updateTrigger,
})

//Helpers:
const fetchNodeGroups = async (isTable, canAffectLoadingScreen, dispatch, getState) => {
    await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined, canAffectLoadingScreen));

    const state = getState().hubObjectContent;
    for (var i = 0; i < state.hubNodeGroups.length; i++) {
        await dispatch(apiFetch("GET", FetchType.NODE_GROUP_NODES, isTable, { nodeGroupId: state.hubNodeGroups[i].groupId }, undefined, canAffectLoadingScreen));
    }
}

export const mapStateToHubObjectBoardProps = state => {
    return state.hubObjectContent;
}

export const mapDispatchToHubObjectBoardProps = {
    onDeleteObject: (objectType, objectId, folderId) => async (dispatch, getState) => {
        //Logger.debug(`deleting (${objectId}) of type ${objectType}`);
        if (objectType === HubConstant.HUB_OBJECT_STORY) {
            await hubApi.deleteStory(folderId, objectId)
        } else if (objectType === HubConstant.HUB_OBJECT_ELEMENT) {
            await hubApi.deleteElement(objectId)
        } else if (objectType === HubConstant.HUB_PRODUCTION_GROUP) {
            await hubApi.deleteNodeGroup(objectId)
        }
    },

    onDeleteNewsItemInFolder: (objectType, objectId, folderId) => async (dispatch, getState) => {

        dispatch(deleteNewsItemInFolderBegin(objectType));
        Logger.debug(`deleting story ${objectId}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/v2/folder/${folderId}/story/${objectId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (response.ok) {

            var newPayload = {};
            newPayload.id = objectId;
            //dispatch(deleteNewsItemInFolderSuccess(newPayload));
        }
        else {
            Logger.error('deleting story failed, response=', response);
           // dispatch(deleteNewsItemInFolderFailure(response.error));
        }
    },

    moveNewsItemToOtherFolder: (sourceFolderId, objectId, destinationFolderId, isTextSearchActive) => async (dispatch, getState) => {
        let body = {
            DestinationFolderId: destinationFolderId,
            IsFullTextSearchActive: isTextSearchActive
        }

        let token = await authService.getAccessTokenAsync();
        let response = await fetch(`/api/v2/folder/${sourceFolderId}/story/${objectId}/move`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(body),
        });

        if (response.ok) {

            var newPayload = {};
            newPayload.id = objectId;
            newPayload.isTextSearchActive = isTextSearchActive
            newPayload.destinationFolderId = destinationFolderId
            dispatch(moveNewsItemToOtherFolderSuccess(newPayload));
        }
        else {
            Logger.error('moving story failed, response=', response);
            dispatch(moveNewsItemToOtherFolderFailure(response.error));
        }
    },

    getNewsItemsByFolder: (folderId, searchText, action) => async (dispatch, getState) => {

        //objectType can be added to this eventually. ONLY STORIES FOR NOW
        const state = getState().hubObjectContent;
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING) {
            // Don't issue a duplicate request (we are loading or already have or are loading the requested data)
            Logger.debug(`Folder with id (${folderId}): already loaded or loading`);
            return;
        }

        dispatch(getFolderToNewsItemBegin());
        Logger.debug(`Loading newsitems from folder with id: ${folderId}`);

        let id = !!state.hubWorkingFolder && !folderId ? state.hubWorkingFolder.id : folderId;


        let token = await authService.getAccessTokenAsync();
        let reverse = true;

        //let queryString = (!!objectType && objectType !== 0) ? (objectType === HubConstant.HUB_OBJECT_STORY ? `?objectType=${HubConstant.HUB_OBJECT_STORY}` : `?objectType=${HubConstant.HUB_OBJECT_ELEMENT}`) : null;

        let url = `api/v2/folder/${id}/newsitem`;
        let paramToken = '?';
        //if (!!queryString) {
        //    //Logger.debug("QueryString is: " + queryString);
        //    url = url + queryString;
        //    paramToken = '&';
        //}
        if (searchText != null && searchText != '') {
            Logger.debug("searchText is: " + searchText);
            searchText = searchText.trim();
            if (searchText.length > 0) {
                url = url + `${paramToken}searchText=${searchText}`;
                reverse = false;
            }
            let readRightFolderIds = state.hubFolderTree.filter(folder =>
                (folder.folderPermissions & HubConstant.USER_FOLDER_PERMISSIONS.READ) === HubConstant.USER_FOLDER_PERMISSIONS.READ
            ).map(folder => folder.id);

            if (readRightFolderIds.length > 0) {
                url = url + `&readRightFolderIds[]=${readRightFolderIds.join('&readRightFolderIds[]=')}`;
            }
        }
        Logger.debug(`loading from ${url}`);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            // Logger.debug(`stories loaded`);
            let resp = await response.json();
            let payload = reverse ? resp.hubObjects.reverse() : resp.hubObjects;

            var newPayload = {};
            newPayload.hubObjects = payload;
            newPayload.action = action;

            dispatch(getFolderToNewsItemSuccess(newPayload));
        }
        else {
            //Logger.debug(`loading stories failed, error=${response.error}`);
            dispatch(getFolderToNewsItemFailure(response.error));
        }

    },

    setFilterForNewsItemInFolder: (folderId, objectType, nodeIds, metadata, action) => async (dispatch, getState) => {

        //objectType can be added to this eventually. ONLY STORIES FOR NOW
        const state = getState().hubObjectContent;
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING) {
            // Don't issue a duplicate request (we are loading or already have or are loading the requested data)
            Logger.debug(`Folder with id (${folderId}): already loaded or loading`);
            return;
        }

        dispatch(getFolderToNewsItemBegin());
        Logger.debug(`Loading newsitems from folder with id: ${folderId}`);

        let id = !!state.hubWorkingFolder && !folderId ? state.hubWorkingFolder.id : folderId;

        let token = await authService.getAccessTokenAsync();
        let reverse = true;


        let url = `api/v2/folder/${id}/newsitem`;

        var jsonToSend = {
            nodeFilter: {
                groups: [0],
                nodes: nodeIds
            },
            objectTypes: [objectType],
            ...!!metadata && metadata.name.length > 0 && {
                metadataFilters: [
                    {
                        fieldId: metadata.id,
                        values: metadata.name
                    }
                ]
            }
        }

        Logger.debug(`loading from ${url}`);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(jsonToSend),
        });

        if (response.ok) {
            // Logger.debug(`stories loaded`);
            let resp = await response.json();
            let payload = reverse ? resp.hubObjects.reverse() : resp.hubObjects;

            var newPayload = {};
            newPayload.hubObjects = payload;
            newPayload.action = action;

            dispatch(getFolderToNewsItemSuccess(newPayload));
        }
        else {
            //Logger.debug(`loading stories failed, error=${response.error}`);
            dispatch(getFolderToNewsItemFailure(response.error));
        }
    },

    loadNodeContents: (nodeId, objectType, searchText, action) => async (dispatch, getState) => {
        Logger.debug("Loadingnodecontent")
        const state = getState().hubObjectContent;
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING) {
            // Don't issue a duplicate request (we are loading or already have or are loading the requested data)
            Logger.debug(`NodeContents(${nodeId}): already loaded or loading`);
            return;
        }

        dispatch(fetchNodeContentsBegin(objectType));
        let token = await authService.getAccessTokenAsync();
        let user = await authService._user;
        Logger.debug('loading stories, current user:');
        Logger.debug(user);
        let reverse = true;
        //let endPointName = (!!objectType && objectType !== 0) ? (objectType === HubConstant.HUB_OBJECT_STORY ? 'story' : 'element') : 'content';
        //let url = `api/node/1/${endPointName}`;
        let queryString = (!!objectType && objectType !== 0) ? (objectType === HubConstant.HUB_OBJECT_STORY ? `?objectType=${HubConstant.HUB_OBJECT_STORY}` : `?objectType=${HubConstant.HUB_OBJECT_ELEMENT}`) : null;
        Logger.debug(`objectType is ${objectType}`);
        let url = `api/node/1/newsitem`;
        let paramToken = '?';
        if (!!queryString) {
            //Logger.debug("QueryString is: " + queryString);
            url = url + queryString;
            paramToken = '&';
        }
        if (nodeId > 0) {
            //Logger.debug("nodeId is: " + nodeId);
            url = url + `${paramToken}sourceNodeId=${nodeId}`;
            paramToken = '&';
        }
        if (searchText != null) {
            Logger.debug("searchText is: " + searchText);
            searchText = searchText.trim();
            if (searchText.length > 0) {
                url = url + `${paramToken}searchText=${searchText}`;
                reverse = false;
            }
        }
        Logger.debug(`loading from ${url}`);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            // Logger.debug(`stories loaded`);
            let resp = await response.json();
            let payload = reverse ? resp.hubObjects.reverse() : resp.hubObjects;

            var newPayload = {};
            newPayload.hubObjects = payload;
            newPayload.action = action;

            dispatch(fetchNodeContentsSuccess(newPayload));
        }
        else {
            //Logger.debug(`loading stories failed, error=${response.error}`);
            dispatch(fetchNodeContentsFailure(response.error));
        }
    },

    setNodeFilter: (nodeIds, objectType, metadata, searchText) => async (dispatch, getState) => {

        var jsonToSend = {
            nodeFilter: {
                groups: [0],
                nodes: nodeIds
            },
            objectTypes: [objectType],
            ...!!metadata && metadata.name.length > 0 && {metadataFilters: [
                {
                  fieldId: metadata.id,
                  values: metadata.name
                }
              ]
            }
        }
        //Fetch later to prevent animation lag from filter bar:
        setTimeout(function(){ dispatch(apiFetch("POST", FetchType.NODES, undefined, undefined, jsonToSend)); }, 150);
    },

    getNodeGroups: (isTable, canAffectLoadingScreen) => async (dispatch, getState) => {
        await fetchNodeGroups(isTable, canAffectLoadingScreen, dispatch, getState);
    },

    getRootFolders: () => async (dispatch, getState) => {
        const state = getState();
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING || state.error) {

            Logger.debug(`Root Folders already loaded or loading`);
            return;
        }
        dispatch(apiFetch("GET", FetchType.ROOT_FOLDER, true, undefined, undefined));
    },

    getFolderTree: () => async (dispatch, getState) => {
        const state = getState();
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING || state.error) {

            Logger.debug(`Folder Tree already loaded or loading`);
            return;
        }
        
        // get Root Folders from above
        getFolderTreeBegin();
        let rootFolders = [];
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`api/v2/CloudQ/Folder`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            }
        });

        if (response.ok) {
            const resp = await response.json();
            rootFolders = await Promise.all(resp.hubObjects.map(folder => FolderFactory.processFolderWithPermissions(tree,folder)));
        }
        else {
            //Logger.debug(`loading stories failed, error=${response.error}`);
            dispatch(getFolderTreeFailure(response.error));
        }
        Logger.debug(`Root Folders: ${rootFolders}`);
        async function buildTree(rootFolders, tree) {
            for (let i = 0; i < rootFolders.length; i++) {
                try {
                    tree.push(rootFolders[i]);
                    const children = await FolderFactory.getSubFolders(tree, rootFolders[i].id);
                    if (children.length > 0) {
                        await buildTree(children, tree);
                    }
                } catch (error) {
                    Logger.error('Error occurred while building tree:', error);
                }
            }
            return tree;
        }
        let tree = [];
        // Build the full tree
        const fullTree = await buildTree(rootFolders, tree);
        Logger.debug("fullTree Folders: ", fullTree);

        if (fullTree.length > 0)
        dispatch(getFolderTreeSuccess(fullTree));
    },

    getNewsItemsByFolder_deprecate: (folderId) => async (dispatch, getState) => {

        Logger.debug(`Loading newsitems from folder with id: ${folderId}`);
        var state = getState()
        if (state.hubObjectContent.hubWorkingFolder && !folderId) {
            var id = getState().hubObjectContent.hubWorkingFolder.id
            dispatch(apiFetch("GET", FetchType.NEWSITEM_FOLDER, true, { folderId: id }, undefined));
        }
        else {
            dispatch(apiFetch("GET", FetchType.NEWSITEM_FOLDER, true, { folderId: folderId }, undefined));
        }
    },

    createNodeGroup: (body, nodesToAdd) => async (dispatch, getState) => {
        //Logger.debug("isTable", isTable)
        //await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined));
        await dispatch(apiFetch("POST", FetchType.NODE_GROUP, undefined, undefined, body));

        nodesToAdd.forEach(async node => {
            await dispatch(apiFetch("POST", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: getState().hubObjectContent.nodeGroupIdJustAdded, nodeId: node.id }, undefined));
        });

        //await HubConfigActions.fetchNodes(dispatch, getState);
        await fetchNodeGroups(true, true, dispatch, getState);
    },

    updateNodeGroups: (nodeGroupId, body, nodesToAdd, nodesToDelete) => async (dispatch, getState) => {
        //Logger.debug("isTable", isTable)
        //await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined));
        await dispatch(apiFetch("PUT", FetchType.NODE_GROUP, undefined, { nodeGroupId: nodeGroupId }, body));

        nodesToAdd.forEach(async node => {
            await dispatch(apiFetch("POST", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });

        nodesToDelete.forEach(async node => {
            await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });

        //await HubConfigActions.fetchNodes(dispatch, getState);
        await fetchNodeGroups(true, true, dispatch, getState);
    },

    deleteNodeGroup: (nodeGroupId, nodesToDelete) => async (dispatch, getState) => {

        //Bryan: BUG 356 commented out, concurrent issue, there is no guarantee that the api calls to remove node finishes 
        //       before the api call to remove nodegroup. We get the delete voilation error if the api all to remove the nodegroup 
        //       is executed first
        /*
        nodesToDelete.forEach(async node => {
            await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });
        */
        await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP, undefined, { nodeGroupId: nodeGroupId }, undefined));

        await fetchNodeGroups(true, true, dispatch, getState);
    },

    onStoryCreated: (story) => async (dispatch, getState) => {
        //dispatch(addStoryToFolderSuccess(story));
    },

    editNodeGroup: () => async (dispatch, getState) => {
        dispatch(editNodeGroup());
    },

    getNewsItemMetadata: (newsItemId) => async (dispatch, getState) => {
        dispatch(apiFetch("GET", FetchType.NEWSITEM_METADATA, true, { newsItemId: newsItemId }, undefined));
    },
    getNewsItemSubObject: (newsItemId) => async (dispatch, getState) => {
        await dispatch(apiFetch("GET", FetchType.NEWSITEM_SUBOBJECT, true, { newsItemId: newsItemId }, undefined));
    },
    onFinishStoryUpdate: () => async (dispatch, getState) => {
        dispatch(setUpdateTrigger(false));
    },
    setWorkingFolder: (folder) => async (dispatch) => {
        dispatch(setWorkingFolder(folder));
    },

    getFolderSecurityGroups: () => async (dispatch) => {
        await dispatch(apiFolderSecurityFetch(
                "GET",
                undefined,
                "/api/v2/foldersecurity/groups",
                fetchFolderSecurityGroupsBegin,
                fetchFolderSecurityGroupsSuccess,
                fetchFolderSecurityGroupsFailure
            ));
        },

    addFolderSecurityGroup: (body, usersToAdd) => async (dispatch) => {
        const response = await dispatch(apiFolderSecurityFetch(
            "POST",
            body,
            "/api/v2/foldersecurity/groups",
            addFolderSecurityGroupBegin,
            addFolderSecurityGroupSuccess,
            addFolderSecurityGroupFailure
        ));
        let group = response.folderSecurityGroup;
        if (!!group && !!group.groupId) {
            await Promise.all(usersToAdd.map(async user => {
                await dispatch(mapDispatchToHubObjectBoardProps.addUserToFolderSecurityGroup(group.groupId, user.id));
            }));
        }
        await dispatch(mapDispatchToHubObjectBoardProps.getFolderSecurityGroups());
    },

    updateFolderSecurityGroup: (groupId, body, usersToAdd, usersToDel) => async (dispatch) => {

        await dispatch(apiFolderSecurityFetch(
            "PUT",
            body,
            `/api/v2/foldersecurity/groups/${groupId}`,
            updateFolderSecurityGroupBegin,
            updateFolderSecurityGroupSuccess,
            updateFolderSecurityGroupFailure
        ));

        await Promise.all([
            ...usersToAdd.map(async user => {
                await dispatch(mapDispatchToHubObjectBoardProps.addUserToFolderSecurityGroup(groupId, user.id));
            }),
            ...usersToDel.map(async user => {
                await dispatch(mapDispatchToHubObjectBoardProps.removeUserFromFolderSecurityGroup(groupId, user.id));
            })
        ]);
        
        await dispatch(mapDispatchToHubObjectBoardProps.getFolderSecurityGroups());
    },

    deleteFolderSecurityGroup: (groupId) => async (dispatch) => {
        await dispatch(apiFolderSecurityFetch(
            "DELETE",
            undefined,
            `/api/v2/foldersecurity/groups/${groupId}`,
            deleteFolderSecurityGroupBegin,
            deleteFolderSecurityGroupSuccess,
            deleteFolderSecurityGroupFailure
        ));
        await dispatch(mapDispatchToHubObjectBoardProps.getFolderSecurityGroups());
    },

    addUserToFolderSecurityGroup: (groupId, userId) => async (dispatch) => {
        await dispatch(apiFolderSecurityFetch(
            "POST",
            undefined,
            `/api/v2/foldersecurity/groups/${groupId}/user/${userId}`,
            addUserToFolderSecurityGroupBegin,
            addUserToFolderSecurityGroupSuccess,
            addUserToFolderSecurityGroupFailure
        ));
    },

    removeUserFromFolderSecurityGroup: (groupId, userId) => async (dispatch) => {
        await dispatch(apiFolderSecurityFetch(
            "DELETE",
            undefined,
            `/api/v2/foldersecurity/groups/${groupId}/user/${userId}`,
            removeUserFromFolderSecurityGroupBegin,
            removeUserFromFolderSecurityGroupSuccess,
            removeUserFromFolderSecurityGroupFailure
        ));
    },

    editFolderSecurityGroup: () => async (dispatch) => {
        dispatch(editFolderSecurityGroup());
    },
};
