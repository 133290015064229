import * as Logger from 'loglevel';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'

//dispatcher and actions here:
//To sync logo between About and Side Navbar

const apiFetch = (method, id, body) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: `/api/GeoArea${!!id ? `/${id}` : ''}`,
        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,

        begin: (method === "GET") ? fetchGeoBegin
            : (method === "POST") ? addGeoBegin
                : (method === "PUT") ? updateGeoBegin : deleteGeoBegin,

        success: (method === "GET") ? fetchGeoSuccess
            : (method === "POST") ? addGeoSuccess
                : (method === "PUT") ? updateGeoSuccess : deleteGeoSuccess,

        error: (method === "GET") ? fetchGeoFailure
            : (method === "POST") ? addGeoFailure
                : (method === "PUT") ? updateGeoFailure : deleteGeoFailure,
    }
});

const fetchGeoBegin = () => ({
    type: ActionType.FETCH_GEO_BEGIN,
});

const fetchGeoSuccess = payload => ({
    type: ActionType.FETCH_GEO_SUCCESS,
    payload: payload
});

const fetchGeoFailure = error => ({
    type: ActionType.FETCH_GEO_FAILURE,
    payload: error
});

const addGeoBegin = () => ({
    type: ActionType.ADD_GEO_BEGIN,
});

const addGeoSuccess = payload => ({
    type: ActionType.ADD_GEO_SUCCESS,
    payload: payload
});

const addGeoFailure = error => ({
    type: ActionType.ADD_GEO_FAILURE,
    payload: error
});

const updateGeoBegin = () => ({
    type: ActionType.UPDATE_GEO_BEGIN,
});

const updateGeoSuccess = payload => ({
    type: ActionType.UPDATE_GEO_SUCCESS,
    payload: payload
});

const updateGeoFailure = error => ({
    type: ActionType.UPDATE_GEO_FAILURE,
    payload: error
});

const deleteGeoBegin = () => ({
    type: ActionType.DELETE_GEO_BEGIN,
});

const deleteGeoSuccess = payload => ({
    type: ActionType.DELETE_GEO_SUCCESS,
    payload: payload
});

const deleteGeoFailure = error => ({
    type: ActionType.DELETE_GEO_FAILURE,
    payload: error
});

const editGeoInput = () => ({
    type: ActionType.EDIT_GEO_INPUT
});


export const mapGeoStateToProps = state => {
    Logger.debug(`map Logo props, state=`, state);
    return state.hubConfig.hubLogo;
};

const parseArrIntoPolyStr = location => {
    var locString = 'POLYGON((';
    for (var i = 0; i < location.length; i++) {
        locString += `${location[i].lat} ${location[i].lon}${i < location.length - 1 ? "," : ""}`;
    }
    locString += '))';

    return locString;
}

const GeoFetch = async (dispatch) => {
    await dispatch(apiFetch("GET", undefined, undefined));
}

const GeoAdd = async (dispatch, name, location, description) => {
    await dispatch(apiFetch("POST", undefined, { areaName: name, geoData: parseArrIntoPolyStr(location), description: description }));
}

const GeoUpdate = async (dispatch, geoAreaList, name, location, description) => {
    //get Id:
    var geoObj = geoAreaList.find(elem => elem.geoDesc.areaName === name);
    await dispatch(apiFetch("PUT", geoObj.areaId, { areaName: name, geoData: parseArrIntoPolyStr(location), description: description }));
    await dispatch(apiFetch("GET", undefined, undefined));
}

const GeoDelete = async (dispatch, geoAreaList, name, location, description) => {
    //get Id:
    var geoObj = geoAreaList.find(elem => elem.geoDesc.areaName === name);
    await dispatch(apiFetch("DELETE", geoObj.areaId, undefined));
    await dispatch(apiFetch("GET", undefined, undefined));
}

export const mapGeoDispatcherToProps = {

    startGeoFetch: () => async (dispatch, getState) => {
        await GeoFetch(dispatch);
    },

    startGeoAdd: (name, location, description) => async (dispatch, getState) => {
        await GeoAdd(dispatch, name, location, description)//.then(() => GeoFetch(dispatch));
    },

    startGeoUpdate: (name, location, description) => async (dispatch, getState) => {
        await GeoUpdate(dispatch, getState().hubConfig.hubGeo.geoObj.geoAreaList, name, location, description);
    },

    startGeoDelete: (name) => async (dispatch, getState) => {
        await GeoDelete(dispatch, getState().hubConfig.hubGeo.geoObj.geoAreaList, name);
    },

    editGeoInput: () => async (dispatch, getState) => {
        dispatch(editGeoInput())
    }
};