import React, { useRef, Fragment, useState, useEffect } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import * as Logger from 'loglevel';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import  FolderTree  from "./FolderTree";
import '../../../styles/widgets/MoveFolderTreeSelector.scss';
import ClipLoader from 'react-spinners/ClipLoader';

export function MoveNewsItemFolderForm(props) {
    const { payload: moveData } = props;
    const [searchMode, setSearchMode] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [workingFolder, setWorkingFolder] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const form = useRef(null);

    useEffect(() => {
        const syncTreeData = async () => {
            try {
                var curFolder = getFolderObjectById(props.hubFolderTree, moveData.folderId)
                if (!curFolder) {
                    setError('Error occurred while syncing tree data!')
                } else {
                    await setWorkingFolder(curFolder);
                }
                setSearchMode(moveData.isFullTextSearchActive)
                setIsLoading(false);
            } catch (e) {
                Logger.error('Error occurred while syncing tree data:', e);
                setError('Error occurred while syncing tree data!')
            }
        };

        syncTreeData();
    }, []);

    function getFolderObjectById(tree, folderId) {
        return tree.find((f) => f.id === folderId);
    }

    const moveNewsItem = async (selectedFolder) => {
        try {
            let sourceFolderId = workingFolder.id;
            let storyId = moveData.id;
            let destinationFolderId = selectedFolder.id;

            props.moveNewsItemToOtherFolder(sourceFolderId, storyId, destinationFolderId, searchMode);
            props.onFormSubmitted();
        } catch (error) {
            Logger.error('Error occurred while moving news item:', error);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();

        if (!!selectedFolder) {
           try {
               await moveNewsItem(selectedFolder);
           } catch (error) {
               Logger.error('Error occurred while submitting form:', error);
           }
        }
    };

    const handleFolderSelect = async (folder) => {
        setSelectedFolder(folder);
    };

    return (
        <Form ref={form} onSubmit={onSubmit}>
            <Row>
                <Col>
                    <div className="MoveFolderTreeSelector">
                        {isLoading || workingFolder === null ? (
                            <div className="loadingSpinner">
                                <ClipLoader color={"#fff"} size={75} css={{ display: 'block', margin: 3, borderColor: '#f69b1d' }} />
                            </div>
                        ) : (
                            <FolderTree
                                displayMode={true}
                                handleFolderSelect={handleFolderSelect}
                                workingFolder={workingFolder}
                            />
                        )}
                    </div>
                </Col>
            </Row>
            {error ? (
                <div className='errorMsg' style={{ textAlign: "center" }}>{error}</div>
            ) : (
                <Fragment />
            )}
            <Button variant="light" type="submit">
                Save
            </Button>
        </Form>
    );
}

const mapStateToProps = (state) => {
    return {
        hubRootFolders: state.hubObjectContent.hubRootFolders,
        hubFolderTree: state.hubObjectContent.hubFolderTree,
    };
};

export default withRouter(
    connect(mapStateToProps, (dispatch) => bindActionCreators({ ...mapDispatchToHubObjectBoardProps }, dispatch))(
        MoveNewsItemFolderForm
    )
);
