import { UserManager } from 'oidc-client';
import * as Logger from 'loglevel';


export class TokenRenewer {
    completeRenew = () => {
        new UserManager().signinSilentCallback();
        Logger.debug(`------------ completeRenew callback done!`);
    };
}
const tokenRenewer = new TokenRenewer();
export default tokenRenewer;
