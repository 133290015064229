import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as Logger from 'loglevel';
import { createHubScnClientMiddleware } from "./scn/ScnClient";
import { createHubFtClientMiddleware } from "./ft/FtClient";
import * as HubConfig from './reducers/HubConfig/HubConfig';
import * as HubObjectContent from './reducers/Content/HubObjectContent';
import * as HubMetrics from './reducers/HubMetrics';

import api from 'store/middleware/api'

export default function configureStore(history, initialState, isDevelopment) {
    Logger.debug('configureStore started');

    const reducers = {
        hubConfig: HubConfig.reducer,
        hubObjectContent: HubObjectContent.reducer,
        hubMetrics: HubMetrics.reducer
    };

    const middleware = [
        createHubScnClientMiddleware('/ScnHub'),
        createHubFtClientMiddleware('/FtHub'),
        routerMiddleware(history),
        thunk,
        api
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];

    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    Logger.debug('configureStore: creating store');
    Logger.debug(initialState);
    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}