import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { Row, Col, Card, Overlay, Form, Button, Tab, Tabs, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusSquare, faMinusSquare, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import AboutSettings from '../../../../widgets/Settings/AboutSettings';

//redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as HubLogoActions from 'store/actions/HubLogoActions';
import * as HubSettingsActions from 'store/actions/HubSettingsActions';
import * as HubGeoActions from 'store/actions/HubGeoActions';

import * as HubConstant from 'util/HubConstant';
import Utilities, { UtilConstant } from 'hub-utilities';
import * as _ from 'lodash';

import 'styles/main-content/About.scss';
import 'styles/widgets/HubForm.scss';
import ProductionGroup from './ProductionGroup';
import MetaData from './Metadata';
import FolderSecurityGroup from './FolderSecurityGroup'
import * as DataRenderHelper from 'util/DataRenderHelper'
import { useEffect, useRef, useState } from 'react';

function Hub(props) {

    const [actualData, setActualData] = useState({});
    const [showAboutSettings, setShowAboutSettings] = useState(false);
    const [aboutSettingsTarget, setAboutSettingsTarget] = useState(null);
    const [cleanUpSettings, setCleanUpSettings] = useState([]);
    const [schedulesSettings, setSchedulesSettings] = useState([]);
    const [isEditingInput, setIsEditingInput] = useState(false);
    const [currentGeoTab, setCurrentGeoTab] = useState(HubConstant.HUB_GEO_FIELD_STATE.ADD);
    const [geoList, setGeoList] = useState([]);
    const [backgroundImageFile, setBackgroundImageFile] = useState();
    const [imgErrorStatus, setImgErrorStatus] = useState(null);
    const [currentGeo, setCurrentGeo] = useState({
        name: "",
        loc: [{ lat: "", lon: "" }, { lat: "", lon: "" }, { lat: "", lon: "" }],
        description: ""
    });
    const [target, setTarget] = useState(null);
    
    const sampleData = {
        "hubInfo": {
            "hubId": "Unknown",
            "name": "Unknown",
            "description": "Unknown",
            "version": "Unknown",
            "createTime": "Unknown",
            "expireTime": "Unknown",
            "nodes": [
                { "type": -1, "count": -1 }
            ],
            "users": -1
        },
        "environment": {
            "hostEnvironment": "Unknown",
            "identityServer": "Unknown",
            "connectionString": "Unknown",
        },
        "runtime": {
            "stories": -1,
            "elememnts": -1,
            "mediaSize": -1
        }
    };

    const aboutSettingsContinerRef = useRef(null);
    const refs = useRef(null);
    const logoUploaderRef = useRef(null);


    //Geo: =================================================

    const setGeoName = (name) => {
        props.editGeoInput();       
        setCurrentGeo({
            name: name,
            loc: (!!currentGeo.loc) ? currentGeo.loc : undefined,
            description: (currentGeo.description !== undefined) ? currentGeo.description : undefined
        })
    }

    const addLoc = () => {
        props.editGeoInput();
        var newCurrentGeoLoc = currentGeo.loc;
        newCurrentGeoLoc.push({ lat: "", lon: "" }); 
        setCurrentGeo({
            name: (currentGeo.name !== undefined) ? currentGeo.name : undefined,
            loc: newCurrentGeoLoc,
            description: (currentGeo.description !== undefined) ? currentGeo.description : undefined
        })
    }

    const removeLoc = (index) => {
        props.editGeoInput();
        var newCurrentGeoLoc = currentGeo.loc;
        newCurrentGeoLoc.splice(index, 1);
        setCurrentGeo({
            name: (currentGeo.name !== undefined) ? currentGeo.name : undefined,
            loc: newCurrentGeoLoc,
            description: (currentGeo.description !== undefined) ? currentGeo.description : undefined
        })
    }

    const setGeoLoc = (isLat, loc, pointIndex) => {
        props.editGeoInput();
        if (/^\d*\.?\d*$/.test(loc)) {
            var newCurrentGeoLoc = currentGeo.loc;
            isLat ? newCurrentGeoLoc[pointIndex].lat = loc : newCurrentGeoLoc[pointIndex].lon = loc;
            setCurrentGeo({
                name: (currentGeo.name !== undefined) ? currentGeo.name : undefined,
                loc: newCurrentGeoLoc,
                description: (currentGeo.description !== undefined) ? currentGeo.description : undefined
            })
        }
    }

    const setGeoDescription = (description) => {
        props.editGeoInput();
        setCurrentGeo({
            name: (currentGeo.name !== undefined) ? currentGeo.name : undefined,
            loc: (!!currentGeo.loc) ? currentGeo.loc : undefined,
            description: description
        })
    }

    //======================================================

    const Refresh = async (isUpdatingLicense) => {
        //Fetch schedule and purge settings here:
        await props.startSettingsFetch(isUpdatingLicense);
        setCleanUpSettings([
            { key: "objectKeepTime", preTitle: "Send Stories to Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.objectKeepTime, isAdvanced: false },
            { key: "gcKeepTime", preTitle: "Permanently delete items from Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.gcKeepTime, isAdvanced: false },
            { key: "batchCount", preTitle: "Delete stories in batches of ", exTitle: "", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.batchCount, isAdvanced: true },
            { key: "expiryExt", preTitle: "Extend expiry time by ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.expiryExt, isAdvanced: true }
        ]);
        setSchedulesSettings([
            { key: "normal", preTitle: "Standard maintenance checks occur every ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: props.settings.schedule.normal, isAdvanced: false },
            { key: "fast", preTitle: "Incomplete maintenance resumes after ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: props.settings.schedule.fast, isAdvanced: false }
        ])
    }

    useEffect(() => {
        const fetchSettings = async () => {
            await props.startSettingsFetch(false); 
        }
        fetchSettings();
    }, [])


    useEffect(() => {
        props.setActivePageName({ singular: "Hub", plural: "Hub", icon: faChromecast });
        if (!!props.hubInfo) {
            setActualData(props.hubInfo)
        } else {
            setActualData(sampleData);
        }
    }, [props.hubInfo]);

    useEffect(() => {
        if (!_.isEmpty(schedulesSettings) && !_.isEmpty(cleanUpSettings)) {
            var newCleanUpSettings = [...cleanUpSettings];
            var newSchedulesSettings = [...schedulesSettings];
            newCleanUpSettings.forEach(cleanUpSetting => cleanUpSetting.value = props.settings.purge[cleanUpSetting.key])
            newSchedulesSettings.forEach(scheduleSetting => scheduleSetting.value = props.settings.schedule[scheduleSetting.key])
            setCleanUpSettings(newCleanUpSettings);
            setSchedulesSettings(newSchedulesSettings);
            setActualData(props.hubInfo);
        } else {
            if (props.settings) {
                setCleanUpSettings([
                    { key: "objectKeepTime", preTitle: "Send Stories to Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.objectKeepTime, isAdvanced: false },
                    { key: "gcKeepTime", preTitle: "Permanently delete items from Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.gcKeepTime, isAdvanced: false },
                    { key: "batchCount", preTitle: "Delete stories in batches of ", exTitle: "", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.batchCount, isAdvanced: true },
                    { key: "expiryExt", preTitle: "Extend expiry time by ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: props.settings.purge.expiryExt, isAdvanced: true }
                ]);
                setSchedulesSettings([
                    { key: "normal", preTitle: "Standard maintenance checks occur every ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: props.settings.schedule.normal, isAdvanced: false },
                    { key: "fast", preTitle: "Incomplete maintenance resumes after ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: props.settings.schedule.fast, isAdvanced: false }
                ])
            }
        }
        

    }, [props.settings, props.hubInfo]);


    const flipShowSettingState = (e) => {
        var newShowSetting = !showAboutSettings;
        setShowAboutSettings(newShowSetting)
        setAboutSettingsTarget(e.target);
    }

    const setHubName = (val) => {
        var newHubData = { ...actualData };
        if (!!newHubData && newHubData["hubInfo"]) {
            newHubData["hubInfo"].customerName = val;
        }
        setActualData(newHubData, () => props.startEditingInput())
    }

    const setCleanUpHubSettingsInputVal = (hubSettingKey, val) => {

        let keyCleanUpIndex = cleanUpSettings.findIndex(setting => setting.key === hubSettingKey)
        var newAboutSetting = Object.assign({}, cleanUpSettings[keyCleanUpIndex]);
        newAboutSetting.value = val;

        var newAboutSettingsArr = [...cleanUpSettings];
        newAboutSettingsArr.splice(keyCleanUpIndex, 1, newAboutSetting);

        setCleanUpSettings(newAboutSettingsArr);
    }


    function renderCleanUpSettingsOnAdvanced(isAdvanced, cleanUpSettings) {
        return cleanUpSettings.map((settingInfo, index) =>
            (settingInfo.isAdvanced === isAdvanced) ?
                <p key={`hubSettingsAdvanced_${index}`} className="AboutInfo">
                    <span className="settingsName">{settingInfo.preTitle}
                        <Form.Control
                            key={settingInfo.key}
                            name={settingInfo.key}
                            type="text"
                            className="settingsInput"
                            placeholder={settingInfo.key}
                            value={settingInfo.value}
                            onChange={e => setCleanUpHubSettingsInputVal(settingInfo.key, e.target.value)} />
                        {settingInfo.exTitle}</span>
                </p> : <Fragment key={`hubSettingsAdvanced_${index}`}></Fragment>
        )
    }

    function setScheduleHubSettingsInputVal(hubSettingKey, val) {
        let keyScheduleIndex = schedulesSettings.findIndex(setting => setting.key === hubSettingKey)
        var newAboutSetting = Object.assign({}, schedulesSettings[keyScheduleIndex]);
        newAboutSetting.value = val;

        var newAboutSettingsArr = [...schedulesSettings];
        newAboutSettingsArr.splice(keyScheduleIndex, 1, newAboutSetting);

        setSchedulesSettings(newAboutSettingsArr);
    }

    function renderScheduleSettingsOnAdvanced(isAdvanced, schedulesSettings) {
        return schedulesSettings.map((settingInfo, index) =>
            (settingInfo.isAdvanced === isAdvanced) ?
                <p key={`hubSettingsAdvanced_${index}`} className="AboutInfo">
                    <span className="settingsName">{settingInfo.preTitle}
                        <Form.Control
                            key={settingInfo.key}
                            name={settingInfo.key}
                            type="text"
                            className="settingsInput"
                            placeholder={settingInfo.key}
                            value={settingInfo.value}
                            onChange={e => setScheduleHubSettingsInputVal(settingInfo.key, e.target.value)} />
                        {settingInfo.exTitle}</span>
                </p> : <Fragment key={`hubSettingsAdvanced_${index}`}></Fragment>
        )
    }


    function renderHubSettings() {
        return (
            <Fragment>
                <div className="subSettingContainer">
                    <h5>DATA PURGE</h5>
                    <p className="purgeDesc"><i>Configure when old stories and media are automatically removed from this Hub</i></p>
                    {renderCleanUpSettingsOnAdvanced(false, cleanUpSettings)}
                </div>
                <div className="subSettingContainer">
                    <h5>ADVANCED</h5>
                    {renderCleanUpSettingsOnAdvanced(true, cleanUpSettings)}
                </div>
            </Fragment>
        )
    }

    const updateSettings = (isReset) => {
        if (!!props.settings) {
            var newScheduleObj = {};
            var newPurgeObj = {};

            if (!isReset) {
                let aboutSettings = cleanUpSettings.concat(schedulesSettings);

                for (var i = 0; i < aboutSettings.length; i++) {
                    switch (aboutSettings[i].type) {
                        case HubConstant.HUB_SETTINGS_TYPE.SCHEDULE:
                            newScheduleObj[aboutSettings[i].key] = parseInt(aboutSettings[i].value);
                            break;
                        case HubConstant.HUB_SETTINGS_TYPE.PURGE:
                            newPurgeObj[aboutSettings[i].key] = parseInt(aboutSettings[i].value);
                            break;
                        default:
                            break;
                    }
                }
            }
            props.startUpdateSettings(newScheduleObj, newPurgeObj, !isReset);
        }
    }

    const onGeoButtonClick = async (isDelete) => {

        var locPolygon = currentGeo.loc;

        switch (parseInt(currentGeoTab)) {
            case HubConstant.HUB_GEO_FIELD_STATE.ADD:
                locPolygon.push(currentGeo.loc[0]);
                await props.startGeoAdd(currentGeo.name, locPolygon, currentGeo.description);
                locPolygon.pop();
                break;
            case HubConstant.HUB_GEO_FIELD_STATE.MODIFY:
                if (!isDelete) {
                    await props.startGeoUpdate(currentGeo.name, locPolygon, currentGeo.description);
                } else {
                    await props.startGeoDelete(currentGeo.name);

                    if (!!props.geoObj && props.geoObj.geoAreaList.length > 0) {                       
                        await setCurrentGeo({
                            name: props.geoObj.geoAreaList[0].geoDesc.areaName,
                            loc: props.geoObj.geoAreaList[0].geoDesc.geoData,
                            description: props.geoObj.geoAreaList[0].geoDesc.description
                        })
                    } else {                      
                        await setCurrentGeo({
                            name: undefined,
                            loc: undefined,
                            description: undefined
                        })
                    }
                }
                break;
            default:
                break;
        }
    }

    function renderUpdateStatus(success, error) {
        if (!!error) {
            return <Row><Col xs="12"><p className="UpdateError">Error {error.status}: {error.statusText}</p></Col></Row>
        } else if (!!success) {
            return <Row><Col xs="12"><p style={{color: "blue"}} className="Success">{success}</p></Col></Row>
        }
    }

    function renderGeoStatus() {
        if (!!props.geoError) {
            return <p className="UpdateError">Error {props.geoError.status}: {props.geoError.statusText}</p>
        } else if (!!props.geoSuccess) {
            return <p className="Success">{props.geoSuccess}</p>
        }
    }

    function renderGeoDropdown(geoList) {
        return geoList.map((geoElem, index) => {
            return (<Dropdown.Item eventKey={index} onSelect={() =>              
                setCurrentGeo({
                    name: geoElem.geoDesc.areaName,
                    loc: geoElem.geoDesc.geoData,
                    description: geoElem.geoDesc.description
                })
            }>{geoElem.geoDesc.areaName}</Dropdown.Item>)
        })
    }


    function renderGeoFields(hasGeoDropdown, hasGeoName, hasGeoLoc, hasGeoDescription, hasDeleteButton) {
        return (
            <Fragment>
                {
                    hasGeoDropdown ?
                        <div>
                            <span><b>Location Chosen:</b>&nbsp;</span>
                            <Dropdown className="pageDropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {currentGeo.name === undefined ? "No Available Location" : currentGeo.name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="pageDropdownItems">
                                    {renderGeoDropdown(!!props.geoObj ? props.geoObj.geoAreaList : [])}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> : <Fragment></Fragment>
                }
                {hasGeoName && currentGeo.description !== undefined ?
                    <div>
                        <span><b>Name:</b>&nbsp;</span>
                        <span className="settingsInputSpan"><Form.Control
                            key="geoName"
                            type="text"
                            className="settingsInput"
                            value={currentGeo.name}
                            onChange={e => setGeoName(e.target.value)} /></span>
                    </div> : <Fragment></Fragment>}
                {hasGeoLoc && !!currentGeo.loc ?
                    <div>
                        <span><b>Location:</b>&nbsp;</span>
                        <span onClick={addLoc}>
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        Add Location
                                </Tooltip>
                                }
                            >
                                <FontAwesomeIcon className="addLocButton" icon={faPlusSquare} />
                            </OverlayTrigger>
                        </span>
                        {currentGeo.loc.map((loc, index) => {
                            return (
                                <div>
                                    <span className="settingsInputSpan">
                                        <Form.Control
                                            key={`Latitude${index}`}
                                            placeholder="Latitude"
                                            type="text"
                                            className="settingsInput geoLatInput"
                                            value={loc.lat}
                                            onChange={e => setGeoLoc(true, e.target.value, index)} />
                                        <Form.Control
                                            key={`Longitude${index}`}
                                            placeholder="Longitude"
                                            type="text"
                                            className="settingsInput geoLonInput"
                                            value={loc.lon}
                                            onChange={e => setGeoLoc(false, e.target.value, index)} />
                                        <OverlayTrigger
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip-bottom`}>
                                                    Remove Location
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon onClick={() => removeLoc(index)} className={`addLocButton remove ${(currentGeo.loc.length <= 3) ? "disabled" : ""}`} icon={faMinusSquare} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            )
                        })
                        }
                    </div> : <Fragment></Fragment>}
                {hasGeoDescription && currentGeo.description !== undefined ?
                    <div>
                        <span><b>Description:</b>&nbsp;</span>
                        <span className="settingsInputSpan"><Form.Control
                            key="geoName"
                            type="text"
                            className="settingsInput geoInputs"
                            value={currentGeo.description}
                            onChange={e => setGeoDescription(e.target.value)} /></span>
                    </div> : <Fragment></Fragment>}

                {renderGeoStatus()}

                {currentGeo.name !== undefined ?
                    <Button onClick={() => onGeoButtonClick(false)}>
                        {currentGeoTab === HubConstant.HUB_GEO_FIELD_STATE.ADD ? "Add Location"
                            : currentGeoTab === HubConstant.HUB_GEO_FIELD_STATE.MODIFY ? "Modify Location"
                                : "Big Goof!"}
                    </Button> : <Fragment></Fragment>
                }

                {hasDeleteButton && currentGeo.name !== undefined ?
                    <Button className="deleteBtn" onClick={() => onGeoButtonClick(true)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button> : <Fragment></Fragment>}
            </Fragment>
        )
    }

    const onDropdownSelectRenderGraphics = async (tab) => {
        if (tab !== currentGeoTab) {

            props.editGeoInput();

            await props.startGeoFetch();
            setCurrentGeoTab(tab , () => {
                if (tab === HubConstant.HUB_GEO_FIELD_STATE.ADD) {
                    setCurrentGeo({
                        name: "",
                        loc: [{ lat: "", lon: "" }, { lat: "", lon: "" }, { lat: "", lon: "" }],
                        description: ""
                    })
                } else if ((!!props.geoObj && props.geoObj.geoAreaList.length > 0)) {
                    setCurrentGeo({
                        name: props.geoObj.geoAreaList[0].geoDesc.areaName,
                        loc: props.geoObj.geoAreaList[0].geoDesc.geoData,
                        description: props.geoObj.geoAreaList[0].geoDesc.description
                    })
                } else {
                    setCurrentGeo({
                        currentGeo: {
                            name: undefined,
                            loc: undefined,
                            description: undefined
                        }
                    })                  
                }

            })
        }

    }

    function renderGeographics() {
        return (
            <Row>
                <Col xs="8">
                    <Card.Title>Geographics:</Card.Title>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={currentGeoTab}
                        onSelect={onDropdownSelectRenderGraphics}
                    >
                        <Tab eventKey={HubConstant.HUB_GEO_FIELD_STATE.ADD} title="Add">
                            {renderGeoFields(false, true, true, true, false)}
                        </Tab>
                        <Tab eventKey={HubConstant.HUB_GEO_FIELD_STATE.MODIFY} title="Modify">
                            {renderGeoFields(true, true, true, true, true)}
                        </Tab>
                    </Tabs>

                </Col>
                <Col className="globeCol" xs="4">
                    <img className="AboutStatsLogo globe" src="/images/orange-earth.svg" alt="Geographics" />
                </Col>
            </Row>)
    }

    function verifyFileSize(event) {
        return new Promise((resolve, reject) => {
            if (event.target.files && event.target.files[0]) {
                var img = document.createElement("img");
                setBackgroundImageFile(event.target.files[0] )
                img.onload = function () {
                    Logger.debug(this.width + " " + this.height);
                    resolve(this.width = 480 && this.height === 130);
                }

                var reader = new FileReader();
                reader.onloadend = function (ended) {
                    img.src = ended.target.result;
                }
                reader.readAsDataURL(event.target.files[0]);
            }
        })
    }

    const fileUploadHandler = async (event) => {
        //Restrict file size to be 480px * 130px before accepting:
        var file = event.target.files[0];
        // Logger.debug(`Uploading file ${event.target.value} started`);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);

        if (await verifyFileSize(event)) {
            props.startUpdateLogo(selectedFilename, true, file);
            setImgErrorStatus(null)
        } else {
            setImgErrorStatus("Image size must be 480 * 130 px!!")
        }
    };

    const handleUpdateButtonClick = () => {
    if (!!actualData["hubInfo"].customerName) {
        props.setHubName(actualData["hubInfo"].customerName, actualData["hubInfo"].description);
    } else if (!!actualData["hubInfo"].name) {
        // Reset it back to the OG name
        props.setHubName(actualData["hubInfo"].name, actualData["hubInfo"].description);
        var newState = actualData;
        newState["hubInfo"].customerName = actualData["hubInfo"].name;
        setActualData(newState);
    } else {
        // Reset it back to the BurliNewsHub AppName
        props.setHubName(actualData["appName"], actualData["hubInfo"].description);
        var newState = actualData;
        newState["hubInfo"].customerName = actualData["appName"];
        setActualData(newState);
    }
}

    function renderGeneralSettings() {
        Logger.debug(actualData);
        return (
            <Fragment>
                <Row>
                    <Col xs="4" ref={aboutSettingsContinerRef} className="LogoOuterContainer" onClick={() => logoUploaderRef.current.click()}>
                        <div className="LogoContainer"
                            ref={aboutSettingsContinerRef}
                            onClick={flipShowSettingState}>
                            {
                                (!!props.logoObj) ?
                                    <img className="AboutLogo" src={props.logoObj} alt="Logo" />
                                    :
                                    <img className="AboutLogo" src="/images/HubBrandingDefault-1.2-Grey.png" alt="Logo" />
                            }
                            <FontAwesomeIcon icon={faCamera} className="cam"></FontAwesomeIcon>
                            <Form.Control
                                type="file"
                                accept="image/png"
                                ref={logoUploaderRef}
                                className="custom-file-input rowEditorMediaBrowser"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                style={{ display: "none" }}
                                onChange={fileUploadHandler} />
                            {/* <div className="faContainer">
                                <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                                <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                            </div> */}
                        </div>
                        <Overlay
                            show={showAboutSettings}
                            target={target}
                            placement="bottom"
                            container={aboutSettingsContinerRef}>
                            <AboutSettings></AboutSettings>
                        </Overlay>
                    </Col>
                    <Col xs="8" className="LogoDescription">
                        <h5>HUB LOGO</h5>
                        <p>Click image to replace it</p>
                        <p style={!!imgErrorStatus ? { color: 'red' } : {}}><i>Image must be exactly 480px wide and 130 high in .png format</i></p>
                    </Col>
                </Row>
                <Row className="HubNameRow">
                    <Col xs="12">
                        <h5>HUB NAME</h5>
                    </Col>
                </Row>
                <Row className="HubNameRow">
                    <Col xs="8">
                        <Form.Control
                            key="hubInfoName"
                            type="text"
                            placeholder="Hub Name"
                            className="settingsInput"
                            value={!!actualData && !!actualData["hubInfo"] ? actualData["hubInfo"].customerName !== undefined ? actualData["hubInfo"].customerName : actualData["hubInfo"].name : ""}
                            onChange={e => setHubName(e.target.value)} />
                        <Button className="updateButton" onClick={handleUpdateButtonClick}>Update</Button>
                    </Col>
                    <Col xs="4">
                        <Button 
                            className="updateButton updateLicensingButton" 
                            onClick={() => Refresh(true)}>Update Licensing</Button>
                    </Col>
                </Row>
                {renderUpdateStatus(
                    !!props.updateLicensingSuccess ? props.updateLicensingSuccess : props.setNameSuccess, 
                    !!props.updateLicensingError ? props.updateLicensingError : props.setNameError)}
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>HUB DETAILS</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="6" className="HubCol">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "hubId", title: "GUID", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "createTime", title: "Created", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "appVersion", title: "API Version", type: HubConstant.HUB_INFO_TYPE.GENERAL }
                                        ], actualData)}
                                    </Col>
                                    <Col xs="6">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "description", title: "Hub Description", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "users", title: "Number of Active Users", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "nodes", title: "Number of Production Centres", type: HubConstant.HUB_INFO_TYPE.NODES },
                                            { key: "expireTime", title: "Licensed Until End of", type: HubConstant.HUB_INFO_TYPE.HUB_INFO }
                                        ], actualData)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>CURRENT DATA</h5>
                                <p><i>Summed data from across this Hub</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "stories", title: "Total Stories", type: HubConstant.HUB_INFO_TYPE.RUNTIME },
                                            { key: "elememnts", title: "Total Media Elements", type: HubConstant.HUB_INFO_TYPE.RUNTIME },
                                            { key: "mediaSize", title: "Total Media File Storage", type: HubConstant.HUB_INFO_TYPE.RUNTIME, isStorageSize: true }
                                        ], actualData)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    function renderCleanUpSettings() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>DATA PURGE</h5>
                                <p className="purgeDesc"><i>Configure when old stories and media are automatically removed from this Hub</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {renderCleanUpSettingsOnAdvanced(false, cleanUpSettings)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>ADVANCED</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {renderCleanUpSettingsOnAdvanced(true, cleanUpSettings)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {renderUpdateStatus(props.updateSettingsSuccess, props.updateSettingsError)}
                <Row>
                    <Button className="updateButton" onClick={() => updateSettings(false)}>Save Changes</Button>
                    <Button className="resetButton" onClick={() => updateSettings(true)} disabled={props.justFinishedReset}>Reset to Default</Button>
                </Row>
            </Fragment>
        )
    }

    function renderScheduleSettings() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>SCHEDULED EVENTS</h5>
                                <p className="purgeDesc"><i>Wake-up intervals for scheduled maintenance events</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {renderScheduleSettingsOnAdvanced(false, schedulesSettings)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {renderUpdateStatus(props.updateSettingsSuccess, props.updateSettingsError)}
                <Row>
                    <Button className="updateButton" onClick={() => updateSettings(false)}>Save Changes</Button>
                    <Button className="resetButton" onClick={() => updateSettings(true)} disabled={props.justFinishedReset}>Reset to Default</Button>
                </Row>
            </Fragment>
        )
    }

    function renderNodeGroupsSettings() {
        return <ProductionGroup {...props}></ProductionGroup>
    }

    function renderFolderSecurityGroupSettings() {
        return <FolderSecurityGroup {...props}></FolderSecurityGroup>
    }

    function renderMetadatatSettings() {
        return <MetaData {...props}></MetaData>
    }

    
    // Logger.debug(props.activePageName);
    return (
        <div className="Hub">
            <Row className="HubTabRow">
                <Col xs="12">
                    <Tabs
                        id="controlled-tab-example"
                        defaultActiveKey="general"
                        mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                        unmountOnExit
                    >
                        <Tab eventKey="general" title="General">
                            {renderGeneralSettings()}
                        </Tab>
                        <Tab eventKey="cleanUp" title="Clean-up">
                            {renderCleanUpSettings()}
                        </Tab>
                        <Tab eventKey="schedules" title="Schedules">
                            {renderScheduleSettings()}
                        </Tab>
                        {/* <Tab eventKey="regions" title="Regions">
                            Coming Soon!
                        </Tab> */}
                        <Tab eventKey="nodeGroups" title="Production Groups">
                            {renderNodeGroupsSettings()}
                        </Tab>
                        <Tab eventKey="metadata" title="Data Fields">
                            {renderMetadatatSettings()}
                        </Tab>
                        <Tab eventKey="folderSecurityGroups" title="Folder Security Groups">
                            {renderFolderSecurityGroupSettings()}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
    
}

const mapLogoAndSettingStatesToProps = (state) => {
    return {
        settings: state.hubConfig.hubSettings.settings,
        hubInfo: state.hubConfig.hubSettings.hubInfo,

        setNameSuccess: state.hubConfig.hubSettings.setNameSuccess,
        updateSettingsSuccess: state.hubConfig.hubSettings.updateSettingsSuccess,
        setNameError: state.hubConfig.hubSettings.setNameError,
        updateSettingsError: state.hubConfig.hubSettings.updateSettingsError,

        // bascially just fetching data from hub as 'update licensing'
        updateLicensingSuccess: state.hubConfig.hubSettings.updateLicensingSuccess,
        updateLicensingError: state.hubConfig.hubSettings.updateLicensingError,

        justFinishedReset: state.hubConfig.hubSettings.justFinishedReset,

        logoObj: state.hubConfig.hubLogo.logoObj,
        geoObj: state.hubConfig.hubGeo.geoObj,
        geoError: state.hubConfig.hubGeo.error,
        geoSuccess: state.hubConfig.hubGeo.success,
    }
}

export default withRouter(connect(
    mapLogoAndSettingStatesToProps,
    dispatch => bindActionCreators({
        ...HubSettingsActions.mapSettingsDispatcherToProps,
        ...HubLogoActions.mapLogoDispatcherToProps,
        ...HubGeoActions.mapGeoDispatcherToProps,
    }, dispatch)
)(Hub));
