import React, { useEffect, useState, useRef } from "react";
import { useHistory } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import {  removeChildFolders } from '../../post-authorization/libraries/DataHandler';
import * as Logger from 'loglevel';
import folderApi from '../../../util/FolderApi'
import { FolderTreeItem } from "./FolderTreeItem";
import { FolderTreeToast } from "./FolderTreeToast";
import 'styles/sections/FolderTree.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ChangeType = {
    ADDED: 1,
    UPDATED: 2,
    DELETED: 3,
    PERMISSION_CHANGED: 4,
};

export function FolderTree(props){

    const [selected, setSelected] = useState(null);
    const [tree, setTree] = useState(null);
    const history = useHistory();
    const [activeContextMenu, setActiveContextMenu] = useState(null);
    const [toastData, setToastData] = useState(null);

    const prevHubFolderTreeRef = useRef();

    useEffect(() => {
        if (!!props.hubFolderTree) {
            if (tree === null) {
                initializeFolderTree();
            } else {
                refreshAfterFolderUpdate();
            }
        }
        prevHubFolderTreeRef.current = props.hubFolderTree;
    }, [props.hubFolderTree]);

    const initializeFolderTree = () => {

        if (!props.displayMode) {
            setRootFolders();
        } else {
            setDisplayTree();
        }
        
    };

    const setRootFolders = () => {
        const parentFolders = props.hubFolderTree.filter(folder => folder.parentId === null && folder.folderPermissions !== -1);

        if (parentFolders.length > 0) {
            const firstParentFolder = parentFolders[0];
            const directChildren = props.hubFolderTree.filter(folder => folder.parentId === firstParentFolder.id && folder.folderPermissions !== -1);

            const updatedParentFolders = parentFolders.map(folder => ({
                ...folder,
                isOpen: true,
            }));

            setSelected(props.workingFolder || firstParentFolder);
            setTree([...updatedParentFolders, ...directChildren]);
        }
    };

    const setDisplayTree = () => {
        const updatedFolders = props.hubFolderTree
            .filter(folder => folder.folderPermissions !== -1)
            .map(folder => ({
                ...folder,
                isOpen: true,
            }));

        setTree(updatedFolders);
        setSelected(props.workingFolder);
    };


    const isFolderEqual = (folder1, folder2) => {
        if (!folder1 || !folder2) {
            return false;
        }

        return (
            folder1.parentId === folder2.parentId &&
            folder1.id === folder2.id &&
            folder1.name === folder2.name &&
            folder1.securityOption === folder2.securityOption &&
            folder1.folderPermissions === folder2.folderPermissions
        );
    };

    const refreshAfterFolderUpdate = () => {
        let toastMessage = "There have been changes to the folder tree. This page will refresh after you close this notification."
        let isHardRefresh = true;
        let isDataRefresh = false;
        setToastData({ toastMessage, isHardRefresh, isDataRefresh })
    
        //const latestFullTree = props.hubFolderTree;
        //const prevFullTree = prevHubFolderTreeRef.current;
        //const newTree = props.hubFolderTree.filter(folder => folder.folderPermissions !== -1 || tree.some(t => t.id === folder.id));
        //const prevTree = prevHubFolderTreeRef.current.filter(folder => tree.some(t => t.id === folder.id));

        //const hasDifferentLength = newTree.length !== prevTree.length;

        //const hasDifferentFolders = newTree.some(folder => {
        //    const prevFolder = prevTree.find(prev => prev.id === folder.id);
        //    return prevFolder && !isFolderEqual(folder, prevFolder);
        //});

        //const shouldRefresh = hasDifferentLength || hasDifferentFolders;

        //let isHardRefresh = false;
        //let isDataRefresh = false;
        //let toastMessage = ""

        //if (shouldRefresh) {
        //    toastMessage = "There have been changes to the folder tree."
        //    if (hasDifferentFolders) {
        //        toastMessage += " This page will refresh after you close this notification.";
        //        isHardRefresh = true;
        //    }
        //}

        //const newlyAddedFolders = latestFullTree.filter(
        //    (folder) => !prevFullTree.find((f) => f.id === folder.id)
        //);
        //if (newlyAddedFolders.length > 0) {
        //    const updatedTree = [...tree, ...newlyAddedFolders].filter(folder => folder.folderPermissions !== -1);
        //    const updatedTreeWithOpenState = updatedTree.map(folder => {
        //        if (newlyAddedFolders.some(newFolder => newFolder.parentId === folder.id)) {
        //            return { ...folder, isOpen: true };
        //        }
        //        return folder;
        //    });

        //    setTree(updatedTreeWithOpenState);
    //    }

    //    const removedFolders = prevFullTree.filter((folder) => !latestFullTree.some((f) => f.id === folder.id));
    //    if (removedFolders.length > 0) {
    //        setTree(tree.filter((f) => !removedFolders.find((removed) => removed.id === f.id)));
    //        if (removedFolders.some(folder => folder.id === selected.id)) {
    //            setSelected(newTree[0])
    //        }
    //    }

    //    if (toastMessage !== "") {
    //        setToastData({ toastMessage, isHardRefresh, isDataRefresh })
    //    }
    }


    const onFolderCreate = async (newFolderData) => {

        Logger.debug(newFolderData);
        Logger.debug(JSON.stringify(newFolderData))

        let response = await folderApi.createNewFolder(newFolderData);
        Logger.debug(response);

        if (!!response){
            return true
        }
        return false;
    }

    const onFolderDelete = async (folderId) => {
        await folderApi.deleteFolder(folderId)
    }


    const getSubFolders = async (folder) => {
        return props.hubFolderTree.filter(child => child.parentId === folder.id);
    };


    const onFolderUpdate = async (newFolderData) => {
        return await folderApi.updateFolder(newFolderData);
    }

    const refreshSubFolders = async (node) => {
        if (!node) {
            return;
        }

        if (!node.isOpen) {
            return;
        }

        const refreshedFolders = await getSubFolders(node);

        if (!refreshedFolders || refreshedFolders.length === 0) {
            const newTree = tree.filter((folder) => folder.parentId !== node.id);
            setTree(newTree);
            return;
        }

        const newTree = tree.map((folder) => {
            const refreshedFolder = refreshedFolders.find((f) => f.id === folder.id);
            return refreshedFolder ? { ...refreshedFolder, isOpen: folder.isOpen } : folder;
        });

        refreshedFolders.forEach((newFolder) => {
            if (!newTree.find((folder) => folder.id === newFolder.id)) {
                newTree.push(newFolder);
            }
        });

        setTree(newTree);
    };

    //if there are duplicate ids, it can cause a circular reference in building the tree and cause an infinite loop
    const isTreeValid = (tree) => {
        const seenIds = new Set();
        const duplicateIds = new Set();

        for (const { id, parentId } of tree) {
            if (seenIds.has(id)) {
                duplicateIds.add(id);
            } else {
                seenIds.add(id);
            }

            if (id === parentId) {
                return false;
            }
        }

        return duplicateIds.size === 0;
    };


    const onFolderIconClick = async (node) => {

        let childNodes = await getSubFolders(node);

        //update the folderIcon to show expanded for user, so they know it has no children.
        if (childNodes.length === 0) {
            if (node.isOpen) {
                return;
            }
            var newTree = [...tree]
            var index = tree.findIndex(f => f.id === node.id)
            newTree[index] = { ...newTree[index], isOpen: true }; 
            setTree(newTree);
            return;
        }
        const hasChildren = tree.some(child => child.parentId === node.id);

        //when expanding the tree. we add childnodes
        if (!node.isOpen && !hasChildren) {
            let updatedTree = [...tree, ...childNodes]
            var index = tree.findIndex(f => f.id === node.id)
            updatedTree[index] = { ...updatedTree[index], isOpen: true }; 
            setTree(updatedTree)
            return;
        }

        if (node.isOpen && hasChildren) {
            var newTree = [...tree]
            var updatedTree = removeChildFolders(newTree, node.id);
            var index = updatedTree.findIndex(f => f.id === node.id)
            updatedTree[index] = { ...updatedTree[index], isOpen: false };;
            setTree(updatedTree)
            return;
        } 
    }

    const onSelected = (node) => {

        if (props.displayMode) {
            if (!!props.workingFolder && props.workingFolder.id === node.id) { return }
        }
        //this is used to redirect back to newsfeed when folder tree is selected outside of the below url
        if (history.location.pathname !== '/newsfeed') {
            history.push('/newsfeed');
            props.handleFolderSelect(node);
        }
        
        if (!!selected && selected.id === node.id) { return }

        if (selected.id !== node.id) {
            setSelected(node)
            props.handleFolderSelect(node);
        }
    }

    const handleContextMenuOpen = (event, nodeId) => {
        event.preventDefault();
        setActiveContextMenu(nodeId);
    }


    const TreeNode = ({node}) => {
        const hasChildren = props.hubFolderTree.some(child => child.parentId === node.id);
        return (
            <ListGroup.Item 
                className="folderGroup"
                as='li'
            >
                <div className="folderItemContainer" >
                        <FolderTreeItem
                            displayMode={props.displayMode}
                            isOpen={node.isOpen}
                            workingFolder={props.workingFolder}
                            node={node}
                            onSelected={onSelected}
                            selected={selected}
                            onFolderIconClick={onFolderIconClick}
                            refreshSubFolders={refreshSubFolders}
                            createNewFolder={onFolderCreate}
                            updateFolder={onFolderUpdate}
                            deleteFolder={onFolderDelete}
                            onContextMenuOpen={handleContextMenuOpen}
                            activeContextMenu={activeContextMenu}
                            setActiveContextMenu={setActiveContextMenu}
                            hasChildren={hasChildren}
                        />
                    </div>
                    {!!hasChildren && node.isOpen && (
                            <ListGroup as="ul" className="folderGroup" >
                                {tree.filter((childNode) => childNode.parentId === node.id)
                                     .map((childNode) => (
                                      <TreeNode key={childNode.id} node={childNode} />
                                ))}
                            </ListGroup>
                        )}
            </ListGroup.Item>
        )
    }

    return (
        <div>
            {!!props.workingFolder && !!tree && isTreeValid(tree) ?
                <ListGroup as="ul" className="folderGroup" >
                    {!!tree && tree.filter((childNode) => childNode.parentId === null)
                        .map((node) => (
                            <TreeNode key={node.id} node={node} />
                        ))}
                </ListGroup> : <div className='errorMsg' style={{ textAlign: "center" }}>{""}</div>}
            {!!toastData && (
                <FolderTreeToast
                    message={toastData.toastMessage}
                    isHardRefresh={toastData.isHardRefresh}
                    isDataRefresh={toastData.isDataRefresh}
                    onDismiss={() => setToastData(null)}
                    onHardRefresh={() => {
                        window.location.reload();
                    }}
                    onDataRefresh={() => {
                        props.handleFolderSelect(selected);
                    }}
                />
            )}
        </div>
    );
}


const mapFolderStateToProps = (state) => {
    return {
        hubFolderTree: state.hubObjectContent.hubFolderTree,
    };
};


export default withRouter(connect(mapFolderStateToProps)(FolderTree));
