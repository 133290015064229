import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import ClipLoader from 'react-spinners/ClipLoader';
import { FolderPermissionsTable } from './FolderPermissionsTable';
import 'styles/sections/FolderTree.scss';
import 'styles/widgets/FolderSecurityForm.scss';
import folderApi from '../../../util/FolderApi';
import * as HubConstant from 'util/HubConstant';

export function FolderForm(props) {
    const { payload: folderData } = props;
    const [userPermissions, setUserPermissions] = useState(null);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [folderName, setFolderName] = useState(!!folderData.name ? folderData.name : "");
    const [folderId, setFolderId] = useState(!!folderData.id ? folderData.id : "");
    const [accessType, setAccessType] = useState(folderData.securityOption != null ? folderData.securityOption : HubConstant.FOLDER_SECURITY_OPTIONS.INHERIT);
    const [formError, setFormError] = useState('');
    const [showTable, setShowTable] = useState(folderData.securityOption === HubConstant.FOLDER_SECURITY_OPTIONS.CUSTOM);
    const form = useRef(null);
    const folderNameRef = useRef(null);
    const [editableRow, setEditableRow] = useState(null); // Define editableRow


    useEffect(() => {
        async function fetchFolderPermissions() {
            try {
                let folderPermissions = await folderApi.getFolderPermissions(folderData.id);
                setUserPermissions(folderPermissions);
            } catch (error) {
                // Handle the error gracefully
                console.error("Error fetching folder permissions:", error);
                setUserPermissions([]); 
            } finally {
                setIsLoadingData(false); 
            }
        }

        if (isLoadingData) {
            fetchFolderPermissions();
        }
    }, [folderData.id, isLoadingData]);

    const onFolderNameChange = (event) => {
        setFolderName(event.target.value)
    }

    const onSubmit = async (event) => {

        event.preventDefault();
        
        var newFolderData = {
            parentId: folderData.parentId,
            id: !!folderData.id ? folderData.id : null,
            name: folderName,
            summary: "", // this is an empty value for now - may be added to screen at a later date
            security: {
                option: accessType,
                securityWord: null
            }
        }
        var success = await props.onSaveClicked(newFolderData);
        if (success) {
            setFormError('');
        } else {
            const errorMessage = !!newFolderData.id ? 'Error updating new folder. Please try again!' : 'Error creating new folder. Please try again!';
            setFormError(errorMessage);
        }
    }

    const handleRadioBtnSelect = (value) => {
        setAccessType(value);
        setShowTable(value === HubConstant.FOLDER_SECURITY_OPTIONS.CUSTOM);
    };

    function generateUserPermissionsTable() {
        return (
            <FolderPermissionsTable
                data={userPermissions} 
                folderData={folderData}
                availableUsers={props.availableUsers}
                active={!isLoadingData}
                editableRow={editableRow} 
                setEditableRow={setEditableRow} 
            />
        );
    }

    const renderRadioButton = (label, value) => {
        return (
            <div className="radioContainer">
                <label className="radioItem">
                    <input
                        type="radio"
                        name="accessType"
                        value={value}
                        checked={accessType === value}
                        onChange={() => handleRadioBtnSelect(value)}
                    />
                    <span className="checkmark" />
                </label>
                <label className="radioTitle">{label}</label>
            </div>
        );
    };

    return (
        <Modal show={props.showModal} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoadingData ? ( 
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ClipLoader color="#f69b1d" size={40} />
                    </div>
                ) : (
                    <form ref={form} noValidate>
                        {folderId && (
                            <Row>
                                <Col xs={16}>
                                    <TextField
                                        type='text'
                                        className='folderId'
                                        value={folderId}
                                        name='folderId'
                                        label="Folder Id"
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col xs={16}>
                                <TextField
                                    ref={folderNameRef}
                                    type='text'
                                    className='folderName'
                                    value={folderName}
                                    name='name'
                                    label="Folder Name"
                                    onChange={onFolderNameChange}
                                    required
                                />
                            </Col>
                        </Row>
                        {folderData.id && (
                            <>
                                <Row>
                                    <Col xs={12}>
                                        <div className="d-flex">
                                            {renderRadioButton('Public', HubConstant.FOLDER_SECURITY_OPTIONS.PUBLIC)}
                                            {folderData.parentId && renderRadioButton('Inherit', HubConstant.FOLDER_SECURITY_OPTIONS.INHERIT)}
                                            {renderRadioButton('Custom', HubConstant.FOLDER_SECURITY_OPTIONS.CUSTOM)}
                                        </div>
                                    </Col>
                                </Row>
                                <div className={`FolderSecurity ${showTable ? 'show' : 'hide'}`}>
                                    <Row className="structureRow">
                                        <Col xs="12">
                                            {generateUserPermissionsTable()}
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        )}
                        {formError ? (
                            <div className='errorMsg' style={{ textAlign: "center" }}>
                                {formError}
                            </div>
                        ) : (
                            <></>
                        )}
                        <Button
                            variant='light'
                            type='submit'
                            size='sm'
                            className='float-right mr-0'
                            onClick={(e) => onSubmit(e)}
                            disabled={editableRow !== null}
                        >
                            Save
                        </Button>
                    </form>
                )}
            </Modal.Body>
        </Modal>
    );
}