import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType'
import * as HubConstant from 'util/HubConstant'
import { DataTableConstant } from 'hub-dashboard-framework'
import { UtilConstant } from 'hub-utilities'

const initialState = {
    success: null,
    error: null,
    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" },
    metaData: null,
    action: DataTableConstant.HUB_ACTION_STATE.NONE,
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_METADATA_BEGIN:
            Logger.debug(`Metadata reducer: action = ${action.type}`);
            return ({
                ...state,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING
            });
        case ActionType.FETCH_METADATA_SUCCESS:
            Logger.debug(`Metadata reducer: action = ${action.type}`);
            Logger.debug(action.payload.fields)
            return (
                {
                    ...state,
                    metaData: action.payload.fields,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE
                });
        case ActionType.UPDATE_METADATA_SUCCESS:
            return (
                {
                    ...state,
                    success: "Successfully Edited Data",
                    error: null
                });
        case ActionType.ADD_METADATA_SUCCESS:
            return (
                {
                    ...state,
                    success: "Successfully Added Data",
                    error: null
                });
        case ActionType.ADD_METADATA_TO_NEWSITEM_SUCCESS:
            return (
                {
                    ...state,
                    success: "Successfully Added Data to Story",
                    error: null
                });
        case ActionType.FETCH_METADATA_FAILURE:
        case ActionType.ADD_METADATA_TO_NEWSITEM_FAILURE:
            Logger.debug("Error: ", action.payload);
            Logger.debug(`Data reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    error: action.payload,
                    success: null,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });

        case ActionType.ADD_METADATA_FAILURE:
        case ActionType.UPDATE_METADATA_FAILURE:
        case ActionType.DELETE_METADATA_FAILURE:
            Logger.debug("Error: ", action.payload);
            Logger.debug(`Metadata reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    error: action.payload.details.error,
                    success: null,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });


        //vocab:
        case ActionType.ADD_METADATA_VOCAB_SUCCESS:
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: "Successfully added value" },
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        case ActionType.UPDATE_METADATA_VOCAB_SUCCESS:
            Logger.debug("yep1");
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: "Successfully updated value" },
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        case ActionType.DELETE_METADATA_VOCAB_SUCCESS:
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: "Successfully deleted value" },
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        case ActionType.ADD_METADATA_VOCAB_FAILURE:
        case ActionType.UPDATE_METADATA_VOCAB_FAILURE:
        case ActionType.DELETE_METADATA_VOCAB_FAILURE:
            Logger.debug(action.payload);
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: action.payload.details.error },
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });

        case ActionType.EDIT_METADATA:
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" },
                    success: null,
                    error: null,
                });

        case ActionType.REPORT_ERROR:
            return (
                {
                    ...state,
                    vocabMsg: { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: action.payload },
                    success: null,
                    error: null,
                });
        default:
            break;
    }
    return state;
}

