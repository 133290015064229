import React from 'react';
import { Navbar } from 'react-bootstrap';
import '../../../styles/sections/Footer.scss';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.date = new Date().getFullYear();
        this.initializeHubInfo();
    }

    initializeHubInfo() {
        //Two main parts down here:
        if (!!this.props.hubInfo) {
            const hubInfo = this.props.hubInfo;
            const environment = hubInfo.environment;
            this.appName = hubInfo.appName;
            this.copyright = hubInfo.copyright;
            if (!!environment.connectionString
                && !!environment.hostEnvironment
                && environment.hostEnvironment !== "Production") {
                this.version = `(${hubInfo.appVersion} - ${environment.hostEnvironment}):`;
                this.idpInfo = `IDP: ${environment.identityServer}`;
            }
            else {
                this.version = `(${hubInfo.appVersion}):`;
                this.idpInfo = '';
            }
        } else {
            console.error("HubInfo is null");
            this.version=  "";
            this.idpinfo = "";
            this.appName = "BurliNewsHub";
            this.copyright = "copyright Burlisoftware All right reserved.";

        }
    }
    
    render() {
        return (
            <div className="Footer">
                <Navbar bg="dark" variant="dark">
                    <p>{this.appName} {this.version} <a href="https://www.burli.com"> {this.copyright}</a> {this.idpInfo} </p>
                </Navbar>
            </div>
        );
    }
}

export default Footer;