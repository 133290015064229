import React from 'react';
import 'styles/exceptions/ScreenSizeWarning.scss';

class ScreenSizeWarning extends React.Component {

    render() {
        return (
            <div className="ScreenSizeWarning">
                <div className="container">
                    <img src="/images/rotate-device.png" alt="rotateYourDeviceThanks!!!"></img>
                    <h1>Please rotate your device</h1>
                    <p>This app does not support narrow screens.</p>
                </div>
            </div>
        )
    }
}

export default ScreenSizeWarning;