import React from 'react';
import { withRouter } from 'react-router-dom';
import SideNavigation from './sections/SideNavigation';
import TopNavigation from './sections/TopNavigation';
import MainContent from './sections/MainContent';
import Footer from './sections/Footer';
import Title from './sections/Title';
import authService from '../api-authorization/AuthorizeService';
import ScreenSizeWarning from '../Exceptions/ScreenSizeWarning';

import * as Logger from 'loglevel';

class AppNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activePageName: { singular: "", plural: "" },

            //for notification:
            arrOfNewData: [],
            clickNotifier: false,
            bounceNotifier: false,
            isValidScreenSize: true,
            isNotificationAllowed: true
        }
    }

    setIsValidScreenSize() {
        if (window.innerWidth <= 510 && this.state.isValidScreenSize) {
            this.setState({ isValidScreenSize: false });
        } else if (window.innerWidth > 510 && !this.state.isValidScreenSize) {
            this.setState({ isValidScreenSize: true });
        }
    }

    componentWillMount() {
        this.setIsValidScreenSize();
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));

        var token = await authService.getAccessToken();
        Logger.debug("=============== Authentication Token: ===============");
        Logger.debug(token);
        Logger.debug("=====================================================");
    }

    resize() {
        this.setIsValidScreenSize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    //consists of singular and plural within an obj:
    setActivePageName = async (name) => {
        Logger.debug(name)
        //active page flushes new data list:
        await this.setArrOfNewData([]);
        this.setState({ activePageName: name });
    }

    setArrOfNewData = (newData) => {
        var newBounceNotifier = !this.state.bounceNotifier;
        this.setState({ bounceNotifier: newBounceNotifier, arrOfNewData: newData });
    }

    setIsNotificationAllowed = (isAllowed) => {
        this.setState({ isNotificationAllowed: isAllowed });
    }

    toggleClickNotifier = () => {
        var newClickNotifier = !this.state.clickNotifier;
        this.setState({ clickNotifier: newClickNotifier, arrOfNewData: [] });
    }

    render() {
        return (
            <div className="App BurliNewsHub">
                { !this.state.isValidScreenSize ? <ScreenSizeWarning></ScreenSizeWarning> : <></> }
                <Title></Title>
                <TopNavigation {...this.props} activePageName={this.state.activePageName} arrOfNewData={this.state.arrOfNewData} bounceNotifier={this.state.bounceNotifier} toggleClickNotifier={this.toggleClickNotifier}></TopNavigation>
                <SideNavigation setActivePageName={this.setActivePageName} activePageName={this.state.activePageName}></SideNavigation>
                <MainContent setActivePageName={this.setActivePageName} activePageName={this.state.activePageName}
                    arrOfNewData={this.state.arrOfNewData} setArrOfNewData={this.setArrOfNewData} isNotificationAllowed={this.state.isNotificationAllowed} setIsNotificationAllowed={this.setIsNotificationAllowed} clickNotifier={this.state.clickNotifier}></MainContent>
                <Footer hubInfo={authService.getHubInfo()}></Footer>
            </div>
        )
    }
}

export default withRouter(AppNavigation);