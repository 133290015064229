import React, { useEffect, useState, Fragment } from "react";
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import "styles/tables/FolderSecurityGroup.scss";

import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import * as HubConstant from 'util/HubConstant'
import { UtilConstant } from 'hub-utilities'
import authService from 'components/api-authorization/AuthorizeService';

export function FolderSecurityGroup(props){
 
    const [fetchedData, setFetchedData] = useState(null);
    const [headerConfig, setHeaderConfig] = useState({
        id: -1,
        Group: { val: "", canSort: true },
        Description: { val: "", canSort: true },
        Members: { val: "", canSort: false },
        rowActions: [
            { action: TableRowAction.EDIT_ROW, form: HubConstant.HUB_FOLDER_SECURITY_GROUP, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
        ]
    }); 

    useEffect(() => {
        async function fetchData() {

            await props.editFolderSecurityGroup();
            await props.getFolderSecurityGroups();
            setFetchedData(await fetchDataFromProps(props.folderSecurityGroups));
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const newData = await fetchDataFromProps(props.folderSecurityGroups);
            setFetchedData([...newData]);
        }
        fetchData();
    }, [props.folderSecurityGroups]);



    const fetchDataFromProps = (objects) => {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.map(o => {
                Logger.debug("o: ", JSON.parse(JSON.stringify(o)));
                return {
                    id: o.groupId,
                    type: HubConstant.HUB_METADATA,
                    Group: { val: o.group.name, canSort: true },
                    Description: { val: o.group.description, canSort: true },
                    Members: {
                        val: o.users && o.users.length > 0 ? o.users.map(node => node.userName).join("; ") : "No members",
                        formVal: o.users || [],
                        canSort: false
                    },
                    rowActions: [
                        { action: TableRowAction.EDIT_ROW, form: HubConstant.HUB_UPDATE_FOLDER_SECURITY_GROUP_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                        { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                    ]
                };
            });
        } else {
            return [];
        }
    };

    const onDeleteRow = (id) => {
        Logger.debug(id);
        props.deleteFolderSecurityGroup(id);
    };
    function displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={headerConfig}
                        data={fetchedData}
                        tools={[
                            { action: TableToolType.ADD, form: HubConstant.HUB_CREATE_FOLDER_SECURITY_GROUP_FORM },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.MESSAGE }]}
                        dataAction={props.action}
                        activeTableName={{ singular: "Value", plural: "Folder Security Groups" }}
                        onDeleteRow={onDeleteRow}
                        message={
                            props.success ? { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: props.success } :
                                props.error ? { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: props.error } :
                                    { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" }
                        }
                        authorizeService={authService}
                        {...props}></DataTable>
                </Col>
            </Row>
        )
    };

    return (
        <div className="FolderSecurityGroup">
            <Row className="structureRow">
                <Col xs="12">
                    {(!!fetchedData && fetchedData != null) ? displayDatatable() : <Fragment key="0"></Fragment>}
                </Col>
            </Row>
        </div>
    );
}


const mapFolderSecurityGroupsStateToProps = (state) => ({
    folderSecurityGroups: state.hubObjectContent.folderSecurityGroups,
    success: state.hubObjectContent.success,
    error: state.hubObjectContent.error,
    action: state.hubObjectContent.action,
});


export default withRouter(connect(
    mapFolderSecurityGroupsStateToProps,
    dispatch => bindActionCreators(mapDispatchToHubObjectBoardProps, dispatch),
    null,
    { pure: false }
)(FolderSecurityGroup));

