import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import * as ProdCenterBoardActions from 'store/actions/HubConfigActions';
import * as HubConstant from 'util/HubConstant'
import { UtilConstant } from 'hub-utilities'
import authService from 'components/api-authorization/AuthorizeService';

class ProductionGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedData: null
        }
        this.headerConfig = {
            id: -1,
            Group: { val: "", canSort: true },
            Description: { val: "", canSort: true },
            Members: { val: "", canSort: false },
            rowActions: [
                { action: TableRowAction.EDIT_PRODUCTION_GROUP, form: HubConstant.HUB_UPDATE_PRODUCTION_GROUP, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
            ]
        };
    }

    async componentWillMount() {

        //remove all status messages:
        this.props.editNodeGroup();

        //need to fetch nodes, for the ones without group
        await this.props.requestNodes();
        await this.props.getNodeGroups(true, true);

        Logger.debug(this.props.hubNodeGroups);
        //objectList is the redux state
        this.setState({ fetchedData: await this.fetchData(this.props.hubNodeGroups) });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //Don't requestNodes here, or else datatable would fetch twice in the beginning, and 
        //mistreat it as new data added

        //Can't compare here, or else there will be some weird async issue
        //Where Logger.debug renders later than data is fetched and o.hubNodes 'appears' to be there
        this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.hubNodeGroups) });
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.map(o => {
                // Logger.debug("o: ", JSON.parse(JSON.stringify(o)));
                return (
                    {
                        //Lower case keys won't get posted to table
                        id: o.groupId,
                        type: HubConstant.HUB_PRODUCTION_GROUP,
                        Group: { val: o.groupName, canSort: true },
                        Description: { val: o.description, canSort: true },
                        //formVal is for the values being passed to popup Forms:
                        Members: { val: !!o.hubNodes ? o.hubNodes.map(node => node.name).join("; ") : "Loading...", formVal: o.hubNodes, canSort: false },
                        rowActions: [
                            { action: TableRowAction.EDIT_PRODUCTION_GROUP, form: HubConstant.HUB_UPDATE_PRODUCTION_GROUP, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                        ]

                    }
                );
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.ADD, form: HubConstant.HUB_CREATE_PRODUCTION_GROUP_FORM },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.MESSAGE }]}
                        dataAction={this.props.action}
                        nodeData={!!this.props.nodes ? this.props.nodes.sort((a, b) => a.id - b.id) : null}
                        activeTableName={{ singular: "Production Group", plural: "Production Groups" }}
                        message={
                            this.props.success ? { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: this.props.success } :
                                this.props.error ? { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: this.props.error } :
                                    { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" }
                        }
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="ProductionCentre">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData && this.state.fetchedData != null) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapNodeAndNodeGroupStatesToProps = (state) => {
    return {
        hubNodeGroups: state.hubObjectContent.hubNodeGroups,
        nodes: state.hubConfig.nodeConfig.nodes,
        action: state.hubObjectContent.action,
        hubNodeFetchCount: state.hubObjectContent.hubNodeFetchCount,
        success: state.hubObjectContent.success,
        error: state.hubObjectContent.error
    }
}


export default withRouter(connect(
    mapNodeAndNodeGroupStatesToProps,
    dispatch => bindActionCreators({ ...ProdCenterBoardActions.mapDispatchToProps, ...mapDispatchToHubObjectBoardProps }, dispatch),
    null,
    { pure: false } //Need to ensure pure is false, such that it will do a deep compare instead of shallow compare with changed props
)(ProductionGroup));