import { combineReducers } from 'redux';
import * as HubUser from './HubUser'
import * as HubNode from './HubNode'
import * as HubSettings from './HubSettings'
import * as HubLogo from './HubLogo'
import * as HubGeo from './HubGeo'
import * as HubMetadata from './HubMetadata'


export const reducer = combineReducers({
    userConfig: HubUser.reducer,
    nodeConfig: HubNode.reducer,
    hubSettings: HubSettings.reducer,
    hubLogo: HubLogo.reducer,
    hubGeo: HubGeo.reducer,
    hubMetadata: HubMetadata.reducer
});