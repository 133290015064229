import * as Logger from 'loglevel';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'

//actions:
const apiFetch = (method, body, ids, newsItemId, isVocab, currentVal) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: !!newsItemId ? 
            //`/api/NewsItem/${newsItemId}/metadata${id != undefined ? `/${id}` : ''}` :
            `/api/NewsItem/${newsItemId}/metadata` :
            `/api/metadatafield${
                !!ids && ids.metadataId !== undefined ? `/${ids.metadataId}` : ''}${
                !!isVocab ? '/vocabulary/keyword' : ''}${
                !!ids && ids.vocabId !== undefined ? `/${ids.vocabId}` : ''}${
                !!currentVal && currentVal !== undefined ? `?currentValue=${currentVal}` : ''}`,
        body: body,
        method: method,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,
        begin: !!newsItemId ? addMetadataToNewsItemBegin :
               isVocab ? method === "POST" ? addMetadataVocabBegin : method === "PUT" ? updateMetadataVocabBegin : deleteMetadataVocabBegin :
               method === "GET" ? fetchMetadataBegin : method === "POST" ? addMetadataBegin : method === "PUT" ? updateMetadataBegin : deleteMetadataBegin,
        success: !!newsItemId ? addMetadataToNewsItemSuccess:
        isVocab ? method === "POST" ? addMetadataVocabSuccess : method === "PUT" ? updateMetadataVocabSuccess : deleteMetadataVocabSuccess :
                 method === "GET" ? fetchMetadataSuccess : method === "POST" ? addMetadataSuccess : method === "PUT" ? updateMetadataSuccess : deleteMetadataSuccess,
        error: !!newsItemId ? addMetadataToNewsItemFailure:
        isVocab ? method === "POST" ? addMetadataVocabFailure : method === "PUT" ? updateMetadataVocabFailure : deleteMetadataVocabFailure :
               method === "GET" ? fetchMetadataFailure : method === "POST" ? addMetadataFailure : method === "PUT" ? updateMetadataFailure : deleteMetadataFailure,
        additionalParams: !!newsItemId ? newsItemId : undefined
    }
});

const fetchMetadataBegin = () => ({
    type: ActionType.FETCH_METADATA_BEGIN
});

const fetchMetadataSuccess = payload => ({
    type: ActionType.FETCH_METADATA_SUCCESS,
    payload: payload
});

const fetchMetadataFailure = error => ({
    type: ActionType.FETCH_METADATA_FAILURE,
    payload: error
});

const addMetadataBegin = () => ({
    type: ActionType.ADD_METADATA_BEGIN
});

const addMetadataSuccess = payload => ({
    type: ActionType.ADD_METADATA_SUCCESS,
    payload: payload
});

const addMetadataFailure = error => ({
    type: ActionType.ADD_METADATA_FAILURE,
    payload: error
});

const updateMetadataBegin = () => ({
    type: ActionType.UPDATE_METADATA_BEGIN
});

const updateMetadataSuccess = payload => ({
    type: ActionType.UPDATE_METADATA_SUCCESS,
    payload: payload
});

const updateMetadataFailure = error => ({
    type: ActionType.UPDATE_METADATA_FAILURE,
    payload: error
});
const deleteMetadataBegin = () => ({
    type: ActionType.DELETE_METADATA_BEGIN
});

const deleteMetadataSuccess = payload => ({
    type: ActionType.DELETE_METADATA_SUCCESS,
    payload: payload
});

const deleteMetadataFailure = error => ({
    type: ActionType.DELETE_METADATA_FAILURE,
    payload: error
});

const addMetadataToNewsItemBegin = () => ({
    type: ActionType.ADD_METADATA_TO_NEWSITEM_BEGIN
})
const addMetadataToNewsItemSuccess = payload => ({
    type: ActionType.ADD_METADATA_TO_NEWSITEM_SUCCESS,
    payload: payload
})
const addMetadataToNewsItemFailure = error => ({
    type: ActionType.ADD_METADATA_TO_NEWSITEM_FAILURE,
    payload: error
})

const editMetadata = () => ({
    type: ActionType.EDIT_METADATA
});
const reportError = error => ({
    type: ActionType.REPORT_ERROR,
    payload: error
});

//Vocab:
const addMetadataVocabBegin = () => ({
    type: ActionType.ADD_METADATA_VOCAB_BEGIN
});

const addMetadataVocabSuccess = payload => ({
    type: ActionType.ADD_METADATA_VOCAB_SUCCESS,
    payload: payload
});

const addMetadataVocabFailure = error => ({
    type: ActionType.ADD_METADATA_VOCAB_FAILURE,
    payload: error
});

const updateMetadataVocabBegin = () => ({
    type: ActionType.UPDATE_METADATA_VOCAB_BEGIN
});

const updateMetadataVocabSuccess = payload => ({
    type: ActionType.UPDATE_METADATA_VOCAB_SUCCESS,
    payload: payload
});

const updateMetadataVocabFailure = error => ({
    type: ActionType.UPDATE_METADATA_VOCAB_FAILURE,
    payload: error
});
const deleteMetadataVocabBegin = () => ({
    type: ActionType.DELETE_METADATA_VOCAB_BEGIN
});

const deleteMetadataVocabSuccess = payload => ({
    type: ActionType.DELETE_METADATA_VOCAB_SUCCESS,
    payload: payload
});

const deleteMetadataVocabFailure = error => ({
    type: ActionType.DELETE_METADATA_VOCAB_FAILURE,
    payload: error
});





//state to props:
export const mapMetadataStateToProps = state => {
    Logger.debug(`map Metadata props, state=`, state);
    return state.hubConfig.hubMetadata;
};

//helpers:
const fetchMetadata = async (dispatch) => {
    await dispatch(apiFetch("GET"), undefined, undefined, undefined, false);
};

//dispatchers:
export const mapDispatchToMetadataProps = {
    getMetadata: () => async (dispatch, getState) => {
        await fetchMetadata(dispatch);
    },

    addMetadata: (body) => async (dispatch, getState) => {
        await dispatch(apiFetch("POST", body, undefined, undefined, false));
        await fetchMetadata(dispatch);
    },

    updateMetadata: (id, body) => async (dispatch, getState) => {
        Logger.debug(id);
        await dispatch(apiFetch("PUT", body, {metadataId: id}, undefined, false));
        await fetchMetadata(dispatch);
    },

    deleteMetadata: (id) => async (dispatch, getState) => {
        await dispatch(apiFetch("DELETE", undefined, {metadataId: id}, undefined, false));
        await fetchMetadata(dispatch);
    },

    editMetadata: () => async (dispatch, getState) => {
        dispatch(editMetadata());
    },
    reportError: (error) => async (dispatch, getState) => {
        dispatch(reportError(error));
    },

    // updateNewsItemMetadata: (newsItemId, metadataId, body) => async (dispatch, getState) => {
    //     Logger.debug(newsItemId);
    //     Logger.debug(metadataId);
    //     await dispatch(apiFetch("POST", body, metadataId, newsItemId, false));
    // }
    updateNewsItemMetadata: (newsItemId, body) => async (dispatch, getState) => {
        Logger.debug(newsItemId);
        await dispatch(apiFetch("POST", body, undefined, newsItemId, false));
    },

    //Vocab:
    addMetadataVocab: (metadataId, body) => async (dispatch, getState) => {
        await dispatch(apiFetch("POST", body, {metadataId: metadataId}, undefined, true));
        await fetchMetadata(dispatch);
    },
    editMetadataVocab: (metadataId, vocabId, body) => async (dispatch, getState) => {
        await dispatch(apiFetch("PUT", body, {metadataId: metadataId, vocabId: vocabId}, undefined, true));
        await fetchMetadata(dispatch);
    },
    deleteMetadataVocab: (metadataId, vocabId, currentVal) => async (dispatch, getState) => {
        await dispatch(apiFetch("DELETE", undefined, {metadataId: metadataId, vocabId: vocabId}, undefined, true, currentVal));
        await fetchMetadata(dispatch);
    }
    /*
    resetMetadataVocab: (id, body) => async (dispatch, getState) => {
        Logger.debug(id);
        await dispatch(apiFetch("PUT", body, id, undefined, true));
        await fetchMetadata(dispatch);
    }
    */
}
