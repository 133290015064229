import React from "react";
import { Form } from "react-bootstrap";
import * as Logger from 'loglevel';
import Utilities from 'hub-utilities'

//redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as HubLogoActions from 'store/actions/HubLogoActions';

class AboutSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayName: "",
            fileName: null,
            errorStatus: "",
            uploadedFile: null
        };
    }

    setDisplayName = (e) => {
        this.setState({ displayName: e.target.value });
    }

    verifyFileSize(event) {
        return new Promise((resolve, reject) => {
            if (event.target.files && event.target.files[0]) {
                var img = document.createElement("img");
                this.setState({backgroundImageFile: event.target.files[0]});

                img.onload = function () {
                    Logger.debug(this.width + " " + this.height);
                    resolve(this.width === 240 && this.height === 65);
                }

                var reader = new FileReader();
                reader.onloadend = function (ended) {
                    img.src = ended.target.result;
                }
                reader.readAsDataURL(event.target.files[0]);
            }
        })
    }

    fileUploadHandler = async (event) => {
        //Restrict file size to be 240px * 65px before accepting:
        var file = event.target.files[0];
        Logger.debug(`Uploading file ${event.target.value} started`);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);

        if (await this.verifyFileSize(event)) {
            this.setState({
                fileName: selectedFilename,
                uploadedFile: file,
                errorStatus: ""
            });
        } else {
            this.setState({
                fileName: "",
                uploadedFile: null,
                errorStatus: "Image size must be 240 * 65 px!!"
            }
            );
            Logger.debug("Image size must be 240 * 65 px!!")
        }
    };

    onSubmit = async () => {
        this.props.startUpdateLogo(this.state.displayName, true, this.state.uploadedFile);
    }

    componentWillMount() {
        //this.fetchLogo();
    }

    render() {
        return (
                <Form.Control
                    type="file"
                    accept="image/png"
                    onChange={this.fileUploadHandler}
                    className="custom-file-input rowEditorMediaBrowser"
                    id="inputGroupFile01"
                    aria-describedby="inputGroupFileAddon01"
                    style={{display: "none"}}  />)
                /* <Card>
                    <Card.Body>
                        <div className="AboutSettingsInner">
                            <Form.Control
                                key={0}
                                type="text"
                                className="AboutSettingsName"
                                placeholder="Display Name"
                                value={this.state.displayName}
                                onChange={this.setDisplayName} />

                        </div>

                        <div className="custom-file">
                            <Form.Control
                                type="file"
                                accept="image/png"
                                onChange={this.fileUploadHandler}
                                className="custom-file-input rowEditorMediaBrowser"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01" />
                            <label className="custom-file-label AboutSettingsInner" htmlFor="inputGroupFile01">{this.state.fileName ? this.state.fileName : "Brand Image"}</label>
                        </div>

                        {(this.state.errorStatus != "")
                            ?
                            <div className="errorStatus">
                                {this.state.errorStatus}
                            </div>
                            :
                            <></>
                        }

                        <Button variant="dark" onClick={this.onSubmit}>
                            Update
                        </Button>
                    </Card.Body>
                </Card> */
        
    }
}

export default withRouter(connect(
    HubLogoActions.mapLogoStateToProps,
    dispatch => bindActionCreators(HubLogoActions.mapLogoDispatcherToProps, dispatch)
)(AboutSettings));
