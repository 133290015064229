import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType'
import * as HubConstant from 'util/HubConstant'
import { DataTableConstant } from 'hub-dashboard-framework'

const initialState = {
    success: null,
    error: null,
    nodes: null,
    action: DataTableConstant.HUB_ACTION_STATE.NONE,
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_HUB_NODES_BEGIN:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            return ({
                ...state,
                error: null,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING
            });
        case ActionType.FETCH_HUB_NODES_SUCCESS:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            Logger.debug(action.payload)
            return (
                {
                    ...state,
                    nodes: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE
                });
        case ActionType.FETCH_HUB_NODES_FAILURE:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    nodes: [],
                    error: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE
                });
        case ActionType.UPDATE_HUB_NODES_BEGIN:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            return ({
                ...state,
                success: null,
                error: null
            });
        case ActionType.UPDATE_HUB_NODES_SUCCESS:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            Logger.debug(action.payload)
            return (
                {
                    ...state,
                    success: "Successfully Edited Node Data",
                    error: null
                });
        case ActionType.UPDATE_HUB_NODES_FAILURE:
            Logger.debug(`HubProdCenterEx reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    success: null,
                    error: action.payload.details.error,
                });
        default:
            break;
    }
    return state;
}

