import React from 'react';
import * as Logger from 'loglevel';
import { Route, Switch } from 'react-router-dom';
//import AppNavigation from './components/Navigation';
import AppNavigation from './components/post-authorization/AppNavigation'
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import './App.css';


export default (props) => {
    Logger.debug('App, props=', props);
    return (
        <Switch>
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <AuthorizeRoute component={AppNavigation} />
        </Switch>
    );
}
