import React from 'react'
import { withRouter } from 'react-router-dom';
import * as Logger from 'loglevel';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';


class LoginClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: undefined
        };
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        //JS returns the result of the last value that halted the execution
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser he gets sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        //replace(returnUrl);
        let url = new URL(returnUrl)
        const redirectPath = { pathname: `${url.pathname}`, state: { local: true } };
        this.props.history.replace(redirectPath);
        //window.location.replace(returnUrl);
    }

    async login(returnUrl) {
        const state = { returnUrl };
        Logger.debug(`------------ ASYNC JOB1 STARTED - authService.signIn --- State = { ${returnUrl} }`);
        const result = await authService.signIn(state);
        Logger.debug(`------------ ASYNC JOB1 FINISHED - authService.signIn returned, result:`);
        Logger.debug(result);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // We replace the location here so that in case the user hits the back
                // arrow from within the login page he doesn't get into an infinite
                // redirect loop.
                window.location.replace(result.redirectUrl);
                break;
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(returnUrl);
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }


    async processLoginCallback() {
        const url = window.location.href;
        Logger.debug(`------------ ASYNC JOB3 STARTED - Processing loging callback, url= ${url}`);
        const result = await authService.completeSignIn(url);
        Logger.debug(`------------ ASYNC JOB3 FINISHED - Processing loging callback, result:`);
        Logger.debug(result);
        switch (result.status) {
            case AuthenticationResultStatus.Redirect:
                // There should not be any redirects as the only time completeSignIn finishes
                // is when we are doing a redirect sign in flow.
                throw new Error('Should not redirect.');
            case AuthenticationResultStatus.Success:
                await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                break;
            case AuthenticationResultStatus.Fail:
                this.setState({ message: result.message });
                break;
            default:
                throw new Error(`Invalid authentication result status '${result.status}'.`);
        }
    }


    componentDidMount() {
        const action = this.props.action;
        Logger.debug(`Login mounted, action = ${action}`);
        switch (action) {
            case LoginActions.Login:
                this.login(this.getReturnUrl());
                break;
            case LoginActions.LoginCallback:
                this.processLoginCallback();
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                this.setState({ message: error });
                break;
            case LoginActions.Profile:
                this.redirectToProfile();
                break;
            case LoginActions.Register:
                this.redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }

    render() {
        const action = this.props.action;
        const { message } = this.state;
        if (!!message) {
            return <div>{message}</div>
        } else {
            switch (action) {
                case LoginActions.Login:
                    return (<div>Processing login</div>);
                case LoginActions.LoginCallback:
                    return (<div>Processing login callback</div>);
                case LoginActions.Profile:
                case LoginActions.Register:
                    return (<div></div>);
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        }
    }
}

export const Login = withRouter(LoginClass); 
